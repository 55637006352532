import PropTypes from 'prop-types';
import * as React from 'react';

const UnSortMessages = ({ size = 20, color = '#fff', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 512 512" {...props}>
      <path d="M492 80H20C9.308 80 0 88.693 0 100v312c0 11.046 8.954 20 20 20h472c11.046 0 20-8.954 20-20V100c0-11.227-9.26-20-20-20zm-39.795 40l-86.258 116H312.56c-8.256-23.278-30.487-40-56.56-40s-48.303 16.722-56.56 40h-53.389L59.795 120zM276 256c0 11.028-8.972 20-20 20s-20-8.972-20-20 8.972-20 20-20 20 8.972 20 20zm196 136H40V160.414l79.951 107.521A20.002 20.002 0 00136 276h63.44c8.256 23.278 30.487 40 56.56 40s48.303-16.722 56.56-40h63.439a20 20 0 0016.049-8.066L472 160.413z" />
    </svg>
  );
};

UnSortMessages.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default UnSortMessages;
