import {apiGet} from '../index';
import {createUrlQuery} from '../../utils/createApiUrl';

export const fetchReasonList = async (props) => {
    return await apiGet({
        url: `/dk-reason${createUrlQuery({
            ...props
        })}`,
    });
};

export const fetchInvitationList = async (props) => {
    return await apiGet({
        url: `/dk-invitation${createUrlQuery({
            ...props
        })}`,
    });
};


export const fetchProtocolList = async (props) => {
    return await apiGet({
        url: `/dk-protocol${createUrlQuery({
            ...props
        })}`,
    });
};


export const fetchExtract = async (props) => {
    return await apiGet({
        url: `/dk-extract${createUrlQuery({
            ...props
        })}`,
    });
};
