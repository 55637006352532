import PropTypes from 'prop-types';
import * as React from 'react';

const SvgComponent = ({ size = 20, color = '#000', ...props }) => {
  return (
    <svg viewBox="-68 0 512 512" height={size} width={size} fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M355 74.02h-58.645V20c0-11.047-8.957-20-20-20H20C8.953 0 0 8.953 0 20v48.852c0 11.046 8.953 20 20 20 7.937 0 14.395 6.437 14.395 14.351S27.937 117.56 20 117.56c-11.047 0-20 8.953-20 20v47.324c0 11.043 8.953 20 20 20 7.937 0 14.395 6.437 14.395 14.351S27.937 233.586 20 233.586c-11.047 0-20 8.953-20 20v47.324c0 11.047 8.953 20 20 20 7.937 0 14.395 6.438 14.395 14.352 0 7.914-6.458 14.351-14.395 14.351-11.047 0-20 8.957-20 20v47.325c0 11.046 8.953 20 20 20h61.453V492c0 11.047 8.953 20 20 20H355c11.047 0 20-8.953 20-20V94.02c0-11.043-8.953-20-20-20zM40 396.938v-11.13c20.129-7.98 34.395-27.628 34.395-50.546S60.129 292.695 40 284.715v-14.938c20.129-7.976 34.395-27.625 34.395-50.543S60.129 176.664 40 168.687V153.75c20.129-7.98 34.395-27.629 34.395-50.547S60.129 60.637 40 52.656V40h216.355v356.938zM335 472H121.453v-35.063h154.902c11.043 0 20-8.953 20-20V114.02H335zM215.02 218.469c0 54.511-44.348 98.86-98.86 98.86-11.043 0-20-8.954-20-20s8.957-20 20-20c32.457 0 58.86-26.403 58.86-58.86s-26.403-58.86-58.86-58.86c-11.043 0-20-8.953-20-20s8.957-20 20-20c54.512 0 98.86 44.348 98.86 98.86zm0 0" />
    </svg>
  );
};

SvgComponent.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default SvgComponent;
