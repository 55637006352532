import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../../api/index';
import { useFetch } from '../../../../hooks/useFetch';
import { Input, Modal } from '../../../components';
import { Gear, TrashCan } from '../../../icons';

const ReportPeriod = () => {
  const state = useSelector(state => state);
  const [viewModalNewPeriod, setViewModalNewPeriod] = useState(false);
  const [viewModalEditPeriod, setViewModalEditPeriod] = useState(false);
  const [createErrObj, setCreateErrObj] = useState({});
  const [editErrObj, setEditErrObj] = useState({});

  const [sending, setSending] = useState(false);
  const [page, setPage] = useState(1);
  const [createNameReport, setCreateNameReport] = useState('');
  const [createStartReport, setCreateStartReport] = useState('');
  const [createFinishReport, setCreateFinishReport] = useState('');
  const [editNameReport, setEditNameReport] = useState('');
  const [editStartReport, setEditStartReport] = useState('');
  const [editFinishReport, setEditFinishReport] = useState('');
  const [editIdReport, setEditIdReports] = useState('');
  const [sorting, setSorting] = useState('');

  const { response, fetchData } = useFetch({
    url: `/report-period?page=${page}&limit=10&sort=${sorting}`,
  });

  const getSortClass = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        return '_desc';
      } else {
        return '_asc';
      }
    }

    return '';
  };

  const sortTable = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        setSorting(sortType);
      } else {
        setSorting(`-${sortType}`);
      }
    } else {
      setSorting(sortType);
    }
  };

  const showModalNewPeriod = isShow => {
    setViewModalNewPeriod(isShow);
  };

  const showModalEditPeriod = isShow => {
    setViewModalEditPeriod(isShow);
  };

  //Перевод даты
  const reformatDate = dateStr => {
    let dArr = [];
    if (dateStr != null) {
      dArr = dateStr.split('-');
    }
    return dArr[2] + '.' + dArr[1] + '.' + dArr[0];
  };

  const reformatDate2 = dateStr => {
    let dArr = [];
    if (dateStr != null) {
      dArr = dateStr.split('.');
    }
    return dArr[2] + '-' + dArr[1] + '-' + dArr[0];
  };

  const delReportPeriodAlert = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить?',
      buttons: [
        {
          label: 'Да',
          onClick: () => delReportPeriod(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const reformatStartDate = date => {
    setEditStartReport(reformatDate2(date));
  };
  const reformatFinishtDate = date => {
    setEditFinishReport(reformatDate2(date));
  };

  //Создание отчетного периода

  const createRepotPeriod = () => {
    if (!sending) {
      setSending(true);

      setCreateStartReport(reformatDate(createStartReport));
      setCreateFinishReport(reformatDate(createFinishReport));

      const postData = {
        name: createNameReport,
        from: createStartReport,
        to: createFinishReport,
      };

      apiPost({ url: `/report-period/create`, postData })
        .then(res => {
          if (res.status === 406) {
            setCreateErrObj(res.data);
            return;
          } else if (res.error) {
            console.error('Создание отчетного периода');
            return;
          }

          showModalNewPeriod(false);
          fetchData();
          setCreateErrObj({});
        })
        .finally(() => setSending(false));
    }
  };

  //Изменение отчетного периода

  const openEditReportPeriod = item => {
    setEditNameReport(item.name);
    reformatStartDate(item.startDate);
    reformatFinishtDate(item.endDate);
    setEditIdReports(item.id);

    showModalEditPeriod(true);
  };

  const editReportPeriod = editIdReport => {
    if (!sending) {
      setSending(true);

      const postData = {
        name: editNameReport,
        from: reformatDate(editStartReport),
        to: reformatDate(editFinishReport),
      };

      apiPost({ url: `/report-period/update?id=${editIdReport}`, postData })
        .then(res => {
          if (res.status === 406) {
            setEditErrObj(res.data);
            return;
          } else if (res.error) {
            console.error('Обновление отчетного периода');
            return;
          }

          setEditErrObj({});
          showModalEditPeriod(false);
          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  //Удаление отчетного периода

  const delReportPeriod = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-period/delete?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Удаление отчетного периода');
            return;
          }
          showModalEditPeriod(false);
          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  return (
    <div className="report-period">
      <div className="card">
        <div className="card-header">
          <div className="row jc-between">
            <div className="col-auto">Отчетные периоды</div>
            {state.user.accessUser[`report-period`][`create`] && (
              <div className="col-auto">
                <button id="new-report" className="btn btn-primary btn-xs" onClick={() => showModalNewPeriod(true)}>
                  Создать новый отчетный период
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="card-content">
          <div className="scroll-x">
            <table className="mt-3 low-table">
              <thead>
                <tr>
                  <th onClick={() => sortTable('name')} style={{ cursor: 'pointer' }}>
                    <div className="d-flex">
                      <span>Название</span>
                      <div className={`sort ${getSortClass('name')}`}></div>
                    </div>
                  </th>
                  <th onClick={() => sortTable('from')} style={{ cursor: 'pointer' }}>
                    <div className="d-flex">
                      <span>Начальная дата</span>
                      <div className={`sort ${getSortClass('from')}`}></div>
                    </div>
                  </th>
                  <th onClick={() => sortTable('to')} style={{ cursor: 'pointer' }}>
                    <div className="d-flex">
                      <span>Конечная дата</span>
                      <div className={`sort ${getSortClass('to')}`}></div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {response?.models.map(item => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>
                      <div className="row row-sm jc-end">
                        {state.user.accessUser[`report-period`][`update`] && (
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-warning"
                              onClick={() => openEditReportPeriod(item)}
                              title="Изменить"
                            >
                              <Gear />
                            </button>
                          </div>
                        )}
                        {state.user.accessUser[`report-period`][`delete`] && (
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-danger"
                              onClick={() => delReportPeriodAlert(item.id)}
                              title="Удалить"
                            >
                              <TrashCan />
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 ta-center">
            <ReactPaginate
              pageCount={response?.lastPage == 1 ? 0 : response?.lastPage}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              onPageChange={e => setPage(e.selected + 1)}
              activeClassName={'active'}
              containerClassName={'pagination d-inline-flex ta-center'}
              pageLinkClassName={'link'}
              pageClassName={'link'}
              previousLabel={false}
              nextLabel={false}
            />
          </div>

          <Modal
            show={viewModalEditPeriod}
            onClose={() => showModalEditPeriod(false)}
            modalTitle="Изменение отчетного периода"
            footer={
              <div className="row row-sm">
                <div className="col-auto">
                  <button onClick={() => delReportPeriodAlert(editIdReport)} className="btn btn-danger">
                    Удалить
                  </button>
                </div>

                <div className="col-auto">
                  <button className="btn btn-primary" onClick={() => editReportPeriod(editIdReport)}>
                    Сохранить
                  </button>
                </div>
              </div>
            }
          >
            <div className="row">
              <div className="col-12 col-md-4">
                <Input label="Название" value={editNameReport} onChange={setEditNameReport} error={editErrObj.name} />
              </div>

              <div className="col-12 col-md-4">
                <Input
                  label="Начальная дата"
                  type="date"
                  value={editStartReport}
                  onChange={setEditStartReport}
                  error={editErrObj.start_date}
                />
              </div>

              <div className="col-12 col-md-4">
                <Input
                  label="Конечная дата"
                  type="date"
                  value={editFinishReport}
                  onChange={setEditFinishReport}
                  error={editErrObj.end_date}
                />
              </div>
            </div>
          </Modal>

          <Modal
            show={viewModalNewPeriod}
            onClose={() => showModalNewPeriod(false)}
            modalTitle="Создание отчетного периода"
            footer={
              <button className="btn btn-primary" onClick={() => createRepotPeriod()}>
                Создать
              </button>
            }
          >
            <div className="row">
              <div className="col-12 col-md-4">
                <Input
                  label="Название"
                  value={createNameReport}
                  onChange={setCreateNameReport}
                  error={createErrObj.name}
                />
              </div>
              <div className="col-12 col-md-4">
                <Input
                  label="Начальная дата"
                  type="date"
                  value={createStartReport}
                  onChange={setCreateStartReport}
                  error={createErrObj.start_date}
                />
              </div>
              <div className="col-12 col-md-4">
                <Input
                  label="Конечная дата"
                  type="date"
                  value={createFinishReport}
                  onChange={setCreateFinishReport}
                  error={createErrObj.end_date}
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ReportPeriod;
