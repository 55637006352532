import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiGet } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { Breadcrumbs, Input, Modal, Textarea } from '../../../components';

const ReportTriggers = () => {
  const { id } = useParams();

  const [modalViewTrigger, setModalViewTrigger] = useState(false);
  const [triggerData, setTriggerData] = useState('');

  const { response, fetchData } = useFetch({
    url: `/report/report-trigger?id=${id}`,
  });

  const linkRoute = [
    {
      name: 'Отчеты',
      link: '/reports',
    },
    {
      name: 'Отчет организаций',
      link: `/organization-reports/${id}`,
    },
    {
      name: 'Триггеры отчета',
      link: `/report-triggers/${id}`,
    },
  ];

  const viewTriigerInfo = id => {
    apiGet({
      url: `/trigger/view?id=${id}`,
    }).then(res => {
      if (res.error) {
        alert('Ошибка');
        return;
      }
      setModalViewTrigger(true);
      setTriggerData(res);
    });
  };

  const changeCheckbox = (triggerId, enable) => {
    let toEnable = !enable ? 1 : 0;

    apiGet({
      url: `/report/set-report-trigger?id=${id}&trigger_id=${triggerId}&enable=${toEnable}`,
    }).then(res => {
      if (res?.error) {
        alert('Ошибка');
      } else {
        fetchData();
      }
    });
  };

  return (
    <div className="edit-summary">
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="card">
        <div className="card-header">Триггеры отчета</div>
        <div className="card-content">
          <div className="row">
            <div className="col">
              <ul className="tasks__list list-unstyled">
                {response?.map(item => (
                  <li
                    key={item?.trigger_id}
                    className="tasks__item"
                    onClick={e => {
                      e.target.localName == 'span' && viewTriigerInfo(item.trigger_id);
                    }}
                  >
                    <span>{item.triggerName}</span>

                    <div
                      onClick={() => changeCheckbox(item?.trigger_id, item.enable)}
                      className={`custom-checkbox ${item.enable && '_active'}`}
                    >
                      <div className="circle"></div>
                    </div>
                  </li>
                ))}

                {/* {provided.placeholder} */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modalViewTrigger} onClose={() => setModalViewTrigger(false)} modalTitle="Просмотр триггера">
        <div className="row">
          <div className="col-12">
            <Input label="Название триггера" disabled={true} value={triggerData?.data?.name || ''} onChange={e => e} />
            <Input
              label="Период"
              disabled={true}
              value={`${triggerData?.data?.period?.startDate || ''} ${
                triggerData?.data?.period?.startDate ? '-' : ''
              }  ${triggerData?.data?.period?.endDate || ''}`}
              onChange={e => e}
            />
            <Textarea
              label="Код триггера"
              rows="10"
              value={triggerData?.data?.condition || ''}
              disabled={true}
            ></Textarea>
            <Textarea
              label="Описание триггера"
              rows="10"
              value={triggerData?.data?.description || ''}
              disabled={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReportTriggers;
