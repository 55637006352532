import {useHistory, useParams} from 'react-router-dom';
import {useFetch} from '../../../../hooks/useFetch';
import {createUrlQuery} from '../../../../utils/createApiUrl';
import useUrl from '../../../../hooks/useUrl';
import {apiGet, apiPost} from '../../../../api';
import {message} from 'antd';
import {confirmAlert} from 'react-confirm-alert';
import {downloadReport} from '../../../../utils/downloadReport';
import getUrlParams from '../../../../hooks/getUrlParams';
import {getFolderIdUrl} from '../../../../utils/getFolderId';
import {useDispatch} from 'react-redux';

export const useInvitationViewPage = () => {
    const {id} = useParams();
    const {response, loading, fetchData} = useFetch({url: `/dk-invitation/view${createUrlQuery({id})}`});
    const params = useUrl();
    const history = useHistory();
    const dispatch = useDispatch();

    const linkRoute = [
        {
            name: 'Приглашения',
            link: `/invitation/${params.archive}${getFolderIdUrl()}`,
        },
        {
            name: 'Просмотр приглашения',
        },
    ];

    const deleteInvitation = async () => {
        const {error} = await apiPost(
            {url: `/dk-invitation/delete${createUrlQuery({id})}`}
        );
        if (!error) {
            history.goBack();
            message.success('Успешно удалено!');
        }
    };
    const sendInvitation = async () => {
        const {error} = await apiPost(
            {url: `/dk-invitation/send${createUrlQuery({id})}`}
        );
        if (!error) {
            await fetchData();
            message.success('Успешно отправлено!');
        }
    };
    const handleConfirmDeleteInvitation = () => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы точно хотите удалить приглашение?',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => deleteInvitation(),
                },
                {
                    label: 'Нет',
                },
            ],
        });
    };
    const downloadFile = () => {
        apiGet({url: `/dk-invitation/download?id=${id}`})
            .then(res => {
                if (res.error) {
                    console.error('Скачивание документа (шаблон)');
                    return;
                }
                console.log(res);
                downloadReport(res.data, true);
            });
    };

    return {
        linkRoute,
        history,
        params,
        response,
        id,
        fetchData,
        loading,
        dispatch,
        downloadFile,
        handleConfirmDeleteInvitation,
        sendInvitation
    };
};
