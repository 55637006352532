import React from 'react';
import { EditTrigger } from '../../containers/Lk/index';

const EditTriggerPage = () => {
  return (
    <>
      <EditTrigger />
    </>
  );
};

export default EditTriggerPage;
