import React from 'react';
import { useOrganizationUpdateDkPage } from './useOrganizationUpdateDkPage';
import { CustomEditor } from '../../components/CustomEditor/CustomEditor';
import Index from '../../components/Spinner';
import { CustomButton } from '../../components/Button/Button';
import { Breadcrumbs } from '../../components';

const OrganizationUpdateDkPage = () => {
  const { linkRoute, values, onFormChange, methods, loading, updateInfo } = useOrganizationUpdateDkPage();
  const {
    formState: { errors },
  } = methods;

  if (loading) {
    return (
      <div className="w-full flex justify-center">
        <Index size={60} />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumbs liClassName="breadcrumbs-li" linkRoute={linkRoute} />

      <div className="card">
        <div className="card-content flex flex-col gap-8">
          <div className="flex space-y-3 flex-col">
            <CustomEditor
              label="Основной текст"
              fieldClasses="!mb-0"
              error={errors.mainText && [errors.mainText.message]}
              onChange={v => onFormChange('mainText', v)}
              value={values.mainText}
            />

            <CustomEditor
              label="Текст голосования"
              fieldClasses="!mb-0"
              error={errors.votingText && [errors.votingText.message]}
              value={values.votingText}
              onChange={v => onFormChange('votingText', v)}
            />

            <CustomEditor
              label="Текст решения"
              fieldClasses="!mb-0"
              style={{ minHeight: 60 }}
              error={errors.solveText && [errors.solveText.message]}
              value={values.solveText}
              onChange={v => onFormChange('solveText', v)}
            />
          </div>

          <CustomButton className="w-fit" onClick={updateInfo}>
            Сохранить
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default OrganizationUpdateDkPage;
