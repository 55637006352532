import React from 'react';
import { ReportsTemplate } from '../../containers/Lk/index';

const ReportsTemplatePage = () => {
  return (
    <>
      <ReportsTemplate />
    </>
  );
};

export default ReportsTemplatePage;
