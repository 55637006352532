import React, { useState } from 'react';
import { apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { Input } from '../../../components';
import Index from '../../../components/Spinner';
import './index.scss';

const Profile = () => {
  const { response, loading } = useFetch({ url: '/user/profile' });
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPass2, setNewPass2] = useState('');
  const [errorPass, setErrorPass] = useState(false);
  const [textError, setTextError] = useState('');
  const [changePassOkay, setChangePassOkay] = useState(false);

  const changePass = () => {
    if (oldPass == '' || newPass == '' || newPass2 == '') {
      setTextError('Пожалуйста заполните все поля');
      setErrorPass(true);
      return;
    }
    if (newPass != newPass2) {
      setTextError('Пароли не совпадают');
      setErrorPass(true);
      return;
    }

    apiPost({
      url: `/user/change-password`,
      postData: {
        currentPassword: oldPass,
        newPassword: newPass,
      },
    }).then(res => {
      if (res.error) {
        setTextError('Старый пароль не верный');
        setErrorPass(true);
      } else {
        setErrorPass(false);
        setChangePassOkay(true);
        setOldPass('');
        setNewPass('');
        setNewPass2('');
      }
    });
  };

  if (loading) {
    return (
      <div className="m-auto">
        <Index />
      </div>
    );
  }

  return (
    <div className="profile card">
      <div className="head  card-header f-500">Личный кабинет СРО &#34;Альянс&#34;</div>
      <div className="profile-block card-content">
        <div className="row">
          <div className="col-12 col-md-4">
            <Input label="Фамилия" defaultValue={response?.lastName || ''} disabled />
          </div>
          <div className="col-12 col-md-4">
            <Input label="Имя" defaultValue={response?.name || ''} disabled />
          </div>
          <div className="col-12 col-md-4">
            <Input label="Отчество" defaultValue={response?.patronymic || ''} disabled />
          </div>
          <div className="col-12 col-md-4">
            <Input label="Почта" defaultValue={response?.email || ''} disabled />
          </div>
        </div>

        <div className="h5 mb-2">Изменение пароля</div>

        <div className="row">
          <div className="col-md-4">
            <Input
              type="password"
              autocomplete="new-password"
              placeholder="Введите старый пароль"
              value={oldPass}
              onChange={setOldPass}
            />
          </div>

          <div className="col-md-4">
            <Input type="password" placeholder="Введите новый пароль" value={newPass} onChange={setNewPass} />
          </div>

          <div className="col-md-4">
            <Input type="password" placeholder="Повторите новый пароль" value={newPass2} onChange={setNewPass2} />
          </div>
        </div>
        {errorPass == true && <span className="d-block error_text mb-2">{textError}</span>}
        {changePassOkay == true && <span className="d-block okay_text">Пароль успешно изменен</span>}

        <button className="btn saveProfile btn-bg-acent save f-300" onClick={() => changePass()}>
          Изменить пароль
        </button>
      </div>
    </div>
  );
};

export default Profile;
