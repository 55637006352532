import React from 'react';
import { TemplateSummaryReportElements } from '../../containers/Lk/index';

const TemplateSummaryReportElementsPage = () => {
  return (
    <>
      <TemplateSummaryReportElements />
    </>
  );
};

export default TemplateSummaryReportElementsPage;
