export const STATUS_CLASS_LIST = ['', 'white', 'blue', 'pale-red', 'red', 'green', 'dark-red', 'dark-red'];

export const CURRENT_YEAR = new Date().getFullYear();
export const YEARS = [
  {
    value: CURRENT_YEAR - 1,
    name: CURRENT_YEAR - 1,
  },
  {
    value: CURRENT_YEAR,
    name: CURRENT_YEAR,
  },
  {
    value: CURRENT_YEAR + 1,
    name: CURRENT_YEAR + 1,
  },
];
