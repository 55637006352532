import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiGet } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { Breadcrumbs, Input, Modal, Textarea } from '../../../components';

const TriggerReports = () => {
  const { id } = useParams();
  const { idTrigger } = useParams();

  const [modalViewTrigger, setModalViewTrigger] = useState(false);
  const [triggerData, setTriggerData] = useState('');

  const { response, fetchData } = useFetch({
    url: `/trigger/report-trigger?id=${idTrigger}`,
  });

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Просмотр шаблона',
      link: `/view-report/${id}`,
    },
    {
      name: 'Отчеты триггера',
      link: `/edit-trigger-reports/${id}/${idTrigger}`,
    },
  ];

  const changeCheckbox = (reportId, enable) => {
    let toEnable = !enable ? 1 : 0;

    apiGet({
      url: `/report/set-report-trigger?id=${reportId}&trigger_id=${idTrigger}&enable=${toEnable}`,
    }).then(res => {
      if (res?.error) {
        alert('Ошибка');
      } else {
        fetchData();
      }
    });
  };

  return (
    <div className="edit-summary">
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="card">
        <div className="card-header">Отчеты триггера</div>
        <div className="card-content">
          <div className="row">
            <div className="col">
              <ul className="tasks__list list-unstyled">
                {response?.map(item => (
                  <li
                    key={item?.report?.id}
                    className="tasks__item"
                  >
                    <span>{item?.report?.period?.name} ({item?.report?.period?.startDate} - {item?.report?.period?.endDate})</span>

                    <div
                      onClick={() => changeCheckbox(item?.report.id, item.enable)}
                      className={`custom-checkbox ${item.enable && '_active'}`}
                    >
                      <div className="circle"></div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default TriggerReports;
