export const EDITOR_OPTIONS = {
    placeholder: 'Начните вводить',
    min_height: 200,
    language: 'ru',
    plugins:
        'tinydrive preview importcss searchreplace  autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
    toolbar:
        'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    image_advtab: true,
    browser_spellcheck: true,
    file_picker_types: 'file image media',
    automatic_uploads: true,
};
