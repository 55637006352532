import React, { useState } from 'react';
import { Breadcrumbs } from '../../../components';
import { useProtocolViewPage } from './useProtocolViewPage';
import Index from '../../../components/Spinner';
import { Descriptions, Popover } from 'antd';
import { Download, Send, TrashCan } from '../../../icons';
import { ProtocolAddOrganizationModal } from './elems/ProtocolAddOrganizationModal';
import { modalApi } from '../../../../redux/actions/modal';
import { CustomButton } from '../../../components/Button/Button';
import { getFolderIdUrl } from '../../../../utils/getFolderId';
import { UpdateProtocolModal } from '../ProtocolPage/elems/UpdateProtocolModal';
import { Pencil } from '../../../icons/Pencil';
import { Link } from 'react-router-dom';
import { OrganizationDragContainer } from './elems/OrganizationDragContainer';
import { apiPost } from '../../../../api';

const ProtocolViewPage = () => {
  const {
    response,
    linkRoute,
    id,
    fetchData,
    downloadFile,
    handleConfirmDeleteOrganization,
    history,
    dispatch,
    handleProtocolUpdate,
    sendAllExtracts,
    sendExtractsById,
    toggleDnd,
    dndVisible,
    setOrganizationDndIds,
    loading,
  } = useProtocolViewPage();

  if (loading) {
    return (
      <div className="w-full flex justify-center">
        <Index size={60} />
      </div>
    );
  }

  return (
    <div className="bg-opacity-20">
      <Breadcrumbs liClassName="breadcrumbs-li" linkRoute={linkRoute} />
      <ProtocolAddOrganizationModal fetchData={fetchData} />
      <UpdateProtocolModal fetchData={fetchData} />

      <Descriptions className=" mb-4" bordered column={1}>
        <Descriptions.Item style={{ padding: '10px' }} label="Номер">
          {response.number}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Дата проведения ДК">
          {response.dateAsString}
        </Descriptions.Item>

        <Descriptions.Item style={{ padding: '10px' }} label="Начало">
          {response['timeStart']}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Завершение">
          {response['timeEnd']}
        </Descriptions.Item>
      </Descriptions>

      <div className="card">
        <div className="card-header jc-between gap-y-3  flex-wrap d-flex ai-center">
          Организации
          <div className="d-flex ai-center flex-wrap gap-2">
            <CustomButton className="h-10 bg-blue-900 hover:bg-blue-900/50 hover:bg-opacity-50" onClick={toggleDnd}>
              {dndVisible ? 'Сохранить' : 'Изменить порядок'}
            </CustomButton>

            <CustomButton className="h-10 bg-violet hover:bg-violet hover:bg-opacity-50" onClick={downloadFile}>
              Скачать
            </CustomButton>

            {!!response?.organizations.length && (
              <CustomButton className="h-10" view="info" onClick={sendAllExtracts}>
                Отправить все выписки
              </CustomButton>
            )}

            <CustomButton className="h-10" view="warning" onClick={handleProtocolUpdate}>
              Редактировать
            </CustomButton>

            <button onClick={() => dispatch(modalApi('protocol-add-organization'))} className="btn btn-success h-10">
              Добавить организацию
            </button>
          </div>
        </div>

        {dndVisible ? (
          <OrganizationDragContainer onDrag={setOrganizationDndIds} organizations={response?.organizations} />
        ) : (
          <>
            {!!response?.organizations.length && (
              <div className="card-content">
                {loading ? (
                  <div className="d-flex jc-center ">
                    <Index size={60} />
                  </div>
                ) : (
                  <div className="scroll-x">
                    <table className="mt-4 middle-table">
                      <thead>
                        <tr>
                          <th>Номер протокола</th>
                          <th>Наименование</th>
                          <th>Решение</th>
                          <th className="settings" />
                        </tr>
                      </thead>

                      <tbody>
                        {response?.organizations?.map(i => (
                          <tr
                            className="go-link cursor-pointer"
                            onClick={() =>
                              history.push(
                                `/dk-organization/${i?.extractId}${getFolderIdUrl(null, { protocolId: id })}`
                              )
                            }
                            key={i?.extractId}
                          >
                            <td>{response?.number || '-'}</td>
                            <td> {i?.organization?.name || '-'}</td>
                            <td style={{ maxWidth: 240 }} onClick={e => e.stopPropagation()}>
                              {i?.solve_text ? (
                                <Popover
                                  overlayClassName="break-all w-96"
                                  content={<span dangerouslySetInnerHTML={{ __html: i?.solve_text }} />}
                                  trigger={['hover', 'click']}
                                >
                                  <span
                                    className="line-clamp-2 break-word"
                                    dangerouslySetInnerHTML={{ __html: i?.solve_text }}
                                  />
                                </Popover>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              <div onClick={e => e.stopPropagation()} className="d-flex ai-center jc-end gap-2">
                                <button
                                  className="action-btn btn btn-info"
                                  title="Отправить"
                                  onClick={() => sendExtractsById(i.extractId)}
                                >
                                  <Send />
                                </button>

                                <Link
                                  to={`/organization-update/${i?.extractId}${getFolderIdUrl(null, { protocolId: id })}`}
                                >
                                  <button className="action-btn btn btn-warning" title="Изменить">
                                    <Pencil />
                                  </button>
                                </Link>

                                <button
                                  title="Удалить"
                                  className="action-btn btn btn-danger"
                                  onClick={() => handleConfirmDeleteOrganization(i.extractId)}
                                >
                                  <TrashCan />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProtocolViewPage;
