import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiGet } from './api';
import Index from './components/components/Spinner';
import AuthFlow from './components/containers/Auth/AuthFlow';
import LkFlow from './components/containers/Lk/LkFlow';
import { appLogin, appLogout } from './redux/actions/app';
import { getAccessUser, updateProfile } from './redux/actions/user';
import { checkUserAuth } from './utils/checkAuth';

const App = () => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    checkUserAuth().then(authRes => {
      if (authRes) {
        apiGet({
          url: '/access-user',
          headerList: {
            Authorization: `Bearer ${authRes.authToken}`,
          },
        }).then(res => {
          if (res.error) {
            console.error('Получение прав пользователя');
            return;
          }

          dispatch(getAccessUser(res));
          dispatch(updateProfile(authRes.userData));
          dispatch(appLogin(authRes.authToken));
        });
      } else {
        dispatch(appLogout());
      }

      setTimeout(() => {
        setLoading(false);
      }, 300);
    });
  }, []);

  if (isLoading) {
    return (
      <div className="m-auto">
        <Index />
      </div>
    );
  }

  return <>{state.app.authToken ? <LkFlow /> : <AuthFlow />}</>;
};

export default App;
