import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';
import Accordeon from '../Accordeon';
import Index from '../Spinner';
import './index.scss';

const Tree = () => {
  const { id } = useParams();

  const { response, loading } = useFetch({
    url: `/report-instance/tree?id=${id}`,
  });

  const renderTree = (id, title, content, children) => {
    return (
      <Accordeon title={title} key={id}>
        {children?.map(({ _id, title, content, children }) => renderTree(_id, title, content, children))}

        <p>{content}</p>
      </Accordeon>
    );
  };

  if (loading) {
    return (
      <div className="d-flex jc-center">
        <Index />
      </div>
    );
  }

  return (
    <div className="tree">
      <div className="card">
        <div className="card-header">Древо отчета</div>

        <div className="card-content">
          {response?.treeCompleted ? (
            renderTree(
              response.tree._id,
              response.tree.title,
              // response.tree.isTable,
              response.tree.content,
              response.tree.children
            )
          ) : (
            <p>Дерево ещё не сформировано</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tree;
