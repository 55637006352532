import {useDispatch} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import useUrl from '../../../../hooks/useUrl';
import getUrlParams from '../../../../hooks/getUrlParams';
import {useFetch} from '../../../../hooks/useFetch';
import {createUrlQuery} from '../../../../utils/createApiUrl';
import {prepareSearchParams} from '../../../../utils/prepareSearchParams';
import {apiGet, apiPost} from '../../../../api';
import {message} from 'antd';
import {confirmAlert} from 'react-confirm-alert';
import {downloadReport} from '../../../../utils/downloadReport';
import dayjs from 'dayjs';
import {DATETIME_FORMAT, DEFAULT_DATE_TIME_FORMAT} from '../../../../constants/formats';
import {useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {fetchInvitationList, fetchReasonList} from '../../../../api/dk/dk';

export const STATUS_LIST = [
    {
        id: '',
        name: 'Выберите Статус'
    },
    {
        id: 1,
        name: 'Не отправлено'
    },
    {
        id: 2,
        name: 'Отправлено'
    },
];

const DEFAULT_VALUES = {
    status: '',
    limit: 0,
    dk_reason_id: '',
    organizationNameSearch: '',
    outgoingDateEnd: '',
    outgoingDateStart: '',
    invitationTime: '',
};


const parseDate = (date) => {
    return dayjs(date).format(DATETIME_FORMAT);
};

const dateReverse = (date) => {
    return dayjs(date).format(DEFAULT_DATE_TIME_FORMAT);
};

export const useInvitationList = () => {
    const dispatch = useDispatch();
    const navigate = useHistory();
    const {id} = useParams();
    const {page} = useUrl();
    const {pathname} = useLocation();
    const urlParams = getUrlParams();
    const reasonList = useQuery(['reason-list', id], () => fetchReasonList({
        dk_folder_id: id,
        limit: 1500,
    }));

    const selectReasonOptions = !reasonList.isLoading ? reasonList.data.data?.models?.map(({id, name}) => ({
        id,
        name
    })) : [];

    const [values, setValues] = useState(DEFAULT_VALUES);
    const [invitationIds, setInvitationIds] = useState([]);

    const invitationQueryList = useQuery(['invitation-list', page, id, values], () => fetchInvitationList({
        dk_folder_id: id,
        page,
        ...values,
        // outgoingDateEnd: values.outgoingDateEnd && parseDate(values.outgoingDateEnd),
        // outgoingDateStart: values.outgoingDateStart && parseDate(values.outgoingDateStart),
        invitationTime: values.invitationTime && parseDate(values.invitationTime),
    }));

    const [checkboxAll, setCheckboxAll] = useState(false);
    const resetFiltersVisible = !!Object.values(values).filter(i => Boolean(i)).length;


    const handleValuesChange = (value, name, page = '1') => {
        setValues(p => ({...p, [name]: value}));
        const {fullUrl} = prepareSearchParams([{key: 'page', value: page}, {key: name, value}]);

        fullUrl && navigate.push(fullUrl);
    };

    const deleteInvitation = async (id) => {
        const {error} = await apiPost(
            {url: `/dk-invitation/delete${createUrlQuery({id})}`}
        );
        if (!error) {
            await invitationQueryList.refetch();
            message.success('Успешно удалено!');
        }
    };
    const handleConfirmDeleteInvitation = (e, id) => {
        e.stopPropagation();
        confirmAlert({
            title: 'Удаление',
            message: 'Вы точно хотите удалить приглашение?',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => deleteInvitation(id),
                },
                {
                    label: 'Нет',
                },
            ],
        });
    };
    const downloadFile = id => {
        apiGet({url: `/dk-invitation/download?id=${id}`})
            .then(res => {
                if (res.error) {
                    console.error('Скачивание документа (шаблон)');
                    return;
                }
                console.log(res);
                downloadReport(res.data, true);
            });
    };

    const setReasonIds = (id) => {
        if (invitationIds.includes(id)) {
            setInvitationIds(p => p.filter(i => i !== id));
        } else {
            setInvitationIds(p => [...p, id]);
        }
    };


    const handleSendInvitation = ({id,all}) => {
        confirmAlert({
            title: 'Отправка приглашений',
            message: `Вы точно хотите отправить ${all ? 'приглашения' :  'приглашение'} ?`,
            buttons: [
                {
                    label: 'Да',
                    onClick: () => all ? sendInvitationAll() : sendInvitation(id),
                },
                {
                    label: 'Нет',
                },
            ],
        });
    };

    const sendInvitation = async (id) => {
        const {error} = await apiPost({
            url: `/dk-invitation/send${createUrlQuery({ id })}`
        });
        if (!error) {
            message.success('Успешно отправлено!');
            await invitationQueryList.refetch();
        }
    };

    const sendInvitationAll = async () => {
        const {error} = await apiPost({
            url: `/dk-invitation/send-multiple${createUrlQuery({ids:invitationIds.join(',')})}`
        });
        if (!error) {
            message.success('Успешно отправлено!');
            await invitationQueryList.refetch();
            setInvitationIds([]);
        }
        setCheckboxAll(false);
    };


    const resetTableFilters = () => {
        setValues(DEFAULT_VALUES);
        navigate.push(
            `${pathname}${createUrlQuery({'archive': urlParams.archive})}`
        );
    };

    const selectAllReasonIds = (e) => {
        const checked = e.target.checked;
        setCheckboxAll(checked);
        const ids = [];
         invitationQueryList.data?.data?.models.map(i => {
             if (i.status !== 2 ){
                 ids.push(i.id);
             }
         });
        setInvitationIds(checked ? ids : []);
    };


    useEffect(() => {
        const newValues = {
            limit: urlParams.limit,
            status: urlParams.status,
            dk_reason_id: urlParams.dk_reason_id,
            // outgoingDateEnd: urlParams.outgoingDateEnd,
            // outgoingDateStart: urlParams.outgoingDateStart,
            organizationNameSearch: urlParams.organizationNameSearch,
            invitationTime: urlParams.invitationTime,
        };
        for (const k in newValues) {
            if (newValues[k]) {
                handleValuesChange(newValues[k], k, page);
            }
        }
    }, []);

    useEffect(() => {
        setInvitationIds([]);
        setCheckboxAll(false);
    }, [page]);

    return {
        invitationIds,
        sendInvitation,
        sendInvitationAll,
        navigate,
        dispatch,
        handleSendInvitation,
        checkboxAll,
        handleConfirmDeleteInvitation,
        downloadFile,
        setReasonIds,
        selectAllReasonIds,
        values,
        resetFiltersVisible,
        parseDate,
        page,
        resetTableFilters,
        handleValuesChange,
        selectReasonOptions,
        dateReverse,
        invitationQueryList
    };
};
