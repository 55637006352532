import {useDispatch} from 'react-redux';
import useUrl from '../../../hooks/useUrl';
import {useParams} from 'react-router-dom';
import {apiPost} from '../../../api';
import {createUrlQuery} from '../../../utils/createApiUrl';
import {useQuery} from '@tanstack/react-query';
import {message} from 'antd';
import {confirmAlert} from 'react-confirm-alert';
import {fetchReasonList} from '../../../api/dk/dk';

export const useReasonPage = () => {
    const dispatch = useDispatch();
    const {page} = useUrl();
    const {id} = useParams();

    const reasonQueryList = useQuery(['reason-list', page, id], () => fetchReasonList({dk_folder_id: id, page}));

    const deleteReason = async (id) => {
        const {error} = await apiPost(
            {url: `/dk-reason/delete${createUrlQuery({id})}`}
        );
        if (!error) {
            await reasonQueryList.refetch();
            message.success('Успешно удалено!');
        }
    };

    const handleConfirmDeleteReason = (id) => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы точно хотите удалить причину приглашения?',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => deleteReason(id),
                },
                {
                    label: 'Нет',
                },
            ],
        });
    };

    return {handleConfirmDeleteReason, deleteReason, dispatch, reasonQueryList, page, id};
};
