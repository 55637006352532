import React, {useEffect, useState} from 'react';
import {Input, Modal} from '../../../../components';
import {useDispatch, useSelector} from 'react-redux';
import {toggleEditArchiveModal} from '../../../../../redux/actions/dk';
import {apiPost} from '../../../../../api';
import {createUrlQuery} from '../../../../../utils/createApiUrl';
import {message} from 'antd';

// eslint-disable-next-line react/prop-types
export const DkEditFolderModal = ({fetchData}) => {
    const dispatch = useDispatch();
    const {show, id, name} = useSelector(state => state.dk.editArchiveModal);
    const [inputValue, setInputValue] = useState('');
    const [errors, setErrors] = useState([]);

    const hideModal = () => {
        dispatch(toggleEditArchiveModal({show: false, id: null, name: null}));
    };

    const editFolder = async () => {
        const {error, data} = await apiPost(
            {url: `/dk-folder/update${createUrlQuery({id})}`, postData: {name: inputValue}}
        );
        if (error) {
            setErrors(data.name.map(i => i.replace('Name', 'Название')));
        }
        if (!error) {
            fetchData();
            hideModal();
            message.success('Успешно отредактировано!');
        }
    };

    useEffect(() => {
        if (name) {
            setInputValue(name);
        }
    }, [id]);

    return (
        <div>
            <Modal
                modalTitle='Редактировать архив'
                footer={
                    <button className="btn btn-bg-acent" onClick={editFolder}>
                        Редактировать архив
                    </button>
                }
                show={show}
                onClose={hideModal}
            >
                <Input
                    label='Название'
                    required
                    value={inputValue}
                    onChange={(v) => setInputValue(v)}
                    error={!!errors.length && errors}
                />
            </Modal>
        </div>
    );
};

