import { modalApi } from './modal';

export const setCurrentAttribute = payload => {
  return dispatch => {
    dispatch({
      type: 'SET_ATTRIBUTE',
      payload,
    });

    dispatch(modalApi('org-attribute'));
  };
};

export const resetCurrentAttribute = () => {
  return dispatch => {
    dispatch({
      type: 'SET_ATTRIBUTE',
      payload: null,
    });
    dispatch(modalApi(''));
  };
};
