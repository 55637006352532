import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api/index';
import { CURRENT_YEAR, STATUS_CLASS_LIST, YEARS } from '../../../../global';
import { useFetch } from '../../../../hooks/useFetch';
import { countNotReprimanded } from '../../../../redux/actions/user';
import {downloadByLink} from '../../../../utils/downloadByLink';
import { Breadcrumbs, Dropzone, Input, Modal, Select, Textarea } from '../../../components';
import { Approval, Blogging, Refresh, WarningSign } from '../../../icons';
import { Download } from '../../../icons/';
import Information from '../../../icons/Information';
import BadgeTab from './badgeTab';
import './index.scss';
import dayjs from 'dayjs';
import ControlBtn from '../ViewOrganizationReports/ControlBtn';

const MfoReportsExamples = () => {
  const { id, idReport } = useParams();

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [sending, setSending] = useState(false);
  const [viewNote, setViewNote] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [ErrorModal, setErrorModal] = useState(false);
  const [sorting, setSorting] = useState('-createdDate');
  const [triggerWarning, setTriggerWarning] = useState('');
  const [triggerError, setTriggerError] = useState('');
  const [noteId, setNoteId] = useState();
  const [textNote, setTextNote] = useState('');
  const [errObj, setErrObj] = useState({});
  const [file, setFile] = useState([]);
  const [incomingNum, setIncomingNum] = useState('');
  const [incomingDate, setIncomingDate] = useState('');
  const [checkOnChecking, setCheckOnChecking] = useState(false);
  const [detailTriggersModal, setDetailTriggersModal] = useState(false);
  const [detailTriggers, setDetailTriggers] = useState(false);
  const [yearReportUpload, setYearReportUpload] = useState(CURRENT_YEAR);

  // Report instance modals
  const [addReportInstanceModalVisible, setAddReportInstanceModalVisible] = useState(false);
  const [addReportInstanceModalId, setAddReportInstanceModalId] = useState(null);
  const [addReportInstanceModalValue, setAddReportInstanceModalValue] = useState('');
  const [addReportInstanceModalYear, setAddReportInstanceModalYear] = useState(CURRENT_YEAR);

  const [editReportInstanceModalVisible, setEditReportInstanceModalVisible] = useState(false);
  const [editReportInstanceModalId, setEditReportInstanceModalId] = useState(null);
  const [editReportInstanceModalValue, setEditReportInstanceModalValue] = useState('');
  const [editReportInstanceModalYear, setEditReportInstanceModalYear] = useState(CURRENT_YEAR);

  const { response } = useFetch({ url: `/organization/view?id=${id}` });
  const { response: getReports, fetchData } = useFetch({
    url: `/report-instance?organization_id=${id}&report_id=${idReport}&page=${page}&limit=10&sort=${sorting}`,
  });

  const { response: getReportData } = useFetch({
    url: `/report/view?id=${idReport}`,
  });

  const getSortClass = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        return '_desc';
      } else {
        return '_asc';
      }
    }

    return '';
  };

  const uploadDocAlert = () => {
    confirmAlert({
      message: 'Пожалуйста добавьте отчет',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  // const reportSuccessAlert = () => {
  // 	confirmAlert({
  // 		message: "Отчет принят",
  // 		buttons: [
  // 			{
  // 				label: "Ок",
  // 				onClick: () => {},
  // 			},
  // 		],
  // 	})
  // }

  // const reportUnSuccessAlert = () => {
  // 	confirmAlert({
  // 		message: "Отчет отклонен",
  // 		buttons: [
  // 			{
  // 				label: "Ок",
  // 				onClick: () => {},
  // 			},
  // 		],
  // 	})
  // }

  const updateActualAlert = id => {
    confirmAlert({
      title: 'Изменение',
      message: 'Вы точно хотите сделать этот экземпляр актуальным?',
      buttons: [
        {
          label: 'Да',
          onClick: () => updateActual(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  // const acceptReportAlert = id => {
  // 	confirmAlert({
  // 		title: "Изменение",
  // 		message: "Вы точно хотите принять отчет ?",
  // 		buttons: [
  // 			{
  // 				label: "Да",
  // 				onClick: () => acceptReport(id),
  // 			},
  // 			{
  // 				label: "Нет",
  // 			},
  // 		],
  // 	})
  // }

  // const rejectReportAlert = id => {
  // 	confirmAlert({
  // 		title: "Изменение",
  // 		message: "Вы точно хотите отклонить отчет ?",
  // 		buttons: [
  // 			{
  // 				label: "Да",
  // 				onClick: () => rejectReport(id),
  // 			},
  // 			{
  // 				label: "Нет",
  // 			},
  // 		],
  // 	})
  // }

  const textReportAlert = text => {
    confirmAlert({
      message: text,
      buttons: [
        {
          label: 'Ок',
          onClick: e => e,
        },
      ],
    });
  };

  const sendingRefreshTriggersAlert = () => {
    confirmAlert({
      message: 'Происходит проверка на триггеры, подождите...',
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ],
    });
  };

  const refreshTrigger = id => {
    if (!sending) {
      setSending(true);
      sendingRefreshTriggersAlert();

      apiGet({ url: `/report-instance/check?id=${id}`, timeout: 300000 })
        .then(res => {
          if (res.error) {
            textReportAlert('Ошибка');
            return;
          }

          textReportAlert('Проверка на триггеры завершена.');

          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  const sortTable = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        setSorting(sortType);
      } else {
        setSorting(`-${sortType}`);
      }
    } else {
      setSorting(sortType);
    }
  };

  const showNote = isShow => {
    setViewNote(isShow);
  };

  //Установить отчет актуальным
  const updateActual = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-instance/set-actual?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Установка актуального отчета');
            return;
          }

          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  const viewReportDetailTriggers = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-instance/eval?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Ошибки получения детализации триггера');
            return;
          }
          setDetailTriggers(res.data);
          setDetailTriggersModal(true);
        })
        .finally(() => setSending(false));
    }
  };

  const emptyIncomingNum = () => {
    confirmAlert({
      message: 'Пожалуйста введите входящий номер или установите отчет как отчет на проверку',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const uploadDoc = () => {
    if (!checkOnChecking && incomingNum === '') {
      emptyIncomingNum();
      return;
    }

    if (file == null) {
      uploadDocAlert();
      return;
    }

    if (!sending) {
      setSending(true);

      const postData = new FormData();
      !checkOnChecking && postData.append('incomingNumber', `ТР-${yearReportUpload}-${incomingNum}`);
      file.forEach(file => {
        postData.append('file', file);
      });

      apiPost({
        url: `/report-instance/upload?report_id=${idReport}&organization_id=${id}`,
        postData,
      })
        .then(res => {
          if (res.error) {
            console.error('Загрузка документа');
            setErrObj(res.data);
            return;
          }
          setErrObj({});
          setIncomingNum('');
          setCheckOnChecking(false);
          setFile([]);
          setModalUpload(false);
          fetchData();

          apiGet({ url: '/report/not-reprimanded-count' }).then(res => {
            dispatch(countNotReprimanded(res.data));
          });
        })
        .finally(() => setSending(false));
    }
  };

  // //Принять отчет

  // const acceptReport = id => {
  // 	if (!sending) {
  // 		setSending(true)

  // 		apiGet({ url: `/report-instance/accept?id=${id}` })
  // 			.then(res => {
  // 				if (res.error) {
  // 					console.error("Принятие отчета")
  // 					return
  // 				}

  // 				fetchData()
  // 				reportSuccessAlert()
  // 			})
  // 			.finally(() => setSending(false))
  // 	}
  // }

  //Скачать файлы

  const downloadFile = id => {
    if (!sending) {
      setSending(true);

      // apiGet({ url: `/report-instance/download?id=${id}` })
      //   .then(res => {
      //     if (res.error) {
      //       console.error('Скачивание файла');
      //       return;
      //     }
      //
      //     downloadBase64File(res.data.asBase64, res.data.name);
      //   })
      //   .finally(() => setSending(false));

      apiGet({ url: `/report-instance/download-by-link?id=${id}` })
        .then(res => {
          downloadByLink(res.data.link, res.data.name);
        })
        .finally(() => setSending(false));
    }
  };

  // Сработал триггера

  const viewTriggerWarning = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-instance/triggered?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Сработал триггер');
            return;
          }

          setTriggerWarning(res.data);
          showWarning(true);
        })
        .finally(() => setSending(false));
    }
  };

  //Ошибки триггера

  const funcIncomingNum = e => {
    setErrObj({});
    if (e.length >= 9) {
      setErrObj({ incNum: 'Максимальное количество цифр - 8' });
      return;
    }
    setIncomingNum(e);
  };

  const viewTriggerError = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-instance/problem?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Ошибки триггера');
            return;
          }

          setTriggerError(res.data);
          showError(true);
        })
        .finally(() => setSending(false));
    }
  };

  //Создание коментария

  const openNoteMod = (id, note) => {
    setTextNote(note);
    // if (note !== null) {
    // 	setNoteData(note)
    // }
    // if (note == null) {
    // 	setNoteData("")
    // }
    setNoteId(id);
    showNote(true);
  };

  const editNoteSave = () => {
    if (!sending) {
      setSending(true);

      const postData = {
        note: textNote,
      };

      apiPost({ url: `/report-instance/note?id=${noteId}`, postData })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
          } else if (res.error) {
            console.error('Редактирование записи');
            return;
          }

          fetchData();
          setErrObj({});
          showNote(false);
        })
        .finally(() => setSending(false));
    }
  };

  // // Отклонить отчет

  // const rejectReport = id => {
  // 	if (!sending) {
  // 		setSending(true)

  // 		apiGet({ url: `/report-instance/reject?id=${id}` })
  // 			.then(res => {
  // 				if (res.error) {
  // 					console.error("Отклонение отчета")
  // 					return
  // 				}

  // 				fetchData()
  // 				reportUnSuccessAlert()
  // 			})
  // 			.finally(() => setSending(false))
  // 	}
  // }

  const checked = check => {
    switch (check) {
      case false:
        return false;
      case true:
        return 1;
    }
  };

  const showWarning = isShow => {
    setWarningModal(isShow);
  };
  const showError = isShow => {
    setErrorModal(isShow);
  };

  const handleUploadFiles = e => {
    setFile(e);
  };

  const openModalUpload = () => {
    setModalUpload(true);
  };

  const linkRoute = [
    {
      name: 'Пользователи',
      link: '/users',
    },
    {
      name: 'Просмотр пользователя',
      link: `/view-user/${id}`,
    },
    {
      name: 'Отчеты организации',
      link: `/mfo-reports/${id}`,
    },
    {
      name: 'Загруженные отчеты организации',
      link: `/mfo-reports-examples/${id}/${idReport}`,
    },
  ];

  const updateAllRepr = () => {
    if (!sending) {
      setSending(true);

      apiGet({
        url: `/organization/set-as-reprimanded?id=${response.id}&report_id=${idReport}`,
      })
        .then(() => {
          fetchData();
          apiGet({ url: '/report/not-reprimanded-count' }).then(res => {
            dispatch(countNotReprimanded(res.data));
          });
        })
        .finally(() => setSending(false));
    }
  };

  const editIncomingNumberFns = async () => {
    const res = await apiPost({
      url: `/report-instance/update?id=${editReportInstanceModalId}`,
      postData: {
        incomingNumber: editReportInstanceModalValue.length
          ? `ТР-${editReportInstanceModalYear}-${editReportInstanceModalValue}`
          : '',
        incomingNumberDate: incomingDate || '',
      },
    });

    if (res.error) return;

    await fetchData();
    setEditReportInstanceModalVisible(false);
  };

  const addIncomingNumberFns = async () => {
    const res = await apiPost({
      url: `/report-instance/update?id=${addReportInstanceModalId}`,
      postData: {
        incomingNumber: addReportInstanceModalValue.length
          ? `ТР-${addReportInstanceModalYear}-${addReportInstanceModalValue}`
          : '',
      },
    });

    if (res.error) return;

    await fetchData();
    setAddReportInstanceModalVisible(false);
  };

  const modalFuncIncomingNum = (type, value) => {
    setErrObj({});
    if (value.length >= 9) {
      setErrObj({ incNum: 'Максимальное количество цифр - 8' });
      return;
    }

    if (type === 'add') {
      setAddReportInstanceModalValue(value);
    } else if (type === 'edit') {
      setEditReportInstanceModalValue(value);
    }
  };

  const makeReportRejected = async id => {
    const res = await apiPost({
      url: `/report-instance/set-as-rejected?id=${id}`,
    });

    if (res.error) {
      return false;
    }

    await fetchData();
  };

  useEffect(() => {
    setErrObj({});
  }, [file]);

  return (
    <>
      <div className="mfo-reports-examples">
        <Breadcrumbs linkRoute={linkRoute} />

        <div className="card">
          <div className="card-header d-flex jc-between">
            <div className="name">Загруженные отчеты</div>
            {response?.isDeleted === false && (
                <div className="row">
                  <div className="col-auto">
                    <button className="btn ml-auto btn-warning" onClick={updateAllRepr}>
                      Установить все отчеты как выгруженные
                    </button>
                  </div>
                  <div className="col-auto">
                    <button className="btn ml-auto btn-success" onClick={openModalUpload}>
                      Загрузить отчет
                    </button>
                  </div>
                </div>
            )}
          </div>

          <div className="card-content">
            <div className="row">
              <div className="col-12 col-md-4">
                <Input label="Username" disabled value={response?.username || ''} />
              </div>
              <div className="col-12 col-md-4">
                <Input label="Название организации" disabled defaultValue={response?.name || ''} />
              </div>
              <div className="col-12 col-md-4">
                <Input label="ИНН" disabled defaultValue={response?.inn || ''} />
              </div>
              <div className="col-12 col-md-4">
                <Input label="ОГРН" disabled defaultValue={response?.ogrn || ''} />
              </div>
              <div className="col-12 col-md-4">
                <Input label="Название отчета" disabled defaultValue={getReportData?.template?.name || ''} />
              </div>
              <div className="col-12 col-md-4">
                <Input
                  label="Период отчета"
                  disabled
                  value={`${getReportData?.period?.startDate} - ${getReportData?.period?.endDate}`}
                />
              </div>
            </div>
            <div className="scroll-x">
              <table className="mt-3 big-table">
                <thead>
                  <tr>
                    <th>#</th>
                    {getReports?.models.length !== 1 && <th>Актуальность</th>}

                    <th onClick={() => sortTable('createdDate')} style={{ cursor: 'pointer' }}>
                      <div className="d-flex">
                        <span>Дата загрузки</span>
                        <div className={`sort ${getSortClass('createdDate')}`} />
                      </div>
                    </th>
                    <th onClick={() => sortTable('status')} style={{ cursor: 'pointer' }}>
                      <div className="d-flex">
                        <span>Статус</span>
                        <div className={`sort ${getSortClass('status')}`} />
                      </div>
                    </th>
                    <th>Входящий номер</th>
                    <th>Выгрузка отчета</th>
                    <th />
                    <th />
                  </tr>
                </thead>

                <tbody>
                  {getReports?.models.map(item => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      {getReports?.models.length !== 1 && (
                        <td>
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={checked(item.isActual)}
                            onClick={() => updateActualAlert(item.id)}
                            onChange={e => e}
                          />
                        </td>
                      )}

                      <td>{item.createdDate}</td>

                      <td>
                        <div className={`badge badge-${STATUS_CLASS_LIST[item.status]}`}>{item.statusText}</div>

                        {/* {item.status !== 7 && (
													<button
														className="btn btn-primary ml-2"
														onClick={() => rejectReportAlert(item.id)}
													>
														Отклонить
													</button>
												)}

												{item.status === 5 && (
													<button
														className="btn btn-success ml-2"
														onClick={() => acceptReportAlert(item.id)}
													>
														Принять
													</button>
												)} */}
                      </td>

                      <td>
                        {item?.incomingNumber ? (
                          <div className="row ai-center">
                            <div className="col-auto">
                              {item?.incomingNumber}
                              <br />
                              {item.incomingNumberDate ? dayjs(item.incomingNumberDate).format('DD-MM-YYYY') : ''}
                            </div>

                            <div className="col-auto pl-0">
                              <button
                                className="badge badge-blue"
                                onClick={() => {
                                  setErrObj({});
                                  setEditReportInstanceModalId(item.id);
                                  setEditReportInstanceModalValue(item?.incomingNumber.split('-')[2] || '');
                                  setEditReportInstanceModalVisible(true);
                                }}
                              >
                                Редактировать
                              </button>
                            </div>
                          </div>
                        ) : (
                          <button
                            className="badge badge-green"
                            onClick={() => {
                              setErrObj({});
                              setAddReportInstanceModalId(item.id);
                              setAddReportInstanceModalVisible(true);
                              setAddReportInstanceModalValue('');
                            }}
                          >
                            Добавить
                          </button>
                        )}
                      </td>

                      <td style={{ minWidth: '156px' }}>
                        <BadgeTab notReprimanded={item?.isNotReprimanded} id={item.id} refreshReports={fetchData} />
                      </td>

                      {response?.isDeleted === false && <td>
                        <button
                            className="badge badge-blue"
                            title="Сделать отчет не принятым"
                            onClick={() => {
                              makeReportRejected(item.id);
                            }}
                        >
                          Не принят
                        </button>
                        <br/>

                        {item.isRejected && <span className={'badge badge-pale-red'}>Отчет не принят</span>}
                      </td>}

                      <td>
                        <div className="row row-sm">

                          {response?.isDeleted === false && <ControlBtn
                              idLink={item.id}
                              activeControl={item.activeControl}
                              requiRement={item.activeControl ? (item.activeControl?.requirement) : ''}
                              requirementId={item.activeControl ? (item.activeControl.requirement?.id) : ''}
                          />}

                          {response?.isDeleted === false && <div className="col-auto mt-1">
                            <button
                                className="action-btn btn btn-success add-note"
                                onClick={() => openNoteMod(item.id, item.note)}
                                title="Комментарий к отчету"
                            >
                              <Blogging/>
                            </button>
                          </div>}

                          {response?.isDeleted === false && <div className="col-auto mt-1">
                            <button
                                className="action-btn btn btn-warning"
                                onClick={() => refreshTrigger(item.id)}
                                title="Запустить проверку на триггеры"
                            >
                              <Refresh/>
                            </button>
                          </div>}

                          <div className="col-auto mt-1">
                            <button
                              className="action-btn btn btn-light"
                              onClick={() => downloadFile(item.id)}
                              title="Скачать"
                            >
                              <Download />
                            </button>
                          </div>
                          {(item.status === 3 || item.status === 5) && (
                            <div className="col-auto mt-1">
                              <button
                                className="action-btn btn btn-primary"
                                onClick={() => viewReportDetailTriggers(item.id)}
                                title="Детализация условия"
                              >
                                <Approval />
                              </button>
                            </div>
                          )}

                          {/* <div className="col-auto">
														<Link
															to={"/tree/" + item.id}
															className="action-btn btn btn-info mr-1 search-report-organization"
															target="_blank"
															title="Древо отчета"
														>
															<Consent />
														</Link>
													</div> */}

                          {item.status === 3 && (
                            <div className="col-auto mt-1">
                              <button
                                className="action-btn btn btn-warning report-errors-btn "
                                onClick={() => viewTriggerWarning(item.id)}
                                title="Ошибки триггера"
                              >
                                <Information />
                              </button>
                            </div>
                          )}

                          {item.status === 4 && (
                            <div className="col-auto mt-1">
                              <button
                                className="action-btn btn btn-danger report-danger-btn"
                                onClick={() => viewTriggerError(item.id)}
                                title="Ошибки триггера"
                              >
                                <WarningSign />
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-2 ta-center">
              <ReactPaginate
                pageCount={getReports?.lastPage === 1 ? 0 : getReports?.lastPage}
                pageRangeDisplayed={5}
                marginPagesDisplayed={3}
                onPageChange={e => setPage(e.selected + 1)}
                activeClassName={'active'}
                containerClassName={'pagination d-inline-flex ta-center'}
                pageLinkClassName={'link'}
                pageClassName={'link'}
                previousLabel={false}
                nextLabel={false}
              />
            </div>
          </div>
        </div>

        <Modal
          show={addReportInstanceModalVisible}
          onClose={() => {
            setIncomingDate('');
            setAddReportInstanceModalVisible(false);
          }}
          modalTitle="Добавить входящий номер"
          footer={
            <button className="btn btn-success" onClick={addIncomingNumberFns}>
              Добавить
            </button>
          }
        >
          <div className="d-flex">
            <span className="mt-auto mb-auto">ТР-</span>
            <Select
              fieldClasses="mt-auto mb-auto"
              style={{ minWidth: '75px' }}
              onChange={setAddReportInstanceModalYear}
              value={addReportInstanceModalYear}
              values={YEARS}
            />
            <span className="mt-auto mb-auto">-</span>
            <Input
              fieldClasses="mt-auto mb-auto"
              value={addReportInstanceModalValue}
              onChange={e => modalFuncIncomingNum('add', e)}
            />
          </div>

          {errObj.incNum && <span className="form-error">{errObj.incNum}</span>}

          <Input
            fieldClasses="mt-3"
            value={incomingDate}
            onChange={setIncomingDate}
            errObj={errObj.incDate}
            label="Дата присвоения входящего номера"
            type="date"
            max="9999-01-01"
          />
        </Modal>

        <Modal
          show={editReportInstanceModalVisible}
          onClose={() => {
            setIncomingDate('');
            setEditReportInstanceModalVisible(false);
          }}
          modalTitle="Редактировать входящий номер"
          footer={
            <button className="btn btn-success" onClick={editIncomingNumberFns}>
              Сохранить
            </button>
          }
        >
          <div className="d-flex">
            <span className="mt-auto mb-auto">ТР-</span>
            <Select
              fieldClasses="mt-auto mb-auto"
              style={{ minWidth: '75px' }}
              onChange={setEditReportInstanceModalYear}
              value={editReportInstanceModalYear}
              values={YEARS}
            />
            <span className="mt-auto mb-auto">-</span>
            <Input
              fieldClasses="mt-auto mb-auto"
              value={editReportInstanceModalValue}
              onChange={e => modalFuncIncomingNum('edit', e)}
            />
          </div>

          {errObj.incNum && <span className="_error">{errObj.incNum}</span>}

          <Input
            fieldClasses="mt-3"
            value={incomingDate}
            onChange={setIncomingDate}
            errObj={errObj.incDate}
            label="Дата присвоения входящего номера"
            type="date"
            max="9999-01-01"
          />
        </Modal>

        <Modal show={warningModal} onClose={() => showWarning(false)} modalTitle="Ошибки в отчете">
          <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: triggerWarning }} />
        </Modal>

        <Modal show={ErrorModal} onClose={() => showError(false)} modalTitle="Ошибки при проверке триггера">
          <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: triggerError }} />
        </Modal>

        <Modal
          show={viewNote}
          onClose={() => showNote(false)}
          modalTitle="Коментарий к отчету"
          footer={
            <button className="btn btn-primary" onClick={() => editNoteSave()}>
              Сохранить
            </button>
          }
        >
          <label className="form-field">
            <Textarea rows="10" onChange={setTextNote} value={textNote} error={errObj.note} />
          </label>
        </Modal>
        <Modal
          show={modalUpload}
          onClose={() => {
            setIncomingDate('');
            setModalUpload(false);
          }}
          modalTitle="Загрузка отчета"
          footer={
            <button className="btn btn-success" onClick={() => uploadDoc()}>
              Загрузить
            </button>
          }
        >
          <div className="col-auto mb-3" style={{ display: 'inline-block' }}>
            <label className="d-flex">
              <input
                type="checkbox"
                className="checkbox mr-2"
                checked={checkOnChecking}
                onChange={() => setCheckOnChecking(!checkOnChecking)}
              />
              <span>Отправка отчета на проверку</span>
            </label>
          </div>
          {!checkOnChecking && (
            <>
              <div className="d-flex">
                <span className="mt-auto mb-auto">ТР-</span>
                <Select
                  fieldClasses="mt-auto mb-auto"
                  style={{ minWidth: '75px' }}
                  onChange={e => setYearReportUpload(e)}
                  value={yearReportUpload}
                  values={YEARS}
                />
                <span className="mt-auto mb-auto">-</span>
                <Input
                  fieldClasses="mt-auto mb-auto"
                  value={incomingNum}
                  onChange={funcIncomingNum}
                  errObj={errObj.incNum}
                />
              </div>
              {errObj.incNum && <span className="_error">{errObj.incNum}</span>}

              <Input
                fieldClasses="mt-3"
                value={incomingDate}
                onChange={setIncomingDate}
                errObj={errObj.incDate}
                label="Дата присвоения входящего номера"
                type="date"
                max="9999-01-01"
              />
            </>
          )}

          <div className="mt-3">
            <Dropzone
              maxSize={1000000000}
              onFileRemove={() => setFile([])}
              onFileUpload={handleUploadFiles}
              styles={{ height: 120 }}
              files={file}
              error={errObj.file}
            />
            {errObj.file &&
              errObj.file.map(i => (
                <span key={i} className="_error">
                  {i}
                </span>
              ))}
          </div>
        </Modal>
        <Modal
          show={detailTriggersModal}
          onClose={() => setDetailTriggersModal(false)}
          modalTitle="Отчет о проверке условий триггеров с детализацией условия"
        >
          <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: detailTriggers }} />
        </Modal>
      </div>
    </>
  );
};

export default MfoReportsExamples;
