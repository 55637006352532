import PropTypes from 'prop-types';
import * as React from 'react';

const Blogging = ({ size = 15, color = '#fff', ...props }) => {
  return (
    <svg viewBox="0 0 512 512" width={size} height={size} fill={color} {...props}>
      <path d="M53.572 269.851a20.003 20.003 0 0028.856 0l48-50A20.004 20.004 0 00136 206V20c0-11.046-8.954-20-20-20H20C8.954 0 0 8.954 0 20v186a20.004 20.004 0 005.572 13.851l48 50zM40 40h56v157.954l-28 29.167-28-29.167V40zm165.999 0H492c11.046 0 20-8.954 20-20s-8.954-20-20-20H205.999c-11.046 0-20 8.954-20 20s8.954 20 20 20zM492 472H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20zm0-118H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20zm0-236H205.999c-11.046 0-20 8.954-20 20s8.954 20 20 20H492c11.046 0 20-8.954 20-20s-8.954-20-20-20zm0 118H205.999c-11.046 0-20 8.954-20 20s8.954 20 20 20H492c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
    </svg>
  );
};

Blogging.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Blogging;
