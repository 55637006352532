import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiGet } from '../../../../api';
import { countNotReprimanded } from '../../../../redux/actions/user';
import arrowSelect from '../../../../static/img/icons/arrow-select.svg';
import './badgeTab.scss';

const BadgeTab = ({ notReprimanded, id, refreshReports }) => {
  const [tab, setTab] = useState(false);
  const [sending, setSeinding] = useState(false);
  const [notRepr, setNotRepr] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setNotRepr(notReprimanded);
  }, [notReprimanded]);

  const toggleReprimandedAlert = () => {
    if (!sending) {
      setSeinding(true);
      let url;
      if (notRepr) {
        url = `/report-instance/set-as-reprimanded?id=${id}`;
      } else {
        // eslint-disable-next-line no-unused-vars
        url = `/report-instance/set-as-not-reprimanded?id=${id}`;
      }

      apiGet({ url: url })
        .then(() => {
          setTab(s => !s);
          setNotRepr(s => !s);
          refreshReports();
          apiGet({ url: '/report/not-reprimanded-count' }).then(res => {
            dispatch(countNotReprimanded(res.data));
          });
        })
        .finally(() => setSeinding(false));
    }
  };

  return (
    <div className=" badge-tab">
      <div className="badge badge-white" onClick={() => setTab(s => !s)} style={{ cursor: 'pointer' }}>
        {' '}
        {notRepr ? 'Не выгруженный' : 'Выгруженный'}
        <img src={arrowSelect} className={`${tab && 'rotate'} ml-2`} style={{ width: '12px' }} />
      </div>
      {tab && (
        <div className="list">
          <div className="str" onClick={toggleReprimandedAlert}>
            {notRepr ? 'Выгруженный' : 'Не выгруженный'}
          </div>
        </div>
      )}
    </div>
  );
};

BadgeTab.propTypes = {
  id: PropTypes.any,
  notReprimanded: PropTypes.any,
  refreshReports: PropTypes.func,
};

export default BadgeTab;
