import {useEffect, useState} from 'react';

export const useTheme = () => {
  const [theme,setTheme] = useState('_light');


    useEffect(() => {
        const theme = localStorage.getItem('theme');

        setTheme(theme);
    },[]);

    return {theme , isDark: theme === '_dark'};
};
