import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import {Input, Select} from '../../../components';
import Breadcrumbs from '../../../components/Breadcrumbs';
import './index.scss';

const EditReport = () => {
  const history = useHistory();
  const { id } = useParams();

  const [errObj, setErrObj] = useState({});
  const [name, setName] = useState('');
  const [showTriggersDetails, setShowTriggersDetails] = useState('');
  const [requirementsLogic, setRequirementsLogic] = useState('');
  const [sending, setSending] = useState(false);

  const { response } = useFetch({
    url: `/report-template/view?id=${id}`,
  });

  useEffect(() => {
    setName(response?.name);
    setShowTriggersDetails(response?.showTriggersDetails);
    setRequirementsLogic(response?.requirementsLogic);
  }, [response]);

  const onEdit = () => {
    if (!sending) {
      setSending(true);
      apiPost({
        url: `/report-template/update?id=${id}`,
        postData: {
          name,
          showTriggersDetails,
          requirementsLogic,
        },
      })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          } else if (res.error) {
            console.error('Изменение шаблона');
            return;
          }

          history.goBack();
        })
        .finally(() => setSending(false));
    }
  };

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Изменение шаблона',
      link: `/edit-report/${id}`,
    },
  ];

  return (
    <div className="edit-report">
      <Breadcrumbs linkRoute={linkRoute} />

      <div className="card">
        <div className="card-header">Изменение шаблона</div>

        <div className="card-content">
          <Input
            label="Название шаблона"
            defaultValue={name}
            onChange={setName}
            error={errObj.name}
            placeholder="Введите название шаблона"
          />
        </div>

        <div className="card-content">
          <label className="d-flex">
            <input
                name="allowed_absence"
                type="checkbox"
                className="checkbox"
                checked={showTriggersDetails}
                onChange={() => setShowTriggersDetails(!showTriggersDetails)}
            />
            <span>Показывать детализацию по триггерам в пользовательской версии</span>
          </label>
        </div>

        <div className="card-content">
          <Select
              label="Логика отправки писем"
              value={requirementsLogic}
              onChange={setRequirementsLogic}
              values={[
                { id: 1, name: 'Отправка требований' },
                { id: 2, name: 'Отправка запросов пояснений' },
                { id: 3, name: 'Не отправляется' },
              ]}
          />
        </div>

        <div className="card-footer">
          <button className="btn btn-success" onClick={onEdit}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditReport;
