import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory, useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api';
import { Input, Modal, Select, Textarea } from '../../../components';
import Breadcrumbs from '../../../components/Breadcrumbs';
import FirefoxDateTime from '../../../components/FireFoxDateTime/FirefoxDateTime';
import './index.scss';

const dataTypeSummaryReport = [
  { name: 'Выберите приложение', id: 0 },
  { name: 'Приложение 2', id: 1 },
  { name: 'Приложение 3', id: 2 },
  { name: 'Приложение 4 или 5', id: 3 },
  { name: 'Приложение 2 (с 2кв 2021г)', id: 4 },
  { name: 'Приложение 2 (с 1кв 2022г)', id: 5 },
  { name: 'Приложение 3 (с 1кв 2022г)', id: 6 },
  { name: 'Отчет по Риск-профилю', id: 7 },
  { name: 'БФО КО 613', id: 8 },
  { name: 'БФО КО 614', id: 9 },
  { name: 'БФО НО 613', id: 10 },
  { name: 'БФО НО 614', id: 11 },
];

const EditSummary = () => {
  const { idReport, id, templateId } = useParams();
  const history = useHistory();

  const [modalViewTrigger, setModalViewTrigger] = useState(false);
  const [triggerData, setTriggerData] = useState('');

  const [dataSuammryReportType, setDataSummaryReportType] = useState('');
  const [dataSummaryTime, setDataSummaryTime] = useState('');
  const [characters, setCharaters] = useState();
  const [charatersLetter, setCharatersLetter] = useState();
  const [triggerColumnsId, setTriggerColumnsId] = useState(null);
  const [templateSummaryName, setTemplateSummaryName] = useState();
  const [reprimanded, setReprimanded] = useState();
  const [otherReport, setOtherReport] = useState('');
  const reformatDate2 = dateStr => {
    return dateStr.replace(/(\d+).(\d+).(\d+) (\d+):(\d+):(\d+)/, '$3-$2-$1T$4:$5:$6');
  };

  const reformatDate = dateStr => {
    return dateStr.replace(/(\d+)-(\d+)-(\d+)T(\d+):(\d+)/, '$3.$2.$1 $4:$5');
  };

  const getSummaryReport = () => {
    apiGet({ url: `/summary-report/view?id=${id}` }).then(res => {
      if (res.error) {
        console.error('Получения отчетных периодов');
        return;
      }
      setOtherReport(
        `${res?.data?.additionalReports?.template?.name || ''} ${res?.data?.additionalReports?.period?.name || ''}`
      );
      setReprimanded(res.data.setAsReprimanded);
      setTemplateSummaryName(res.data.summaryReportTemplateName);
      setDataSummaryReportType(res.data.type);
      setDataSummaryTime(reformatDate2(res.data.reportTime));
      setTriggerColumnsId(res.data.id);
      setCharaters(res.data.triggerColumns);
      setCharatersLetter(res.data.triggerColumns);
    });
  };

  useEffect(() => {
    getSummaryReport();
  }, []);

  const changeCheckbox = index => {
    const tmp = [...characters];
    tmp[index].enable = !characters[index].enable;

    setCharaters(tmp);
  };

  const handleOnDragEnd = result => {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderItem);

    setCharaters(items);
  };

  const emptyCreateSummaryReport = text => {
    confirmAlert({
      message: text,
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  // const confirmFromDownload = text => {
  // 	confirmAlert({
  // 		message: text,
  // 		buttons: [
  // 			{
  // 				label: "Ок",
  // 				onClick: () => {
  // 					history.goBack()
  // 				},
  // 			},
  // 		],
  // 	})
  // }

  const downloadSummaryReport = () => {
    const arrayTriggers = characters;
    for (let i = 0; i < arrayTriggers.length; i++) {
      delete arrayTriggers[i].column;
      delete arrayTriggers[i].triggerName;
    }
    const postData = {
      triggerColumns: JSON.stringify(arrayTriggers),
    };
    apiPost({
      url: `/summary-report/create-trigger-columns?id=${triggerColumnsId}`,
      postData,
    }).then(res => {
      if (res.error) {
        emptyCreateSummaryReport('Ошибка');
      } else {
        // confirmFromDownload(
        // 	"Дождитесь пока сервер сформирует сводные отчеты для выгрузки"
        // )
        // downloadBase64File(res.data.asBase64, res.data.fileName)
        history.goBack();
      }
    });
  };

  const linkRoute = [
    {
      name: 'Отчеты',
      link: '/reports',
    },
    {
      name: 'Отчет организаций',
      link: `/organization-reports/${idReport}`,
    },
    {
      name: 'Сводные отчеты',
      link: `/summary-reports/${idReport}/${templateId}`,
    },
    {
      name: 'Изменение сводного отчета',
      link: `/create-summary-report/${id}`,
    },
  ];

  const viewTriigerInfo = id => {
    apiGet({
      url: `/trigger/view?id=${id}`,
    }).then(res => {
      if (res.error) {
        alert('Ошибка');
        return;
      }
      setModalViewTrigger(true);
      setTriggerData(res);
    });
  };

  return (
    <div className="edit-summary">
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="card">
        <div className="card-header">Изменение сводного отчета</div>
        <div className="card-content">
          <div className="row">
            <div className="col-12 col-md-6">
              {navigator.userAgent.includes('Firefox') ? (
                <FirefoxDateTime value={reformatDate(dataSummaryTime)} label="Время отчета" onChange={e => e} />
              ) : (
                <Input label="Время отчета" type="datetime-local" value={dataSummaryTime} onChange={e => e} />
              )}
            </div>
            <div className="col-12 col-md-6">
              <Select disabled label="Тип отчета" value={dataSuammryReportType || ''} values={dataTypeSummaryReport} />
            </div>
            <div className="col-12 col-md-6">
              <Input
                disabled
                label="Шаблон сводного отчета по триггерам"
                type="text"
                value={templateSummaryName || ''}
                onChange={e => e}
              />
            </div>
            <div className="col-12 col-md-6">
              <Input disabled label="Отчет" type="text" value={otherReport || ''} onChange={e => e} />
            </div>
            <div className="col-12 col-md-6">
              <div className="col-auto mb-3" style={{ display: 'inline-block' }}>
                <label className="d-flex">
                  <input
                    type="checkbox"
                    className="checkbox mr-2"
                    checked={reprimanded}
                    // onChange={() => setReprimanded(!reprimanded)}
                  />
                  <span>Отметить как выгруженные</span>
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <ul className="sirting-ul list-unstyled">
                {charatersLetter?.map(({ column }, index) => (
                  <li key={index} style={{ height: '32px' }}>
                    {column}
                  </li>
                ))}
              </ul>
            </div>

            <div className="col">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {provided => (
                    <ul className="tasks__list list-unstyled" {...provided.droppableProps} ref={provided.innerRef}>
                      {characters?.map(({ trigger_id, triggerName, enable }, index) => {
                        return (
                          <Draggable key={trigger_id + ''} draggableId={trigger_id + ''} index={index}>
                            {provided => (
                              <li
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                className="tasks__item"
                                onClick={e => {
                                  e.target.localName == 'span' && viewTriigerInfo(trigger_id);
                                }}
                              >
                                <span>{triggerName}</span>

                                <div
                                  onClick={() => changeCheckbox(index)}
                                  className={`custom-checkbox ${enable && '_active'}`}
                                >
                                  <div className="circle"></div>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex">
          <button className="btn mr-1  btn-primary" onClick={() => downloadSummaryReport()}>
            Сохранить
          </button>
        </div>
      </div>
      <Modal show={modalViewTrigger} onClose={() => setModalViewTrigger(false)} modalTitle="Просмотр триггера">
        <div className="row">
          <div className="col-12">
            <Input label="Название триггера" disabled={true} value={triggerData?.data?.name || ''} onChange={e => e} />
            <Input
              label="Период"
              disabled={true}
              value={`${triggerData?.data?.period?.startDate || ''} ${
                triggerData?.data?.period?.startDate ? '-' : ''
              }  ${triggerData?.data?.period?.endDate || ''}`}
              onChange={e => e}
            />
            <Textarea
              label="Код триггера"
              rows="10"
              value={triggerData?.data?.condition || ''}
              disabled={true}
            ></Textarea>
            <Textarea
              label="Описание триггера"
              rows="10"
              value={triggerData?.data?.description || ''}
              disabled={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditSummary;
