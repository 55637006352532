import React from 'react';
import { CreateTrigger } from '../../containers/Lk/index';

const CreateTriggerPage = () => {
  return (
    <>
      <CreateTrigger />
    </>
  );
};

export default CreateTriggerPage;
