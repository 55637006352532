import PropTypes from 'prop-types';
import * as React from 'react';

const Control = ({ size = 40, color = '#fff', ...props }) => {
  return (
    <svg viewBox="0 0 48 48" width={size} height={size} fill={color} {...props}>
      <path d="m24,3c4.962,0 9,4.038 9,9 0,.246-.03,.489-.09,.732l-2.925,11.7c-.222,3.108-2.82,5.568-5.985,5.568-3.111,0-5.682-2.382-5.97-5.421-.741-2.178-3.03-9.195-3.03-12.579 0-4.962 4.038-9 9-9Zm0,30c3.309,0 6,2.691 6,6 0,3.309-2.691,6-6,6-3.309,0-6-2.691-6-6 0-3.309 2.691-6 6-6Z"/>
    </svg>
  );
};

Control.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Control;
