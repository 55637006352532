import React from 'react';
import { InboxMessage } from '../../containers/Lk/index';

const InboxMessagePage = () => {
  return (
    <>
      <InboxMessage />
    </>
  );
};

export default InboxMessagePage;
