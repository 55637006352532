import PropTypes from 'prop-types';
import React from 'react';
import spinnerBlueImg from './spinner-blue.svg';
import spinnerWhiteImg from './spinner-white.svg';

const Index = ({ size = 120, color = 'blue', styles, ...props }) => (
  <img
    alt="spinner animation"
    src={color === 'blue' ? spinnerBlueImg : spinnerWhiteImg}
    style={{ width: size, height: size, ...styles }}
    {...props}
  />
);
Index.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  styles: PropTypes.object,
};

export default Index;
