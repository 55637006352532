import PropTypes from 'prop-types';
import * as React from 'react';

const Gear = ({ size = 15, color = '#fff', ...props }) => {
  return (
    <svg width={size} height={size} fill={color} viewBox="0 0 512 512" {...props}>
      <path d="M499.953 197.703l-39.351-8.555a214.162 214.162 0 00-12.664-30.539l21.785-33.886a15.302 15.302 0 00-2.051-19.09l-61.305-61.305a15.302 15.302 0 00-19.09-2.05l-33.886 21.785a214.162 214.162 0 00-30.54-12.665l-8.554-39.351A15.298 15.298 0 00299.347 0h-86.695a15.298 15.298 0 00-14.949 12.047l-8.555 39.351a214.162 214.162 0 00-30.539 12.664l-33.886-21.785a15.302 15.302 0 00-19.09 2.051l-61.305 61.305a15.302 15.302 0 00-2.05 19.09l21.785 33.886a214.162 214.162 0 00-12.665 30.54l-39.351 8.554A15.301 15.301 0 000 212.653v86.695a15.301 15.301 0 0012.047 14.949l39.351 8.555a214.162 214.162 0 0012.665 30.539l-21.786 33.886a15.302 15.302 0 002.051 19.09l61.305 61.305a15.302 15.302 0 0019.09 2.05l33.886-21.785a214.162 214.162 0 0030.54 12.665l8.554 39.351A15.298 15.298 0 00212.653 512h86.695a15.298 15.298 0 0014.949-12.047l8.555-39.351a214.162 214.162 0 0030.539-12.665l33.886 21.786a15.297 15.297 0 0019.09-2.051l61.305-61.305a15.302 15.302 0 002.05-19.09l-21.784-33.886a214.162 214.162 0 0012.664-30.54l39.351-8.554A15.301 15.301 0 00512 299.347v-86.695a15.301 15.301 0 00-12.047-14.949zM347.793 256c0 50.613-41.18 91.793-91.793 91.793s-91.793-41.18-91.793-91.793 41.18-91.793 91.793-91.793 91.793 41.18 91.793 91.793zm0 0" />
    </svg>
  );
};

Gear.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Gear;
