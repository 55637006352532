import React from 'react';
import './index.scss';

function Tabs() {
  return (
    <div className="tabs-container mt-5">
      <ul className="tabs d-flex list-unstyled">
        <li className="active">
          <a href="/">Part 1</a>
        </li>
        <li>
          <a href="/">Part 2</a>
        </li>
        <li>
          <a href="/">Part 3</a>
        </li>
      </ul>
      <div className="tabs-content">
        <div className="tabs-panel active" data-index="0">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pharetra erat at dui dictum, ac semper ante
            blandit. Suspendisse eleifend felis augue, et egestas odio tempor sed. Quisque sed justo eget arcu viverra
            sodales. Suspendisse a venenatis augue, imperdiet elementum lorem. Donec neque dui, fringilla vitae
            ultricies vel, scelerisque non ante. Aliquam erat volutpat. Donec erat velit, finibus at lobortis nec,
            venenatis ac ipsum. Proin blandit urna turpis, quis euismod dui elementum quis. Vestibulum hendrerit eget
            est ornare sollicitudin. Cras sit amet mi ut dui venenatis maximus. Donec blandit libero risus, non cursus
            mauris dignissim a. Donec vitae risus condimentum, rhoncus nibh in, scelerisque massa. Vivamus semper
            laoreet neque at molestie. Integer nulla nisl, accumsan convallis bibendum ac, suscipit ac leo. Nunc at odio
            interdum, interdum arcu eu, ullamcorper felis.
          </p>
        </div>
        <div className="tabs-panel" data-index="1">
          <p>
            Nam auctor orci nec consectetur lacinia. Fusce finibus efficitur hendrerit. Maecenas sit amet turpis eget
            velit feugiat luctus. Quisque eu tristique urna, at rhoncus lectus. Nullam non leo quis urna euismod
            convallis at a nibh. Etiam et bibendum sapien. Suspendisse potenti. Nulla et mauris lacinia tortor facilisis
            dapibus a id dolor. Fusce luctus sapien ac varius mattis. Interdum et malesuada fames ac ante ipsum primis
            in faucibus. Mauris posuere ipsum nibh, at scelerisque diam feugiat eget.
          </p>
        </div>
        <div className="tabs-panel" data-index="2">
          <p>
            Mauris id justo accumsan, semper metus non, aliquam purus. Mauris nunc libero, dignissim sit amet est in,
            egestas molestie nunc. Mauris gravida vel tellus sit amet consequat. Maecenas malesuada varius nibh, vel
            feugiat enim convallis vitae. Suspendisse et pharetra velit. Vestibulum in ex est. Vestibulum tempor
            interdum metus et malesuada. Nullam ornare mi elit, id scelerisque ante dictum in. Nullam id mauris erat.
            Nunc non imperdiet dui. Ut pellentesque ultrices tincidunt. Nulla ac diam id dolor semper malesuada nec eu
            massa. Nam tortor magna, luctus a blandit vitae, aliquam et arcu. Aenean non vestibulum leo, in pellentesque
            odio.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
