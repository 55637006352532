import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import useUrl from '../../../hooks/useUrl';
import { downloadBase64File } from '../../../utils/downloadBase64';
import { Dropzone, Modal, Pagination } from '../../components';
import { Download } from '../../icons';
import { organizationApi } from '../../../api/organization/organization';
import { useMutation, useQuery } from '@tanstack/react-query';
import Index from '../../components/Spinner';
import { modalApi } from '../../../redux/actions/modal';
import { confirmAlert } from 'react-confirm-alert';
import View from '../../icons/View';

const OrganizationRegistryListPage = () => {
  const state = useSelector(state => state);
  const { organizationId } = useParams();
  const [file, setFile] = useState(null);
  const [uploaderError, setUploaderError] = useState(null);
  const isVisible = useSelector(state => state.modal.name) === 'organization-add-registry';

  const { page } = useUrl();
  const dispatch = useDispatch();

  const {
    data: registryList,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () => organizationApi.registryList({ page, organization_id: organizationId }),
    queryKey: ['registryList', page],
  });

  const downloadMutation = useMutation({
    mutationFn: id => organizationApi.registryDownload({ id }),
    onSuccess: ({ error, data }) => {
      if (error) {
        alert('Ошибка при скачивание реестра');
        return;
      }
      downloadBase64File(data.link);
    },
  });

  const submitMutate = useMutation({
    mutationFn: () => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', '1');

      return organizationApi.registryUpload({ organizationId }, formData);
    },
    onSuccess: async ({ status, data }) => {
      if (status === 406) {
        setUploaderError(data.file.join(','));
        return;
      }

      await refetch();
      hideUploadModal();
    },
  });

  const hideUploadModal = () => {
    setUploaderError(null);
    setFile(null);
    dispatch(modalApi(null));
  };

  const showUploadModal = () => dispatch(modalApi('organization-add-registry'));

  const onUploaderReject = rejectedFiles => {
    const { file } = rejectedFiles[0];

    const errorMessage = `${file.name} не соответсвует типу xlsx`;

    setUploaderError(errorMessage);
  };

  return (
    <>
      <div className="summary card">
        <div className="card-header justify-between flex gap-2 flex-wrap items-center">
          <span>Реестры по должникам</span>

          {state.user.accessUser['organization-registry'].create && (
            <button onClick={showUploadModal} className="btn btn-primary btn-xs">
              Загрузить реестр
            </button>
          )}
        </div>

        <div className="card-content">
          {isLoading ? (
            <div className="flex items-center justify-center">
              <Index />
            </div>
          ) : (
            <div className="scroll-x">
              <table className="mt-3 low-table">
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Тип</th>
                    <th>Статус</th>
                    <th className="edit"></th>
                  </tr>
                </thead>

                <tbody>
                  {registryList?.data.models.map(i => (
                    <tr key={i.id}>
                      <td>{i?.id}</td>
                      <td>{i?.typeText}</td>
                      <td>{i?.finishedAt ? `Проверен ${i?.finishedAt}` : 'На проверке'}</td>
                      <td align="right">
                        {i?.finishedAt && (
                          <button
                            disabled={downloadMutation.isLoading}
                            className="action-btn btn btn-light disabled:cursor-not-allowed disabled:opacity-75 transition-all duration-300"
                            onClick={() => downloadMutation.mutate(i.id)}
                            title="Скачать реестр"
                          >
                            <Download />
                          </button>
                        )}

                        {i?.finishedAt && (
                          <Link
                            to={`/organization-registry-row/${i?.id}?organizationId=${organizationId}`}
                            className="action-btn ml-2 btn btn-success disabled:cursor-not-allowed disabled:opacity-75 transition-all duration-300"
                          >
                            <View size={16} />
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="mt-2 ta-center">
            <Pagination lastPage={registryList?.data.lastPage} page={page} />
          </div>
        </div>
      </div>

      <Modal
        footer={
          <button
            disabled={!file}
            className="btn btn-bg-acent disabled:cursor-not-allowed disabled:opacity-70"
            onClick={() => submitMutate.mutate()}
          >
            Добавить
          </button>
        }
        modalTitle="Добавить файл"
        show={isVisible}
        onClose={hideUploadModal}
      >
        <Dropzone
          onReject={onUploaderReject}
          acceptFileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          maxSize={1000000000000000}
          onFileRemove={() => setFile(null)}
          files={file ? [file] : []}
          errorMessage={uploaderError || ''}
          multiple={false}
          showAcceptedListString="xlsx"
          onFileUpload={f => setFile(f[0])}
        />
      </Modal>
    </>
  );
};

export default OrganizationRegistryListPage;
