import React from 'react';
import { TemplateSummaryReportTriggersElementsDownload } from '../../containers/Lk/index';

const TemplateSummaryReportTriggersElementsDownloadPage = () => {
  return (
    <>
      <TemplateSummaryReportTriggersElementsDownload />
    </>
  );
};

export default TemplateSummaryReportTriggersElementsDownloadPage;
