import React from 'react';
import { SummaryReports } from '../../containers/Lk/index';

const SummaryReportsPage = () => {
  return (
    <>
      <SummaryReports />
    </>
  );
};

export default SummaryReportsPage;
