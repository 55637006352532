import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory, useLocation } from 'react-router-dom';
import getUrlParams from '../../../hooks/getUrlParams';
import './index.scss';

const Pagination = ({ nameGet = 'page', lastPage, page, onPageChange = () => {} }) => {
  const { pathname, search, hash } = useLocation();
  const history = useHistory();

  const [pagePag, setPagePag] = useState('');

  const pageChanging = e => {
    const { page } = getUrlParams();
    setPagePag(page);

    let newSearch = '';
    const newPage = e.selected + 1;

    if (search.length) {
      if (page) {
        const regexp = new RegExp(nameGet + `=[0-9]+`);
        newSearch = search.replace(regexp, `${nameGet}=${newPage}`);
      } else {
        newSearch = `${search}&${nameGet}=${newPage}`;
      }
    } else {
      newSearch = `?${nameGet}=${newPage}`;
    }

    history.replace(`${pathname}${newSearch}${hash}`);
    onPageChange();
  };

  useEffect(() => {
    const { page } = getUrlParams();
    setPagePag(page);
  }, [window.location.href]);

  useEffect(() => {
    setPagePag(page);
  }, [page]);

  return (
    <ReactPaginate
      pageCount={lastPage && lastPage === 1 ? 0 : lastPage}
      pageRangeDisplayed={5}
      marginPagesDisplayed={3}
      onPageChange={pageChanging}
      activeClassName={'active'}
      containerClassName={'pagination d-inline-flex ta-center'}
      pageLinkClassName={'link'}
      pageClassName={'link'}
      previousLabel={false}
      nextLabel={false}
      forcePage={pagePag ? +pagePag - 1 : 0}
    />
  );
};
Pagination.propTypes = {
  lastPage: PropTypes.number,
  page: PropTypes.string,
  onPageChange: PropTypes.func,
  nameGet: PropTypes.string,
};

export default memo(Pagination);
