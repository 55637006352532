import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { userReducer } from './userReducer';
import { dkReducer } from './dkReducer';
import { modalReducer } from './modalReducer';
import { organizationAttributeReducer } from './organizationAttributelReducer';

export const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  dk: dkReducer,
  modal: modalReducer,
  orgAttributes: organizationAttributeReducer,
});
