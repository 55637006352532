import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { apiGet, apiPost } from '../../../../api/index';
import PropTypes from 'prop-types';
import { Input, Modal } from '../../../components';
import { Control } from '../../../icons/index';
import { downloadReport } from '../../../../utils/downloadReport';
import './index.scss';


const ControlBtn = ({idLink, activeControl, requiRement, requirementId, fetchData}) =>{
    
    const [modalUploadRequest, setModalUploadRequest] = useState(false);
    const [incomingDateRequest, setIncomingDateRequest] = useState('');
    const [errObj, setErrObj] = useState({});
    const [color, setcolor] = useState(false);
    const [activeBtn, setActiveBtn] = useState(true);
    const [sending, setSending] = useState(false);
    const [titleBtn, setTitleBtn] = useState(false);
    const date = new Date();
    const year = date.getFullYear();
    let mounth = date.getMonth();
    if(mounth + 1 < 9){
        mounth = `0${mounth + 1}`;
    }else{
        mounth = `${mounth + 1}`;
    }
    const day = date.getDate();
    const stringDate = `${year}-${mounth}-${day}`;

    const openModalUploadRequest = () => {
        setModalUploadRequest(true);
      };

    const checkControlBtn = () => {
        if(activeControl){
            let controlDate = activeControl.controlTime.split(' ')[0];
            let controlDateY = controlDate.split('.')[2];
            let controlDateM = controlDate.split('.')[1];
            let controlDateD = controlDate.split('.')[0];
            controlDate = `${controlDateY}-${controlDateM}-${controlDateD}`;
            setcolor(true);
            setTitleBtn(true);
            setIncomingDateRequest(controlDate);
        }
    };

    useEffect(() => {
        checkControlBtn();
    }, []);

    const addControl = () => {
        apiPost({ url: `/report-instance/set-control?id=${idLink}`})
        .then(res => {
        if (res.error) {
            setErrObj(res.data);
            console.error('Ошибка контроля');
            setcolor(false);
            setTitleBtn(false);
        } else{
            setcolor(true);
            setTitleBtn(true);
            setModalUploadRequest(false);
            fetchData();
        }

        });
    };
    
    const removeControl = () => {
        apiPost({ url: `/report-instance/unset-control?id=${idLink}`})
        .then(res => {
          if (res.error) {
            console.error('Ошибка контроля');
          }
          setcolor(false);
          setTitleBtn(false);
          setIncomingDateRequest('');
          setModalUploadRequest(false);
          setActiveBtn(false);
        });
    };

    const downloadFile = id => {
        if (sending) return false;
    
        setSending(true);
    
        apiGet({ url: `/requirement/view?id=${id}&download=1`})
          .then(res => {
            if (res.error) {
              console.error('Скачивание требований');
              return;
            }
            
            downloadReport(res.data);
          })
          .finally(() => {
            setSending(false);
          });
      };
    
    const confirMessage = () => {
        confirmAlert({
            message: 'Вы действительно хотите поставить отчет на контроль?',
            buttons: [
              {
                label: 'Да',
                onClick: () => addControl(),
              },
              {
                label: 'Нет',
                onClick: () => {
                    setcolor(false);
                    setTitleBtn(false);
                    setActiveBtn(false);
                },
              },
            ],
        });

    };

    return(
        <>
    <div className="col-auto mt-1 mb-1">
        <button
        className={color ? 'action-btn btn btn-danger' : 'action-btn btn btn-warning'}
        onClick={color ? openModalUploadRequest : confirMessage}
        title={titleBtn ? 'На контроле' : 'Поставить на контроль'}>
        <Control />
        </button>
    </div>

        <Modal
            show={modalUploadRequest}
            onClose={() => {
            setModalUploadRequest(false);
            }}
            modalTitle="Постановка на контроль">
            <Input
            fieldClasses="mt-3"
            disabled
            value={incomingDateRequest}
            onChange={setIncomingDateRequest}
            errObj={errObj.controlDate}
            label="На контроле до"
            type="date"
            min={stringDate}
            />
            <div className="row d-flex js-start">
                {/* <div className="col-12 col-md-auto mt-1">
                    <button className="btn btn-success"
                    onClick={addControl}>
                        Поставить на контроль
                    </button>
                </div> */}
                    {requiRement ? 
                        (<div className="col-12 col-md-auto mt-1">
                            <button className="btn btn-danger mr-3 mb-1"
                            onClick={removeControl}>
                                Снять с контроля
                            </button>
                            <button className={activeBtn ? 'btn btn-primary' : 'btn btn-primary d-none'}
                            onClick={() => downloadFile(requirementId)}>
                                Скачать требование
                            </button>
                        </div>)
                        :
                        (<div className="col-12 col-md-auto mt-1">
                        <button className="btn btn-danger"
                        onClick={removeControl}>
                            Снять с контроля
                        </button>
                    </div> )  
                    }
            </div>
        </Modal>
        </>
    );
};

ControlBtn.propTypes = {
    idLink: PropTypes.number,
    activeControl: PropTypes.any,
    requiRement: PropTypes.any,
    requirementId: PropTypes.any,
    fetchData: PropTypes.any,
};
export default ControlBtn;