import React from 'react';
import { TemplateSummaryReportTriggers } from '../../containers/Lk/index';

const TemplateSummaryReportTriggersPage = () => {
  return (
    <>
      <TemplateSummaryReportTriggers />
    </>
  );
};

export default TemplateSummaryReportTriggersPage;
