/* eslint-disable indent */
import {
  ACCESS_USER,
  DATA_FOLDER_CHAT,
  UPDATE_FOLDER_CHAT,
  UPDATE_NOT_REPRIMANDED,
  UPDATE_PROFILE,
  UPDATE_UNREAD_MESSAGES,
  UPDATE_UNREAD_MESSAGES_INCOMING,
  UPLOADING_STATUS_PROGRESS
} from '../types';

const initialState = {
  profile: {},
  addFolderModalState: false,
  dataInboxFolders: {},
  accessUser: {},
  unreadMessages: '',
};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROFILE:
      return { ...state, profile: payload };

    case UPDATE_FOLDER_CHAT:
      return {
        ...state,
        addFolderModalState: payload,
      };

    case DATA_FOLDER_CHAT:
      return {
        ...state,
        dataInboxFolders: payload,
      };

    case ACCESS_USER:
      return {
        ...state,
        accessUser: payload,
      };

    case UPDATE_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: payload,
      };

    case UPDATE_NOT_REPRIMANDED:
      return {
        ...state,
        countNotReprimanded: payload,
      };

    case UPLOADING_STATUS_PROGRESS:
      return {
        ...state,
        uploadingProgress: payload,
      };

    case UPDATE_UNREAD_MESSAGES_INCOMING:
      return {
        ...state,
        countNotReprimandedIncoming: payload,
      };

    // case UPLOADING_STATUS_PROGRESS:
    // 	return {
    // 		...state,
    // 		uploadingProgress: payload,
    // 	}

    default:
      return state;
  }
};
