import React from 'react';
import {Input, Pagination, Select} from '../../components';
import Index from '../../components/Spinner';
import {Download, Send} from '../../icons';
import {Checkbox, Tooltip} from 'antd';
import Done from '../../icons/Done';
import {API_LIMIT_LIST, SUCCESS_LIST} from '../../../constants/select';
import {CustomButton} from '../../components/Button/Button';
import {STATUS_LIST} from '../Invitation/InvitationListPage/useInvitationList';
import {useExtractPage} from './useExtractPage';
import {prepareUnixTime} from '../../../utils/prepareUnixTime';
import cn from 'classnames';
import ResetFilters from '../../icons/ResetFilters';

// const resetFiltersVisible = !!Object.values(values).filter(i => Boolean(i)).length;

const ExtractPage = () => {
    const {
        setSelectExtractAll,
        handleSendExtract,
        setSelectExtractIds,
        extractALlIdsCheckbox,
        downloadFile,
        downloadFileOrganization,
        handleValuesChange,
        values,
        extractQueryList,
        page,
        handleExecute,
        extractIdsList,
        resetTableFilters,
        resetFiltersVisible,
    } = useExtractPage();


    return (
        <div>
            <div className="card">
                <div className="card-header jc-between d-flex ai-center">
                    Журнал выписок


                    <div className="flex items-center gap-3">
                        <Tooltip title='Сбросить фильтры'>
                            <CustomButton
                                title='Сбросить фильтры'
                                onClick={resetTableFilters}
                                className={cn({'invisible opacity-0': !resetFiltersVisible},
                                    'bg-yellow hover:bg-yellow hover:opacity-80 transition-all'
                                )}
                            >
                                <ResetFilters/>
                            </CustomButton>
                        </Tooltip>
                        <CustomButton
                            disabled={!extractIdsList.length}
                            view='warning'
                            onClick={() => handleSendExtract({all: true})}
                        >
                            Отправить
                        </CustomButton>
                    </div>
                </div>
                <div className="card-content">
                    {!extractQueryList.data?.data?.models?.length && !extractQueryList.isLoading ? (
                        <div className='w-full text-center font-medium text-primary'>Ничего не найдено</div>
                    ) : (
                        <div className='grid-cols-1 m767:grid-cols-2  grid xl1400:grid-cols-4 gap-2'>
                            <Input
                                fieldClasses='w-full max-m767:!mb-0'
                                label='Организация'
                                placeholder='Начните вводить'
                                value={values.organizationNameSearch}
                                onChange={v => handleValuesChange(v, 'organizationNameSearch')}
                            />
                            <Select
                                fieldClasses='w-full max-m767:!mb-0'
                                label='Статус исполнения'
                                value={values.executedStatus}
                                onChange={v => handleValuesChange(v, 'executedStatus')}
                                values={SUCCESS_LIST}
                            />
                            <Select
                                fieldClasses='w-full max-m767:!mb-0'
                                label='Статус отправки'
                                value={values.status}
                                onChange={v => handleValuesChange(v, 'status')}
                                values={STATUS_LIST}
                            />
                            <Select
                                fieldClasses='w-full flex-1 max-m767:!mb-0'
                                label='Кол-во записей'
                                value={values.limit}
                                onChange={v => handleValuesChange(v, 'limit')}
                                values={[{id: '', name: 'Выберите количество'}, ...API_LIMIT_LIST]}
                            />
                        </div>
                    )}

                    {extractQueryList.isLoading ? (
                        <div className='d-flex jc-center '>
                            <Index size={60}/>
                        </div>
                    ) : (
                        <div className="scroll-x">
                            <table className=" middle-table">
                                {!!extractQueryList.data?.data?.models?.length && (
                                    <thead>
                                    <tr>
                                        <th>
                                            <div className="flex flex-col">
                                                <span className='whitespace-nowrap'>№ протокола</span>
                                                <Checkbox checked={extractALlIdsCheckbox}
                                                          onChange={setSelectExtractAll}>
                                                    Выбрать все
                                                </Checkbox>
                                            </div>
                                        </th>
                                        <th>Дата и время отправки выписки</th>
                                        <th>Организация</th>
                                        <th>Исполнено/Не исполнено</th>
                                        <th>Отправлено/Не отправлено</th>
                                        <th className="settings"/>
                                    </tr>
                                    </thead>
                                )}
                                <tbody>
                                {extractQueryList.data.data?.models?.map((extract) => (
                                    <tr key={extract.id}>
                                        <td>
                                            <Checkbox checked={extractIdsList.includes(extract.id)}
                                                      onChange={() => setSelectExtractIds(extract.id)}>
                                                {extract.protocol.number || '-'}
                                            </Checkbox>
                                        </td>
                                        <td>{extract.extractSentAt || '-'}</td>
                                        <td style={{
                                            maxWidth: '20vw'
                                        }}>{extract.organization.name || '-'}</td>
                                        <td>{extract.isExecuted ? 'Исполнено' : 'Не исполнено'}</td>
                                        <td>{extract.isExtractSent ? 'Отправлено' : 'Не отправлено'}</td>
                                        <td>
                                            <div className='d-flex ai-center jc-end gap-2'>
                                                {extract.isHasDoc && (
                                                    <Tooltip title='Скачать требование'>
                                                        <button className="action-btn btn bg-red/80" title="Скачать"
                                                                onClick={() => downloadFileOrganization(extract.id,extract.protocol.id)}>
                                                            <Download color='white'/>
                                                        </button>
                                                    </Tooltip>

                                                )}
                                                <Tooltip title='Скачать выписку'>
                                                    <button className="action-btn btn btn-light" title="Скачать"
                                                            onClick={() => downloadFile(extract.id)}>
                                                        <Download/>
                                                    </button>
                                                </Tooltip>
                                                {!extract.isExecuted && (
                                                    <button
                                                        className="action-btn btn btn-success"
                                                        onClick={() => handleExecute(extract.id)}
                                                        title="Изменить статус"
                                                    >
                                                        <Done/>
                                                    </button>
                                                )}

                                                <button
                                                    className="action-btn btn btn-info"
                                                    title="Отправить"
                                                    onClick={() => handleSendExtract({id: extract.id})}
                                                >
                                                    <Send/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {!extractQueryList.isLoading && (
                        <div className="d-flex jc-center">
                            <Pagination lastPage={extractQueryList?.data?.data?.lastPage} page={page}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};


export default ExtractPage;
