import React from 'react';
import CreateRule from '../../containers/Lk/CreateRule';

const CreateRulePage = () => {
  return (
    <>
      <CreateRule />
    </>
  );
};

export default CreateRulePage;
