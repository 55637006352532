import React from 'react';
import { TemplateSummaryReportElementsDownload } from '../../containers/Lk/index';

const TemplateSummaryReportElementsDownloadPage = () => {
  return (
    <>
      <TemplateSummaryReportElementsDownload />
    </>
  );
};

export default TemplateSummaryReportElementsDownloadPage;
