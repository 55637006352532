import React from 'react';
import { EditSummary } from '../../containers/Lk/index';

const EditSummaryReportPage = () => {
  return (
    <>
      <EditSummary />
    </>
  );
};

export default EditSummaryReportPage;
