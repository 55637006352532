import React from 'react';
import {
    toggleCreateInvitationModal,
    toggleUpdateInvitationModal
} from '../../../../redux/actions/dk';
import Index from '../../../components/Spinner';
import {Pencil} from '../../../icons/Pencil';
import {Download, Send, TrashCan} from '../../../icons';
import {Input, Pagination, Select} from '../../../components';
import './InvitationListPage.scss';
import {InvitationCreateModal} from '../InvitationCreateModal';
import {InvitationUpdateModal} from '../InvitationUpdateModal';
import {Checkbox, Tooltip} from 'antd';
import cn from 'classnames';
import {CustomButton} from '../../../components/Button/Button';
import ResetFilters from '../../../icons/ResetFilters';
import {getFolderIdUrl} from '../../../../utils/getFolderId';
import {API_LIMIT_LIST} from '../../../../constants/select';
import {STATUS_LIST, useInvitationList} from './useInvitationList';

const InvitationListPage = () => {
    const {
        invitationIds,
        dispatch,
        checkboxAll,
        handleConfirmDeleteInvitation,
        downloadFile,
        setReasonIds,
        selectAllReasonIds,
        values,
        handleValuesChange,
        selectReasonOptions,
        navigate,
        resetFiltersVisible,
        handleSendInvitation,
        page,
        resetTableFilters,
        invitationQueryList
    } = useInvitationList();


    return (
        <div>
            <div className="card">
                <div className="card-header jc-between d-flex ai-center flex-wrap">
                    Приглашения

                    <div className="gap-2 jc-between d-flex ai-center">
                        <Tooltip title='Сбросить фильтры'>
                            <CustomButton
                                title='Сбросить фильтры'
                                onClick={resetTableFilters}
                                className={cn({'invisible opacity-0': !resetFiltersVisible},
                                    'bg-yellow hover:bg-yellow hover:opacity-80 transition-all'
                                )}
                            >
                                <ResetFilters/>
                            </CustomButton>
                        </Tooltip>

                        <CustomButton
                            disabled={!invitationIds.length}
                            onClick={() => handleSendInvitation({all: true})}
                        >
                            Отправить приглашения
                        </CustomButton>
                        <button
                            onClick={() => dispatch(toggleCreateInvitationModal(true))}
                            className="btn btn-primary btn-xs"
                        >
                            Создать новое приглашение
                        </button>
                    </div>

                </div>
                <div className="card-content">
                    {!invitationQueryList.data?.data?.models?.length  && !invitationQueryList.isLoading ? (
                        <div className='w-full text-center font-medium text-primary'>Ничего не найдено</div>
                    ) : (
                        <div className="flex flex-col">
                            <div className='flex  flex-1 gap-2 max-m767:flex-col'>
                                <Select
                                    fieldClasses='w-full flex-1 !mb-0'
                                    label='Причина приглашения'
                                    value={values.dk_reason_id}
                                    onChange={v => handleValuesChange(v, 'dk_reason_id')}
                                    values={[{id: '', name: 'Выберите причину'}, ...selectReasonOptions]}
                                />
                                <Input
                                    fieldClasses='w-full flex-1 !mb-0'
                                    label='Организация'
                                    placeholder='Начните вводить'
                                    value={values.organizationNameSearch}
                                    onChange={v => handleValuesChange(v, 'organizationNameSearch')}
                                />

                            </div>


                            <div className="flex  max-m767:space-y-8 mt-5 my-7 flex-1 gap-2 max-m767:flex-col">
                                {/*<Input*/}
                                {/*    label='Дата и время с'*/}
                                {/*    value={values.outgoingDateStart}*/}
                                {/*    onChange={v => handleValuesChange(v, 'outgoingDateStart')}*/}
                                {/*    type="datetime-local"*/}
                                {/*    fieldClasses='h-full w-full flex-1 max-h-[38px] mb-0'*/}
                                {/*/>*/}

                                {/*<Input*/}
                                {/*    label='Дата и время по'*/}
                                {/*    value={values.outgoingDateEnd}*/}
                                {/*    onChange={v => handleValuesChange(v, 'outgoingDateEnd')}*/}
                                {/*    type="datetime-local"*/}
                                {/*    fieldClasses='h-full w-full flex-1 max-h-[38px] mb-0'*/}
                                {/*/>*/}
                                <Select
                                    fieldClasses='w-full flex-1 !mb-0'
                                    label='Статус'
                                    className=' min-h-[45px]'
                                    value={values.status}
                                    onChange={v => handleValuesChange(v, 'status')}
                                    values={STATUS_LIST}
                                />
                                <Input
                                    label='Дата и время'
                                    value={values.invitationTime}
                                    onChange={v => handleValuesChange(v, 'invitationTime')}
                                    type="datetime-local"
                                    fieldClasses='h-full w-full flex-1 max-h-[38px] mb-0'
                                />
                                <Select
                                    fieldClasses='w-full flex-1 !mb-0'
                                    className=' min-h-[45px]'
                                    label='Кол-во записей'
                                    value={values.limit}
                                    onChange={v => handleValuesChange(v, 'limit')}
                                    values={[{id: '', name: 'Выберите количество'}, ...API_LIMIT_LIST]}
                                />
                            </div>
                        </div>
                    )}


                    {invitationQueryList.isLoading ? (
                        <div className='d-flex jc-center '>
                            <Index size={60}/>
                        </div>
                    ) : (
                        <div className="scroll-x">
                            <table className=" middle-table">
                                {!!invitationQueryList.data?.data?.models?.length && (
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className='flex flex-col'>
                                                    <span className='whitespace-nowrap'>№ приглашения</span>
                                                    <Checkbox checked={checkboxAll} onChange={selectAllReasonIds}>Выбрать
                                                        все</Checkbox>
                                                </div>
                                            </th>
                                            <th>Причина</th>
                                            <th>Организация</th>
                                            <th>Отправлено/Не отправлено</th>
                                            <th>Дата и время приглашения</th>
                                            <th className="settings"/>
                                        </tr>
                                    </thead>
                                )}
                                <tbody>
                                {invitationQueryList.data.data?.models.map((invitation) => (
                                    <tr
                                        className='cursor-pointer go-link'
                                        onClick={() => navigate.push(`/invitation-view/${invitation.id}${getFolderIdUrl()}`)}
                                        key={invitation.id}
                                    >
                                        <td>
                                            <label className='gap-2 d-flex ai-center'>
                                                <Checkbox
                                                    checked={invitationIds?.includes(invitation?.id)}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setReasonIds(invitation.id);
                                                    }}
                                                />
                                                {invitation?.outgoing_number || '-'}
                                            </label>
                                        </td>
                                        <td>{invitation.dkReason.name || ''}</td>
                                        <td style={{maxWidth: '18vw'}}>{invitation.organization.name || '-'}</td>
                                        <td>{invitation.statusText || '-'}</td>
                                        <td className='whitespace-nowrap'> {invitation.timeAsString || '-'}</td>
                                        <td>
                                            <div onClick={e => e.stopPropagation()}
                                                 className='d-flex ai-center jc-end gap-2'>
                                                <button
                                                    className="action-btn btn btn-light"
                                                    title="Скачать"
                                                    onClick={() => downloadFile(invitation.id)}
                                                >
                                                    <Download/>
                                                </button>

                                                <button
                                                    className="action-btn btn btn-info"
                                                    title="Отправить"
                                                    onClick={() => handleSendInvitation({id: String(invitation.id)})}
                                                >
                                                    <Send/>
                                                </button>

                                                <button
                                                    className="action-btn btn btn-success"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        dispatch(toggleUpdateInvitationModal({show: true, invitation}));
                                                    }}
                                                    title="Редактировать"
                                                >
                                                    <Pencil/>
                                                </button>

                                                <button
                                                    className="action-btn btn btn-danger"
                                                    onClick={(e) => handleConfirmDeleteInvitation(e, invitation.id)}
                                                    title="Удалить"
                                                >
                                                    <TrashCan/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {!invitationQueryList.isLoading && (
                        <div className="d-flex jc-center">
                            <Pagination lastPage={invitationQueryList.data.data?.lastPage} page={page}/>
                        </div>
                    )}
                </div>
            </div>

            <InvitationCreateModal fetchData={() => invitationQueryList.refetch()}/>
            <InvitationUpdateModal fetchData={() => invitationQueryList.refetch()}/>
        </div>
    );
};

export default InvitationListPage;
