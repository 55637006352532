import { Breadcrumbs, Input, Select, Pagination } from '../../components';
// import ReactPaginate from 'react-paginate';
import { useFetch } from '../../../hooks/useFetch';
import { useState, useEffect } from 'react';
import { Blogging, Download } from '../../icons';
import { apiGet} from '../../../api';
import { downloadReport } from '../../../utils/downloadReport';
import { useHistory} from 'react-router-dom';
import updateUrl from '../../../hooks/updateUrl';
import useUrl from '../../../hooks/useUrl';


export const JournalRequirements = () => {
  const { page, reportTemplateId, reportPeriodId, organizationName, organizationOgrn,createdDateStart, createdDateEnd, isExecutedSearch } = useUrl();
  // const [ setPage ] = useState(1);

  const [sending, setSending] = useState(false);

  const history = useHistory();
  const [errObj] = useState({});

  const [filterReport, setFilterReport] = useState(reportTemplateId);
  const [filterPeriod, setFilterPeriod] = useState(reportPeriodId);
  const [searchInputOgrn, setSearchInputOgrn] = useState(organizationOgrn);
  const [searchInputOrgName, setSearchInputOrgName] = useState(organizationName);
  const [createDateStart, setDateStart] = useState(createdDateStart);
  const [createDateEnd, setDateEnd] = useState(createdDateEnd);
  const [executed, setExecuted] = useState(isExecutedSearch);

  const [newReportPeriodData, setnewReportPeriodData] = useState(null);
  const [newReportTemplateData, setNewReportTemplateData] = useState(null);

  const styles = {
    verticalAlign: 'middle'
  };

  const date = new Date();
  const year = date.getFullYear();
  let mounth = date.getMonth();
  if(mounth + 1 < 9){
      mounth = `0${mounth + 1}`;
  }else{
      mounth = `${mounth + 1}`;
  }
  let day = date.getDate();
  if(day < 9){
    day = `0${day}`;
  }else{
    day = `${day}`;
  }
  const stringDate = `${year}-${mounth}-${day}`;

  let url = `/requirement?page=${page}&limit=10`;

  const filterReportFunc = v => {
    setFilterReport(v);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'reportTemplateId', value: v }));
  };
  if (filterReport) {
    if (filterReport == 0) {
      setFilterReport('');
    } else url += `&reportTemplateId=${filterReport}`;
  }

  const filterPeriodFunc = v => {
    setFilterPeriod(v);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'reportPeriodId', value: v }));
  };
  if (filterPeriod) {
    if (filterPeriod == 0) {
      setFilterPeriod('');
    } else url += `&reportPeriodId=${filterPeriod}`;
  }

  const changeOgrnSearchName = v => {
    setSearchInputOgrn(v);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'organizationOgrn', value: v }));
  };
  if (searchInputOgrn) {
    if (searchInputOgrn == '') {
      setSearchInputOgrn('');
    } else url += `&organizationOgrn=${searchInputOgrn}`;
  }

  const changeOrganisationSearch = v => {
    setSearchInputOrgName(v);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'organizationName', value: v }));
  };
  if (searchInputOrgName) {
    if (searchInputOrgName == '') {
      setSearchInputOrgName('');
    } else url += `&organizationName=${searchInputOrgName}`;
  }

  const DateStartFunc = v => {
    setDateStart(v);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'createdDateStart', value: v }));
  };
  if (createDateStart) {
    if (createDateStart == '') {
      setDateStart('');
    } else url += `&createdDateStart=${createDateStart}`;
  }
  const DateEndFunc = v => {
    setDateEnd(v);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'createdDateEnd', value: v }));
  };
  if (createDateEnd) {
    if (createDateEnd == '') {
      setDateEnd('');
    } else url += `&createdDateEnd=${createDateEnd}`;
  }
  
  const executedFunc = v => {
    setExecuted(v);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'isExecutedSearch', value: v }));
  };
  if (executed) {
    if (executed == '') {
      setExecuted('');
    } else url += `&isExecutedSearch=${executed}`;
  }

  const { response: reportPeriod } = useFetch({
    url: '/report-period?limit=1000',
  });

  // Получение списка шаблонов
  const { response: reportTemplate } = useFetch({
    url: '/report-template?limit=1000',
  });

  const linkRoute = [
    {
      name: 'Журнал исходящих требований',
      link: '/requirement',
    },
  ];

  useEffect(() => {
    let newReportPeriod = { id: 0, name: 'Выберите период' };
    reportPeriod?.models?.unshift(newReportPeriod);
    setnewReportPeriodData(reportPeriod);
  }, [reportPeriod]);

  useEffect(() => {
    let newReportTemplate = { id: 0, name: 'Выберите форму отчетности' };
    reportTemplate?.models?.unshift(newReportTemplate);
    setNewReportTemplateData(reportTemplate);
  }, [reportTemplate]);


  const viewFile = id => {
    if (sending) return false;

    setSending(true);

    apiGet({ url: `/requirement/view?id=${id}`})
      .then(res => {
        if (res.error) {
          console.error('Просмотр требования)');
          return;
        }
        
        downloadReport(res.data);
      })
      .finally(() => {
        setSending(false);
      });
  };

  const downloadFile = id => {
    if (sending) return false;

    setSending(true);

    apiGet({ url: `/requirement/view?id=${id}&download=1`})
      .then(res => {
        if (res.error) {
          console.error('Скачивание требований');
          return;
        }
        
        downloadReport(res.data);
      })
      .finally(() => {
        setSending(false);
      });
  };

  const downloadFileSearch = () => {
    if (sending) return false;

    setSending(true);

    apiGet({ url: '/requirement/download' + window.location.search})
      .then(res => {
        if (res.error) {
          console.error('Скачивание всего журнала');
          return;
        }
        
        downloadReport(res.data);
      })
      .finally(() => {
        setSending(false);
      });
  };

  const downloadFileAll = () => {
    if (sending) return false;

    setSending(true);

    apiGet({ url: '/requirement/download'})
      .then(res => {
        if (res.error) {
          console.error('Скачивание всего журнала');
          return;
        }
        downloadReport(res.data);
      })
      .finally(() => {
        setSending(false);
      });
  };

  const { response, fetchData } = useFetch({ url });
  let journalRequirements = useFetch({url});

  const renderReport = report => {
    return (
      <tr key={report.id}>
        <td>{report.outgoingNumber}</td>
        <td>{report.createdAt}</td>
        <td>
          <div className="d-flex flex-column" style={styles}>{report.organizationName}</div>
        </td>
        <td>
        <div className="d-flex flex-column" style={styles}>{report.organizationOgrn}</div>
        </td>
        <td>
        <div className="d-flex flex-column" style={styles}>{report.template.name}</div>
        </td>
        <td>
        <div className="d-flex flex-column" style={styles}>{report.period.name}</div>
        </td>
        
        <td>
          <div className="d-flex flex-column" style={styles}>{report.isExecuted ? ' Отработано' : ' Не отработано'}</div>
        </td>
        <td style={styles}>
            <div className="row row-sm">
              <div className="col-auto">
                <button 
                  className="action-btn btn btn-light mt-2" 
                  title="Выгрузить требование" 
                  onClick={() => downloadFile(report.id)}>
                  <Download />
                </button>
              </div>

              <div className="col-auto">
                <button
                  className="action-btn btn btn-success mt-2"
                  title="Посмотреть содержимое"
                  onClick={() => viewFile(report.id)}>
                  <Blogging />
                </button>
              </div>
            </div>
        </td>
      </tr>
    );
  };

  
  return (
    <>
      <Breadcrumbs linkRoute={linkRoute} />
        <div className="card organization-reports">
          <div className="card-header d-flex jc-center">
            <div className="ml-auto">
                <button 
                  className="btn btn-primary" 
                  title="Выгрузить журнал" 
                  onClick={downloadFileAll}>
                    Выгрузить журнал
                </button>
            </div>
          </div>
        </div>
      <div className="card">
        <div className="card-content">
          <div className="row d-flex ai-end jc-end">
          <div className="col-12 col-md-4 name-event">
          <Select
            label="Форма отчетности"
            onChange={filterReportFunc}
            value={filterReport}
            values={newReportTemplateData?.models || []}
          />
          </div>
          <div className="col-12 col-md-4">
          <Select
            label="Отчетный период"
            onChange={filterPeriodFunc}
            value={filterPeriod}
            values={newReportPeriodData?.models || []}
          />
          </div>
          <div className="col-12 col-md-4">
                <Input
                label="ОГРН"
                type="tel"
                value={searchInputOgrn}
                onChange={changeOgrnSearchName}
                />
            </div>
            <div className="col-12 col-md-8">
                <Input
                label="Наименование организации"
                type="tel"
                value={searchInputOrgName}
                onChange={changeOrganisationSearch}
                />
            </div>
            <div className="col-12 col-md-4">
                {/* <Input
                label="Статус"
                type="tel"
                value={executed}
                onChange={executedFunc}
                /> */}
              <Select
                label="Статус"
                values={
                    [
                      {
                      id: '',
                      name: 'Выберите статус',
                      },
                      {
                      id: 1,
                      name: 'Отработано',
                      },
                      {
                      id: 2,
                      name: 'Не отработано',
                      },
                    ]
                  }
                onChange={executedFunc}
              />
            </div>
              <div className="col-12 col-md-4">
                <Input
                  type="date"
                  value={createDateStart}
                  onChange={DateStartFunc}
                  error={errObj.dateStart}
                />
              </div>

              <div className="col-12 col-md-4">
                <Input 
                  type="date" 
                  value={createDateEnd} 
                  onChange={DateEndFunc} 
                  error={errObj.dateEnd} 
                />
              </div>
              <button 
              className="btn btn-primary ml-auto mr-3" 
              title="Выгрузить требования" 
              onClick={downloadFileSearch}>
                Выгрузить требования
            </button>
            </div>


          <div className="scroll-x">
            <table className="mt-3 big-table">
              <thead>
                <tr>
                  <th>Исх. №</th>
                  <th>Время и дата отправки</th>
                  <th>Наименование организации</th>
                  <th>ОГРН</th>
                  <th>Форма отчетности</th>
                  <th>Отчетный период</th>
                  <th>Статус</th>
                  <th />
                </tr>
              </thead>
              <tbody>{journalRequirements.response?.models.map(renderReport)}</tbody>
            </table>
          </div>

          <div className="mt-2 ta-center">
          <Pagination lastPage={response?.lastPage} page={page} />
          {/* <ReactPaginate
              pageCount={journalRequirements.response?.lastPage === 1 ? 0 : journalRequirements.response?.lastPage}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              onPageChange={e => setPage(e.selected + 1)}
              activeClassName={'active'}
              containerClassName={'pagination d-inline-flex ta-center'}
              pageLinkClassName={'link'}
              pageClassName={'link'}
              previousLabel={false}
              nextLabel={false}
            /> */}
          </div>
        </div>
      </div>

    </>
  );
};