import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { Dropzone, Modal } from '../../../components';
import { Download, Gear, TrashCan } from '../../../icons';
import './index.scss';

const ReportsTemplate = () => {
  const history = useHistory();

  const state = useSelector(state => state);
  const [viewModalUpload, setViewModalUpload] = useState(false);
  const [sending, setSending] = useState(false);
  const [page, setPage] = useState(1);
  const [files, setFiles] = useState([]);
  const [reportId, setReportId] = useState(0);
  const [sorting, setSorting] = useState('-createdAt');

  const showModalNewEvent = isShow => {
    setViewModalUpload(isShow);
  };
  const { response, fetchData } = useFetch({
    url: `/report-template?page=${page}&limit=10&sort=${sorting}`,
  });

  const getSortClass = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        return '_desc';
      } else {
        return '_asc';
      }
    }

    return '';
  };

  const getDocAlert = () => {
    confirmAlert({
      message: 'Пожалуйста добавьте файл',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const referenceSuccessAlert = () => {
    confirmAlert({
      message: 'Эталон успешно загружен',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const onDeleteReportAlert = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить?',
      buttons: [
        {
          label: 'Да',
          onClick: () => onDeleteReport(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const sortTable = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        setSorting(sortType);
      } else {
        setSorting(`-${sortType}`);
      }
    } else {
      setSorting(sortType);
    }
  };

  const sendUpload = id => {
    if (!files.length) {
      getDocAlert();
      return;
    }

    if (!sending) {
      let formData = new FormData();
      formData.append('reference', files[0]);

      apiPost({
        url: `/report-template/create-reference?id=${id}`,
        postData: formData,
      })
        .then(res => {
          if (res.error) {
            console.error('Создание эталона');
            return;
          }

          showModalNewEvent(false);
          fetchData();
          referenceSuccessAlert();
        })
        .finally(() => setSending(false));
    }
  };

  const onDeleteReport = id => {
    apiGet({ url: `/report-template/delete?id=${id}` }).then(res => {
      if (res.error) {
        console.error('Удаление отчета');
        return;
      }

      fetchData();
    });
  };

  const handlerUploadFile = e => {
    setFiles(e);
  };

  const onFileRemove = fName => {
    const fList = files.filter(f => f.name !== fName);

    setFiles(fList);
  };

  return (
    <div className="reports-template">
      <div className="card">
        <div className="card-header">
          <div className="row jc-between">
            <div className="col-auto">Шаблоны отчетов</div>

            {state.user.accessUser[`report-template`][`create`] && (
              <div className="col-auto">
                <Link className="btn btn-primary btn-xs" to="/create-template">
                  Создать шаблон
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="card-content">
          <div className="scroll-x">
            <table className="mt-3 low-table">
              <thead>
                <tr>
                  <th>Название шаблона</th>
                  <th onClick={() => sortTable('createdAt')} style={{ maxWidth: 50, cursor: 'pointer' }}>
                    <div className="d-flex">
                      <span> Дата создания</span>
                      <div className={`sort ${getSortClass('createdAt')}`}></div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {response?.models.map(i => (
                  <tr
                    key={i.id}
                    className={'go-link'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(`/view-report/${i.id}`)}
                  >
                    <td style={{ width: '250px' }}>{i.name}</td>
                    <td style={{ maxWidth: 50 }}>{i.createdAt}</td>
                    <td className={i.haveReference ? 'go-link' : ''}>
                      <div className="row row-sm jc-end">
                        {state.user.accessUser[`report-template`][`create-reference`] && (
                          <span>
                            {!i.haveReference && (
                              <div className="col-auto">
                                <button
                                  className="action-btn btn btn-success"
                                  onClick={() => {
                                    showModalNewEvent(true), setReportId(i.id);
                                  }}
                                >
                                  <Download color="#fff" />
                                </button>
                              </div>
                            )}
                          </span>
                        )}

                        {/*
												{i.haveReference && (
													<div className="col-auto">
														<Link
															to={`/view-report/${i.id}`}
															className="action-btn btn btn-primary"
														>
															<View />
														</Link>
													</div>
												)} */}
                        {state.user.accessUser[`report-template`][`update`] && (
                          <div className="col-auto">
                            <button
                              onClick={e => {
                                e.stopPropagation();
                                history.push(`/edit-report/${i.id}`);
                              }}
                              className="action-btn btn btn-warning"
                              title="Изменить"
                            >
                              <Gear />
                            </button>
                          </div>
                        )}
                        {state.user.accessUser[`report-template`][`delete`] && (
                          <div className="col-auto">
                            <button
                              onClick={e => {
                                e.stopPropagation();
                                onDeleteReportAlert(i.id);
                              }}
                              className="action-btn btn btn-danger"
                              title="Удалить"
                            >
                              <TrashCan />
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 ta-center">
            <ReactPaginate
              pageCount={response?.lastPage == 1 ? 0 : response?.lastPage}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              onPageChange={e => setPage(e.selected + 1)}
              activeClassName={'active'}
              containerClassName={'pagination d-inline-flex ta-center'}
              pageLinkClassName={'link'}
              pageClassName={'link'}
              previousLabel={false}
              nextLabel={false}
            />
          </div>
        </div>
      </div>

      <Modal onClose={() => showModalNewEvent(false)} show={viewModalUpload} modalTitle="Загрузка эталона">
        <div className="row">
          <div className="col-12 name-event">
            <Dropzone
              maxSize={1000000000}
              onFileRemove={onFileRemove}
              onFileUpload={handlerUploadFile}
              styles={{ height: 120 }}
              files={files}
            />
          </div>

          {files.length > 0 && (
            <>
              {/* <div className="col-auto">
								<div className="file-name">{files[0]?.name}</div>
								<div className="file-text">{formatBytes(files[0]?.size)}</div>
							</div>
							<div className="col-auto">
								<button
									className="file-remove btn btn-danger pos-abs-y"
									onClick={() => setFiles([])}
								>
									<TrashCan />
								</button>
							</div> */}
              <div className="col-12">
                <button onClick={() => sendUpload(reportId)} className="btn btn-primary mt-2">
                  Загрузить
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ReportsTemplate;
