import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import document from '../../../static/img/icons/document.svg';
import { formatBytes } from '../../../utils/formatBytes';
import { TrashCan } from '../../icons';
import './index.scss';

const Dropzone = ({
  maxSize,
  multiple = true,
  files,
  onFileRemove,
  onFileUpload,
  styles = {},
  acceptFileType = '',
  error = null,
  errorMessage = null,
  showAcceptedListString = null,
  onReject,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    accept: acceptFileType,
    maxSize,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length) {
        onReject?.(fileRejections);
      }
      onFileUpload(acceptedFiles);
    },
  });

  return (
    <div className="dropzone-wrap">
      <div
        {...getRootProps({
          className: 'dropzone d-flex flex-column ai-center jc-center',
        })}
        style={styles}
      >
        <input {...getInputProps()} />

        <h4 className="title">Перетащите файл или нажмите</h4>

        {acceptFileType && showAcceptedListString && (
          <p className="text-sm font-medium mt-1">Разрешенные форматы: {showAcceptedListString}</p>
        )}
      </div>

      {errorMessage && <div className="form-error">{errorMessage}</div>}

      <div className="dropzone-previews">
        {files &&
          files.map(f => (
            <div key={f.path} className={`file pos ${error && '_error-file'}`}>
              <div className="row row-sm ai-center">
                <div className="col-auto">
                  <div className="file-img pos">
                    {f.type.match(/image\//) ? (
                      <img className="image-cover" alt={f.name} src={URL.createObjectURL(f)} />
                    ) : (
                      <img src={document} alt={f.name} className="image-contain doc-icon pos-abs" />
                    )}
                  </div>
                </div>

                <div className="col font-700">
                  <div className="file-name">{f.name}</div>
                  <div className="file-size">{formatBytes(f.size)}</div>
                </div>

                <div className="col-auto d-flex">
                  <button className="file-remove btn btn-danger pos" onClick={() => onFileRemove(f.name)}>
                    <TrashCan size={14} color="#fff" className="pos-abs" />
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
Dropzone.propTypes = {
  maxSize: PropTypes.any.isRequired,
  multiple: PropTypes.any,
  files: PropTypes.any.isRequired,
  onFileRemove: PropTypes.any.isRequired,
  onFileUpload: PropTypes.any.isRequired,
  styles: PropTypes.object,
  acceptFileType: PropTypes.string,
  error: PropTypes.any,
  errorMessage: PropTypes.string,
};

export default Dropzone;
