import React from 'react';
import { CreateTemplate } from '../../containers/Lk/index';

const CreateTemplatePage = () => {
  return (
    <>
      <CreateTemplate />
    </>
  );
};

export default CreateTemplatePage;
