import React from 'react';
import { Reports } from '../../containers/Lk/index';

const ReportsPage = () => {
  return (
    <>
      <Reports />
    </>
  );
};

export default ReportsPage;
