import PropTypes from 'prop-types';
import { memo } from 'react';

const Input = ({
  register = null,
  label = null,
  fieldClasses = '',
  disabled = false,
  onChange = () => {},
  error = null,
  type = 'text',
  ...props
}) => {
  let inputClassName = 'form-input';

  if (error) {
    inputClassName += ' _error';
  }

  return (
    <label className={`form-field ${fieldClasses}`}>
      {label && <span className={`form-label ${disabled ? 'cursor-default' : ''}`}>{label}</span>}

      <input
        ref={register}
        className={inputClassName}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        type={type}
        {...props}
      />

      {error &&
        error.map(e => (
          <div key={e} className="form-error">
            {e}
          </div>
        ))}
    </label>
  );
};
Input.propTypes = {
  label: PropTypes.any,
  register: PropTypes.any,
  fieldClasses: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.any,
  type: PropTypes.string,
};

export default memo(Input);
