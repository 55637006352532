import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
import { apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import './index.scss';
import {CustomEditor} from '../../../components/CustomEditor/CustomEditor';
import ReactQuill from "react-quill";

const Calendar = () => {
  const { response: getCalendar } = useFetch({ url: '/calendar' });
  const [calendarData, setCalendarData] = useState(getCalendar);

  const alertCalendarSave = () => {
    confirmAlert({
      title: '',
      message: 'Каледарь успешно сохранен',
      buttons: [
        {
          label: 'Да',
          onClick: e => e,
        },
      ],
    });
  };

  const saveCalendar = () => {
    apiPost({
      url: '/calendar/update',
      postData: {
        content: calendarData,
      },
    }).then(res => {
      if (res.error) {
        console.error('Сохранение изменений календаря');
        return;
      }
      alertCalendarSave();
    });
  };

  useEffect(() => {
    setCalendarData(getCalendar);
  }, [getCalendar]);

  return (
    <div className="calendar">
      <div className="row mt-2 mb-2">
        <div className="col-auto">
          <a href="https://api.sro-mfo.ru/docs/algoritm.docx?v=1" className="btn btn-primary">
            Алгоритм отправки отчетности
          </a>
        </div>

        {/*<div className="col-auto">*/}
        {/*	<a*/}
        {/*		href="https://lk.sro-mfo.ru/documents/%D0%90%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%20%D0%BE%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B8%20%D0%BE%D1%82%D1%87%D0%B5%D1%82%D0%B0%20%D0%9F%D0%A1%D0%9A%20%D0%B8%20%D0%9F%D0%A1.docx"*/}
        {/*		className="btn btn-primary"*/}
        {/*	>*/}
        {/*		Алгоритм отправки отчета ПСК и ПС*/}
        {/*	</a>*/}
        {/*</div>*/}

        <div className="col-auto">
          <Link to="/calendar-app" className="btn btn-primary">
            Скачать программы для отчетов
          </Link>
        </div>
      </div>

      <div className="card">
        <div className="card-header d-flex jc-between">
          <div>Календарь событий</div>
          <button className="btn btn-primary" onClick={() => saveCalendar()}>
            Сохранить
          </button>
        </div>

        <div className="card-content">

          {/*<CustomEditor   className="mt-3 editor" value={calendarData} onChange={setCalendarData} />*/}
          <ReactQuill className="mt-3 editor" value={calendarData} onChange={setCalendarData} />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
