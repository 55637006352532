export const LOGIN = 'APP/LOGIN';
export const LOGOUT = 'APP/LOGOUT';

export const UPDATE_PROFILE = 'USER/UPDATE_PROFILE';

export const ACCESS_USER = 'USER/ACCESS_USER';

export const UPDATE_FOLDER_CHAT = 'USER/UPDATE_FOLDER_CHAT';

export const DATA_FOLDER_CHAT = 'USER/DATA_FOLDER_CHAT';

export const UPDATE_UNREAD_MESSAGES = 'USER/UPDATE_UNREAD_MESSAGES';

export const UPDATE_UNREAD_MESSAGES_INCOMING = 'USER/UPDATE_UNREAD_MESSAGES_INCOMING';

export const UPDATE_NOT_REPRIMANDED = 'USER/NOT_REPRIMANDED';

export const UPLOADING_STATUS_PROGRESS = 'USER/UPLOADING_STATUS_PROGRESS';

export const REQUIREMENT_GET = 'USER/REQUIREMENT_GET';
export const REQUIREMENT_LOAD = 'USER/REQUIREMENT_LOAD ';



