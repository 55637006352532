import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {apiGet, apiPost} from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { countNotReprimanded } from '../../../../redux/actions/user';
import { downloadBase64File } from '../../../../utils/downloadBase64';
import { goToLink } from '../../../../utils/goToLink';
import { Breadcrumbs, Pagination } from '../../../components';
import { Download } from '../../../icons';
import './index.scss';
import useUrl from '../../../../hooks/useUrl';
import {confirmAlert} from "react-confirm-alert";

const SummaryReports = () => {
  const state = useSelector(state => state);
  const history = useHistory();
  const { id, templateId } = useParams();
  const dispatch = useDispatch();

  const [report, setReport] = useState(null);
  const [summaryReports, setSummaryReports] = useState(null);

  const { page } = useUrl();

  useEffect(() => {
    refreshSummaryReports();
  }, [page])

  const refreshSummaryReports = () => {
    apiGet({
      url: `/summary-report?report_id=${id}&page=${page || 1}&limit=10`,
    }).then(res => {
      setSummaryReports(res);
    });
  }

  const linkRoute = [
    {
      name: 'Отчеты',
      link: '/reports',
    },
    {
      name: 'Отчет организаций',
      link: `/organization-reports/${id}`,
    },
    {
      name: 'Сводные отчеты',
      link: `/summary-reports/${id}`,
    },
  ];

  const downloadFile = id => {
    apiGet({
      url: `/summary-report/view?id=${id}&withFile=1`,
    }).then(res => {
      if (res?.error) {
        console.error('Скачивание элемента шаблона сводного отчета');
        return;
      }
      downloadBase64File(res.data.asBase64, `${res.data.fileName}`);
    });
  };

  useEffect(() => {
    apiGet({ url: `/report/not-reprimanded-count` }).then(res => {
      dispatch(countNotReprimanded(res.data));
    });
  }, []);

  useEffect(() => {
    apiGet({
      url: `/report/view?id=${id}`,
    }).then(res => {
      if (res.error) {
        alert(res.data);
      }

      setReport(res.data);
    });
  }, []);

  const needUploadReportButton = () => {
    sendRequestToCreate(14);
  }

  const limitLogicReportButton = () => {
    sendRequestToCreate(15);
  }

  const checkMarkReportButton = () => {
    sendRequestToCreate(16);
  }

  const sendRequestToCreate = (type) => {
    const postData = new FormData();

    postData.append('type', type);

    apiPost({
      url: `/summary-report/create?report_id=${id}`,
      postData,
    }).then(res => {
      var message = null;
      if (res.error) {
        message = 'Произошла ошибка'
      } else {
        message = 'Отчет скоро будет сформирован'
        refreshSummaryReports();
      }

      confirmAlert({
        message: message,
        buttons: [
          {
            label: 'Ок',
            onClick: () => {},
          },
        ],
      });
    });
  }

  return (
    <>
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="summary card">
        <div className="card-header">Сводные отчеты</div>
        <div className="card-content">
          {state.user.accessUser[`summary-report`].create && (
            <Link to={`/create-summary-report/${id}/${templateId}`} className="btn btn-primary btn-xs mt-2">
              Создать сводный отчет
            </Link>
          )}
          {state.user.accessUser[`summary-report`].create && report && report.needUploadEnable && (
              <button
                  className="btn btn-primary btn-xs ml-2 mt-2"
                  onClick={() => needUploadReportButton()}
              >
                Создать отчёт о необходимости сдачи
              </button>
          )}
          {state.user.accessUser[`summary-report`].create && report && report.limitLogicEnable && (
              <button
                  className="btn btn-primary btn-xs ml-2 mt-2"
                  onClick={() => limitLogicReportButton()}
              >
                Создать отчёт с организациями, превысившими лимит
              </button>
          )}
          {state.user.accessUser[`summary-report`].create && report && report.checkMarkLogicEnable && (
              <button
                  className="btn btn-primary btn-xs ml-2 mt-2"
                  onClick={() => checkMarkReportButton()}
              >
                Создать отчёт по наличию галочки и загруженному отчёту по 832 форме
              </button>
          )}

          <div className="scroll-x">
            <table className="mt-3 low-table">
              <thead>
                <tr>
                  <th>Время Отчета</th>
                  <th>Время создания</th>
                  <th>Тип</th>
                  <th>Шаблон сводного отчета</th>
                  <th>Статус</th>
                  <th className="edit"></th>
                </tr>
              </thead>
              <tbody>
                {summaryReports?.data.models.map(i => (
                  <tr
                    className={'go-link'}
                    style={{ cursor: 'pointer' }}
                    key={i.id}
                    onClick={e => goToLink(e, () => history.push(`/edit-summary-report/${id}/${i.id}/${templateId}`))}
                  >
                    <td>{i?.reportTime}</td>
                    <td>{i?.createdAt}</td>
                    <td>{i?.typeText}</td>
                    <td>{i?.summaryReportTemplateName}</td>
                    <td>{i?.completedAt ? 'Готово к выгрузке' : 'Обработка на сервере'}</td>
                    <td>
                      {i?.completedAt && (
                        <button
                          className="action-btn btn btn-light"
                          onClick={() => downloadFile(i.id)}
                          title="Скачать отчет"
                        >
                          <Download />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-2 ta-center">
            <Pagination lastPage={summaryReports?.data.lastPage} page={page} />
            {/* <ReactPaginate
							pageCount={
								getSummaryReports?.lastPage == 1
									? 0
									: getSummaryReports?.lastPage
							}
							pageRangeDisplayed={5}
							marginPagesDisplayed={3}
							onPageChange={e => setPage(e.selected + 1)}
							activeClassName={"active"}
							containerClassName={"pagination d-inline-flex ta-center"}
							pageLinkClassName={"link"}
							pageClassName={"link"}
							previousLabel={false}
							nextLabel={false}
						/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryReports;
