import React from 'react';
import { Inbox } from '../../containers/Lk/index';

const InboxPage = () => {
  return (
    <>
      <Inbox />
    </>
  );
};

export default InboxPage;
