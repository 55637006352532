import PropTypes from 'prop-types';
import * as React from 'react';

const UnreadMessage = ({ size = 20, color = '#fff', ...props }) => {
  return (
    <svg width={size} height={size} {...props} fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M202.472 270.73A19.995 19.995 0 00216 276h80c5.01 0 9.838-1.88 13.529-5.269l98-90c8.136-7.471 8.674-20.123 1.202-28.259-7.472-8.135-20.123-8.672-28.259-1.202L288.21 236h-64.42l-92.261-84.731c-8.137-7.472-20.787-6.933-28.259 1.202-7.472 8.136-6.934 20.788 1.202 28.259l98 90z" />
      <path d="M492 56H20C8.954 56 0 64.954 0 76v360c0 11.046 8.954 20 20 20h472c11.046 0 20-8.954 20-20V76c0-11.046-8.954-20-20-20zm-20 360H40V96h432v320z" />
    </svg>
  );
};

UnreadMessage.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default UnreadMessage;
