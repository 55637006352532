import { Api } from '../api';

export const checkUserAuth = async () => {
  const authToken = await localStorage.getItem('authToken');

  if (authToken) {
    try {
      const res = await Api.get('/user/profile', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const { data } = res.data;

      return {
        userData: data,
        authToken,
      };
    } catch (err) {
      return false;
    }
  }

  return false;
};
