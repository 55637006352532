import PropTypes from 'prop-types';
import * as React from 'react';

const Send = ({ size = 22, color = '#fff', ...props }) => {
  return (
    <svg viewBox="0 0 511.763 511.763" width={size} height={size} fill={color} {...props}>
      <path d="M511.716 9.802c-.107-.853-.213-1.707-.533-2.56-.107-.32-.213-.747-.32-1.067-.533-1.173-1.28-2.24-2.133-3.2-.96-.853-2.027-1.6-3.2-2.133-.32-.107-.747-.32-1.067-.32-.853-.213-1.707-.427-2.56-.427h-1.173c-.96 0-2.027.213-2.987.533-.213.107-.427.107-.64.213h-.107L6.436 213.962c-5.44 2.347-7.893 8.64-5.547 14.08a10.213 10.213 0 004.8 5.12l178.347 94.4 94.507 178.347c1.813 3.52 5.44 5.653 9.387 5.76h.427c4.053-.107 7.68-2.667 9.387-6.4L510.969 14.815v-.107c.107-.213.107-.427.213-.64.32-.96.533-1.92.533-2.987.001-.426.107-.853.001-1.279zM35.342 224.522l418.88-182.08-264.107 264-154.773-81.92zm251.84 251.84l-81.92-154.773 264-264.107-182.08 418.88z" />
    </svg>
  );
};

Send.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Send;
