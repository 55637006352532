import {useHistory, useParams} from 'react-router-dom';
import {useFetch} from '../../../../hooks/useFetch';
import {createUrlQuery} from '../../../../utils/createApiUrl';
import useUrl from '../../../../hooks/useUrl';
import {apiGet, apiPost} from '../../../../api';
import {message} from 'antd';
import {confirmAlert} from 'react-confirm-alert';
import {downloadReport} from '../../../../utils/downloadReport';
import {useDispatch} from 'react-redux';
import {getFolderIdUrl} from '../../../../utils/getFolderId';
import {modalApi} from '../../../../redux/actions/modal';
import {setProtocol} from '../../../../redux/actions/dk';
import {useState} from 'react';

export const useProtocolViewPage = () => {
    const {id} = useParams();
    const params = useUrl();
    const history = useHistory();
    const dispatch = useDispatch();
    const {response, loading, fetchData} = useFetch({
        url: `/dk-protocol/view${createUrlQuery({id})}`
    });
    const [dndVisible, setDndVisible] = useState(false);
    const [organizationDndIds, setOrganizationDndIds] = useState([]);

    const linkRoute = [
        {
            name: 'Протоколы',
            link: `/protocol/${params.archive}${getFolderIdUrl()}`,
        },
        {
            name: 'Просмотр протокола',
        },
    ];

    const deleteProtocolOrg = async (extractId) => {
        const {error, data} = await apiPost(
            {url: `/dk-protocol/delete-organization${createUrlQuery({id, extractId})}`}
        );

        if (error) {
            message.error(data);
        }
        if (!error) {
            await fetchData();
            message.success('Успешно удалено!');
        }
    };

    const handleConfirmDeleteOrganization = (id) => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы точно хотите удалить организацию?',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => deleteProtocolOrg(id),
                },
                {
                    label: 'Нет',
                },
            ],
        });
    };

    const downloadFile = () => {
        apiGet({url: `/dk-protocol/download?id=${id}`})
            .then(res => {
                if (res.error) {
                    console.error('Скачивание документа (шаблон)');
                    return;
                }

                downloadReport(res.data, true);
            });
    };
    const sendAllExtracts = async () => {
        const {error} = await apiPost(
            {url: `/dk-protocol/send-all-extracts${createUrlQuery({id})}`}
        );
        if (!error) {
            message.success('Успешно отправлено!');
            await fetchData();
        }
    };

    const sendExtractsById = async (id) => {
        const {error} = await apiPost(
            {url: `/dk-extract/send${createUrlQuery({id})}`}
        );
        if (!error) {
            message.success('Успешно отправлено!');
            await fetchData();
        }
    };
    const handleProtocolUpdate = () => {
        dispatch(modalApi('update-protocol'));
        dispatch(setProtocol(response));
    };


    const toggleDnd = async () => {
        if (!organizationDndIds.length) {
            setDndVisible(p => !p);
            return;
        }

        const {error} = await apiPost({
            url: `/dk-protocol/change-sequences?id=${id}`,
            postData: {
                sequences: JSON.stringify(organizationDndIds)
            },
            showProgress: false
        });

        if (!error) {
            setOrganizationDndIds([]);
            await fetchData();
            setDndVisible(false);
        }
    };

    return {
        response,
        toggleDnd,
        handleProtocolUpdate,
        linkRoute,
        dispatch,
        id,
        fetchData,
        sendExtractsById,
        sendAllExtracts,
        params,
        downloadFile,
        handleConfirmDeleteOrganization,
        dndVisible,
        setOrganizationDndIds,
        history,
        loading
    };
};
