import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api/index';
import { useFetch } from '../../../../hooks/useFetch';
import { goToLink } from '../../../../utils/goToLink';
import { Input, Modal } from '../../../components/index';
import { Gear } from '../../../icons';
import './index.scss';

const Mfo = () => {
  const history = useHistory();
  const [viewModal, setViewModal] = useState(false);
  const [page, setPage] = useState(1);
  const [sending, setSending] = useState(false);
  const [errObj, setErrObj] = useState({});

  const [editUserId, setEditUserId] = useState('');
  const [viewMfoName, setViewMfoName] = useState('');
  const [viewMfoInn, setViewMfoInn] = useState('');
  const [viewMfoOgrn, setViewMfoOgrn] = useState('');

  const [compId, setCompId] = useState(null);

  const [filterName, setFilterName] = useState('');
  const [filterInn, setFilterInn] = useState('');
  const [filterOgrn, setFilterOgrn] = useState('');

  const fName = item => {
    setFilterName(item);
    setPage(1);
  };

  const fInn = item => {
    setFilterInn(item);
    setPage(1);
  };

  const fOgrn = item => {
    setFilterOgrn(item);
    setPage(1);
  };

  let url = `/organization?page=${page}&limit=10&sort=-instanceUploadDate`;
  if (filterName.length) url += `&name=${filterName}`;
  if (filterInn.length) url += `&inn=${filterInn}`;
  if (filterOgrn.length) url += `&ogrn=${filterOgrn}`;

  const { response, fetchData } = useFetch({ url });

  const dataEdit = () => {
    setViewModal(true);
  };

  const editComp = compId => {
    if (!sending) {
      setSending(true);

      const postData = {
        user_id: editUserId,
        name: viewMfoName,
        inn: viewMfoInn,
        ogrn: viewMfoOgrn,
      };

      apiPost({ url: `/organization/update?id=${compId}`, postData })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          } else if (res.error) {
            console.error('Обновление организации');
            return;
          }

          setErrObj({});
          fetchData();
          setViewModal(false);
        })
        .finally(() => setSending(false));
    }
  };

  const viewComp = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/organization/view?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Отображение организации');
            return;
          }

          setCompId(res.data.id);
          setEditUserId(res.data.user_id);
          setViewMfoName(res.data.name);
          setViewMfoInn(res.data.inn);
          setViewMfoOgrn(res.data.ogrn);
          dataEdit();
        })
        .finally(() => setSending(false));
    }
  };

  const showModal = id => {
    viewComp(id);
  };
  const closeModal = () => {
    setViewModal(false);
  };
  return (
    <>
      <div className="mfo">
        <div className="card">
          <div className="card-header">Организации</div>
          <div className="card-content">
            <div className="row jc-between">
              <div className="col-6 col-md-3 "></div>
            </div>
            <div className="scroll-x">
              <table className="mt-3 big-table">
                <thead>
                  <tr>
                    <th>
                      Имя
                      <Input value={filterName} onChange={fName} />
                    </th>
                    <th>
                      ИНН <Input onChange={fInn} />
                    </th>
                    <th>
                      ОГРН <Input onChange={fOgrn} />
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {response?.models.map(item => (
                    <tr
                      className={'go-link'}
                      style={{ cursor: 'pointer' }}
                      key={item.id}
                      onClick={e => goToLink(e, () => history.push(`/mfo-reports/${item.id}`))}
                    >
                      <td>{item.name || ''}</td>
                      <td>{item.inn || ''}</td>
                      <td>{item.ogrn || ''}</td>

                      <td>
                        <div className="row row-sm">
                          {/* <div className="col-auto">
														<Link
															to={}
															className="action-btn btn btn-primary"
														>
															<View />
														</Link>
													</div> */}

                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-warning"
                              onClick={() => showModal(item.id)}
                              title="Изменить"
                            >
                              <Gear />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-2 ta-center">
              <ReactPaginate
                pageCount={response?.lastPage == 1 ? 0 : response?.lastPage}
                pageRangeDisplayed={5}
                marginPagesDisplayed={3}
                onPageChange={e => setPage(e.selected + 1)}
                activeClassName={'active'}
                containerClassName={'pagination d-inline-flex ta-center'}
                pageLinkClassName={'link'}
                pageClassName={'link'}
                previousLabel={false}
                nextLabel={false}
              />
            </div>
          </div>
        </div>

        <Modal
          show={viewModal}
          onClose={() => closeModal()}
          modalTitle="Изменение организации"
          footer={
            <button className="btn btn-primary" onClick={() => editComp(compId)}>
              Сохранить
            </button>
          }
        >
          <div className="row">
            <div className="col-12 name-event">
              <Input label="Наименование" defaultValue={viewMfoName} onChange={setViewMfoName} error={errObj.name} />
            </div>
            <div className="col-12 col-md-4">
              <Input label="ИНН" type="tel" defaultValue={viewMfoInn} onChange={setViewMfoInn} />
            </div>
            <div className="col-12 col-md-4">
              <Input label="ОГРН" type="tel" defaultValue={viewMfoOgrn} onChange={setViewMfoOgrn} />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Mfo;
