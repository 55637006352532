import {Input, Modal} from '../../../components';
import React, {useEffect, useReducer, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {resetCurrentAttribute} from '../../../../redux/actions/organizationAttrubute';
import {organizationApi} from '../../../../api/organization/organization';
import {TrashCan} from '../../../icons';
import Index from '../../../components/Spinner';

const ATTRIBUTES_LABELS = {
    oktmo: 'ОКТМО',
    address: 'Адрес места нахождения микрокредитной компании',
    name_full: 'Полное наименование организации',
};

const errorApiParse = (data, setError) => {
    if (data) {
        for (const [k, v] of Object.entries(data)) {
            setError(p => ({...p, [k]: v}));
        }
    }
};


const getErrors = (values, attribute) => {
    const errors = {};

    for (const key in values) {
        if (!values[key]) {
            const errorName = key === 'date' ? 'Дата' : ATTRIBUTES_LABELS?.[attribute];

            errors[key] = `${errorName} является обязательным полем`;
        }
    }

    return {errors, hasErrors: !!Object.keys(errors).length};
};

const defaultFormValues = {
    value: '', date: ''
};

export const OrganizationAttributeModal = () => {
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [formErrors, setFormErrors] = useState(defaultFormValues);

    const [orgAttributesList, setOrgAttributesList] = useState([]);

    const [isCreateLoading, toggleCreateLoading] = useReducer(s => !s, false);
    const [isListLoading, toggleIsListLoading] = useReducer(s => !s, false);

    const [isDeleteLoading, setIsDeleteLoading] = useState(null);


    const {modal, orgAttributes} = useSelector(s => s);
    const dispatch = useDispatch();

    const isVisible = modal.name === 'org-attribute';

    const onClose = () => {
        dispatch(resetCurrentAttribute());
        setOrgAttributesList([]);
        setFormErrors(defaultFormValues);
        setFormValues(defaultFormValues);
    };

    const onCreate = async () => {
        toggleCreateLoading();
        const {hasErrors, errors} = getErrors(formValues, orgAttributes.attribute?.attribute);

        if (hasErrors) {
            setFormErrors(errors);
            toggleCreateLoading();

            return;
        }

        const res = await organizationApi.create({
            organizationId: orgAttributes.attribute?.compId,
            attribute: orgAttributes.attribute?.attribute
        }, formValues);


        if (res.status === 406) {
            errorApiParse(res.data, setFormErrors);
            toggleCreateLoading();

            return;
        }

        if (!res.error) {
            setOrgAttributesList(prev => [...prev, res.data]);

            setFormErrors(defaultFormValues);
            setFormValues(defaultFormValues);
            toggleCreateLoading();
        }
    };

    const onFromChange = (name, value) => {
        setFormErrors(prev => ({...prev, [name]: null}));
        setFormValues(prev => ({...prev, [name]: value}));
    };


    const onDelete = async (id) => {
        setIsDeleteLoading(id);
        const res = await organizationApi.delete({id});

        if (!res.error) {
            const newList = [...orgAttributesList].filter((i) => i.id !== id);

            setOrgAttributesList(newList);
            setIsDeleteLoading(null);
        }
    };

    const getHistory = async () => {
        if (!orgAttributes.attribute || !isVisible) {
            return;
        }

        toggleIsListLoading();

        const res = await organizationApi.history({
            organization_id: orgAttributes.attribute?.compId,
            attribute: orgAttributes.attribute?.attribute
        });

        if (!res.error) {
            setOrgAttributesList(res.data.models);
            toggleIsListLoading();
        }
    };

    useEffect(() => {
        getHistory();
    }, [isVisible]);

    return (
        <Modal
            show={isVisible}
            onClose={onClose}
            modalTitle={`История - ${ATTRIBUTES_LABELS[orgAttributes.attribute?.attribute]}`}
            footer={
                <button className="btn btn-success max-m595:h-9 max-h-9" onClick={onCreate}>
                    Сохранить
                    {isCreateLoading && <Index size={36}/>}
                </button>
            }
        >
            {isListLoading ? (
                <div className='flex-center'>
                    <Index size={60}/>
                </div>
            ) : (
                <div>
                    {orgAttributesList.length ? (
                        <table className="my-2">
                            <thead>
                            <tr>
                                <th>Значение</th>
                                <th>Дата</th>
                                <th className="settings"/>
                            </tr>
                            </thead>

                            <tbody>
                            {orgAttributesList.map((i) => (
                                <tr
                                    key={i.id}
                                >
                                    <td>{i?.value}</td>
                                    <td>{i?.date}</td>
                                    <td align='right'>
                                        <button
                                            className="action-btn btn btn-danger max-h-9"
                                            onClick={() => onDelete(i.id)}
                                        >
                                            {isDeleteLoading === i.id ? <Index size={36}/> : <TrashCan/>}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <span className='block flex-center'>Нет данных о истории</span>
                    )}
                </div>
            )}

            <Input
                {...formErrors.value && {
                    error: [formErrors.value]
                }}
                fieldClasses='mb-1.5'
                placeholder='Введите значение'
                label={`${ATTRIBUTES_LABELS[orgAttributes.attribute?.attribute]}`}
                onChange={(value) => onFromChange('value', value)}
                value={formValues.value}
            />

            <Input
                fieldClasses='mb-0'
                {...formErrors.date && {
                    error: [formErrors.date]
                }}
                label='Дата' type='date'
                onChange={(value) => onFromChange('date', value)}
                value={formValues.date}
            />
        </Modal>
    );
};
