import React from 'react';
import { TemplateSummaryReportTriggersElements } from '../../containers/Lk/index';

const TemplateSummaryReportTriggersElementsPage = () => {
  return (
    <>
      <TemplateSummaryReportTriggersElements />
    </>
  );
};

export default TemplateSummaryReportTriggersElementsPage;
