import { createUrlQuery } from '../../utils/createApiUrl';
import { apiGet, apiPost } from '../index';

export const organizationApi = {
  history: params => apiGet({ url: `/organization-attribute-history${createUrlQuery(params)}` }),

  create: (params, postData) =>
    apiPost({ postData, url: `/organization-attribute-history/create${createUrlQuery(params)}` }),

  registryUpload: (params, postData) =>
    apiPost({ postData, url: `/organization-registry/create${createUrlQuery(params)}` }),

  delete: params => apiPost({ url: `/organization-attribute-history/delete${createUrlQuery(params)}` }),

  registryList: params => apiGet({ url: `/organization-registry${createUrlQuery(params)}` }),

  registryRowList: params => apiGet({ url: `/organization-registry-row${createUrlQuery(params)}` }),

  registryDownload: params => apiGet({ url: `/organization-registry/download${createUrlQuery(params)}` }),
};
