import React from 'react';
import CalendarApps from '../../containers/Lk/CalendarApps';

const CalendarAppPage = () => {
  return (
    <>
      <CalendarApps />
    </>
  );
};

export default CalendarAppPage;
