
export const SUCCESS_LIST = [
    {
        id: '',
        name: 'Выберите Статус'
    },
    {
        id: 1,
        name: 'Не исполнено'
    },
    {
        id: 2,
        name: 'Исполнено'
    },
];


export const API_LIMIT_LIST = [
    {
        id: 5,
        name: '5'
    },
    {
        id: 10,
        name: '10'
    },
    {
        id: 15,
        name: '15'
    },
    {
        id: 25,
        name: '25'
    },
    {
        id: 50,
        name: '50'
    },
    {
        id: 100,
        name: '100'
    },
];
