import React from 'react';
import { ReportTriggers } from '../../containers/Lk';

const ReportTriggersPage = () => {
  return (
    <>
      <ReportTriggers />
    </>
  );
};

export default ReportTriggersPage;
