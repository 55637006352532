import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { useHistory, useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { goToLink } from '../../../../utils/goToLink';
import { Breadcrumbs, Input, Modal } from '../../../components';
import { Gear, TrashCan } from '../../../icons';
import './index.scss';

const TemplateSummaryReport = () => {
  const { id } = useParams();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [errObj, setErrObj] = useState({});
  const [sending, setSending] = useState(false);
  const [createNameTemplateSummaryReport, setCreateNameTemplateSummaryReport] = useState('');
  const [editNameTemplateSummaryReport, setEditNameTemplateSummaryReport] = useState('');
  const [editIdTemplateSummaryReport, setEditIdTemplateSummaryReport] = useState();

  const [modalCreate, setModalCreate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const { response, fetchData } = useFetch({
    url: `/data-report-template?page=${page}&limit=10&template_id=${id}`,
  });

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Просмотр шаблона',
      link: `/view-report/${id}`,
    },
    {
      name: 'Шаблоны сводных отчетов по данным',
      link: `/template-summary-report/${id}`,
    },
  ];

  const createTemplateSummaryReport = () => {
    if (!sending) {
      apiPost({
        url: `/data-report-template/create?template_id=${id}`,
        postData: {
          name: createNameTemplateSummaryReport,
        },
      })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          }
          if (res.error) {
            console.error('Создание шаблона сводного отчета по данным');
            return;
          }
          setCreateNameTemplateSummaryReport('');
          fetchData();
          setModalCreate(false);
        })
        .finally(() => setSending(false));
    }
  };

  const editTemplateSummaryReport = () => {
    if (!sending) {
      apiPost({
        url: `/data-report-template/update?id=${editIdTemplateSummaryReport}`,
        postData: {
          name: editNameTemplateSummaryReport,
        },
      })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          }
          if (res.error) {
            console.error('Изменение шаблона сводного отчета по данным');
            return;
          }
          setEditNameTemplateSummaryReport('');
          fetchData();
          setModalEdit(false);
        })
        .finally(() => setSending(false));
    }
  };

  const deleteTemplateSummaryReport = id => {
    if (!sending) {
      apiGet({
        url: `/data-report-template/delete?id=${id}`,
      })
        .then(res => {
          if (res.error) {
            console.error('Удаление шаблона сводного отчета по данным');
            return;
          }
          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  const alerDeleteTemplateSummaryReport = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить шаблон сводного отчета по данным?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteTemplateSummaryReport(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const openEditModal = item => {
    setEditIdTemplateSummaryReport(item.id);
    setEditNameTemplateSummaryReport(item.name);
    setModalEdit(true);
  };
  return (
    <>
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="card">
        <div className="card-header ">
          <div className="row jc-between">
            <div className="col-auto mb-2">
              <span>Список шаблонов сводных отчетов по данным</span>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" onClick={() => setModalCreate(true)}>
                Создание шаблона сводного отчета по данным
              </button>
            </div>
          </div>
        </div>
        <div className="card-content">
          <div className="scroll-x">
            <table className="mt-4 low-table">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Дата создания</th>
                  <th className="settings"></th>
                </tr>
              </thead>
              <tbody>
                {response?.models.map(item => (
                  <tr
                    style={{ cursor: 'pointer' }}
                    onClick={e => goToLink(e, () => history.push(`/template-summary-report-elements/${id}/${item.id}`))}
                    className={`go-link`}
                    key={item.id}
                  >
                    <td>{item.name}</td>
                    <td>{item.createdAt}</td>
                    <td>
                      <div className="row row-sm jc-end">
                        <div className="col-auto">
                          <button
                            className="action-btn btn btn-warning"
                            onClick={() => openEditModal(item)}
                            title="Изменить"
                          >
                            <Gear />
                          </button>
                        </div>
                        <div className="col-auto">
                          <button
                            className="action-btn btn btn-danger"
                            onClick={() => alerDeleteTemplateSummaryReport(item.id)}
                            title="Удаление"
                          >
                            <TrashCan />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-2 ta-center">
            <ReactPaginate
              pageCount={response?.lastPage == 1 ? 0 : response?.lastPage}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              onPageChange={e => setPage(e.selected + 1)}
              activeClassName={'active'}
              containerClassName={'pagination d-inline-flex ta-center'}
              pageLinkClassName={'link'}
              pageClassName={'link'}
              previousLabel={false}
              nextLabel={false}
            />
          </div>
        </div>
      </div>
      <Modal
        show={modalCreate}
        onClose={() => setModalCreate(false)}
        modalTitle="Создание шаблона сводного отчета по данным"
        footer={
          <button className="btn btn-primary" onClick={() => createTemplateSummaryReport()}>
            Создать
          </button>
        }
      >
        <div className="row">
          <div className="col-12">
            <Input
              label="Название шаблона сводного отчета по данным"
              value={createNameTemplateSummaryReport}
              onChange={setCreateNameTemplateSummaryReport}
              error={errObj.name}
            />
          </div>
        </div>
      </Modal>

      <Modal
        show={modalEdit}
        onClose={() => setModalEdit(false)}
        modalTitle="Изменение шаблона сводного отчета по данным"
        footer={
          <button className="btn btn-primary" onClick={() => editTemplateSummaryReport()}>
            Изменить
          </button>
        }
      >
        <div className="row">
          <div className="col-12">
            <Input
              label="Название шаблона сводного отчета по данным"
              value={editNameTemplateSummaryReport}
              onChange={setEditNameTemplateSummaryReport}
              error={errObj.name}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TemplateSummaryReport;
