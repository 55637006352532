import React from 'react';
import { Login } from '../../containers/Lk/index';

const LoginPage = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default LoginPage;
