const initialState = {
  attribute: null,
};

export const organizationAttributeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_ATTRIBUTE':
      return { ...state, attribute: payload };

    default:
      return state;
  }
};
