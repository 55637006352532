import React, {useRef, useState} from 'react';
import {Input, Modal, Select, Spinner} from '../../components';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toggleCreateInvitationModal} from '../../../redux/actions/dk';
import {apiGet, apiPost} from '../../../api';
import {createUrlQuery} from '../../../utils/createApiUrl';
import {useFetch} from '../../../hooks/useFetch';
import dayjs from 'dayjs';
import './InvitationListPage/InvitationListPage.scss';
import {message} from 'antd';
import {Select as SelectAntd} from 'antd';
import {Cancel} from '../../icons';
import '../../../static/styles/inc/ui/_select.scss';
import {useQuery} from '@tanstack/react-query';

const dateParse = (v) => {
    return dayjs(v).format('DD.MM.YYYY');
};
const DEFAULT_VALUES = {
    name: '',
    dkReasonId: '',
    organizationId: [],
    date: '',
    timeValue: '',
};

// eslint-disable-next-line react/prop-types
export const InvitationCreateModal = ({fetchData}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {isVisibleCreateInvitationModal} = useSelector(state => state.dk);
    const {
        response,
        loading
    } = useFetch({
        url: isVisibleCreateInvitationModal && `/dk-reason${createUrlQuery({
            limit: 1500,
            dk_folder_id: id
        })}`
    });
    const organizationQuery = useFetch({url: isVisibleCreateInvitationModal && `/organization${createUrlQuery({limit: 1500})}`});
    const selectReasonOptions = !loading ? response?.models?.map(({id, name}) => ({id, name})) : [];
    const selectOrganizationOptions = !organizationQuery.loading ?
        organizationQuery.response?.models?.map(({id, name}) => ({value: id, label: name})) : [];
    const [values, setValues] = useState(DEFAULT_VALUES);
    const [errors, setErrors] = useState(DEFAULT_VALUES);
    const [varsValues, setVarsValues] = useState({});

    const {data: varsList} = useQuery(
        ['vars-list',values.dkReasonId],
        () => apiGet({url: `/dk-reason/vars-names?id=${values.dkReasonId}`}),
        {enabled:!!values.dkReasonId}
    );


    const selectRef = useRef(null);

    const hideModal = () => {
        dispatch(toggleCreateInvitationModal(false));
        setValues(DEFAULT_VALUES);
        setErrors(DEFAULT_VALUES);
        setVarsValues({});
    };

    const createInvitation = async () => {
        const postData = {
            time: `${dateParse(values.date)} ${values.timeValue}`,
            organizationIds: values.organizationId.join(','),
            dkReasonId:values.dkReasonId,
        };

        if (Object.values(varsValues).length) {
            postData.vars = JSON.stringify(varsValues);
        }

        const {data, error, status} = await apiPost({
            url: `/dk-invitation/create-multiple${createUrlQuery({dkFolderId: id})}`,
            postData
        });
        if (status === 406) {
            setErrors({...data});
        }
        if (!error) {
            hideModal();
            fetchData();
            message.success('Успешно создано!');
        }
    };

    const handleValuesChange = (value, name) => {
        setValues(p => ({...p, [name]: value}));
    };

    const handleVarsValuesChange = (value, name) => {
        setVarsValues(p => ({...p, [name]: value}));
    };

    const onClearOrganization = () => {
        setValues(p => ({...p, 'organizationId': []}));
    };

    return (
        <Modal
            footer={
                <button className="btn btn-bg-acent" onClick={createInvitation}>
                    Создать
                </button>
            }
            modalTitle='Создать приглашение'
            show={isVisibleCreateInvitationModal}
            onClose={hideModal}
        >
            <div className="d-flex flex-column">
                <div className="d-flex w-full ai-center form-wrap gap-2">
                    <div className='w-full mb-2'>
                        <span className='form-label'>Организация</span>
                        <div style={{position: 'relative'}}>
                            {!!values.organizationId.length && (
                                <div className='ant-select-custom-clear-icon' onClick={onClearOrganization}>
                                    <Cancel size={14} color='#000'/>
                                </div>
                            )}

                            <SelectAntd
                                ref={selectRef}
                                mode='multiple'
                                className='w-full mb-1'
                                optionFilterProp='label'
                                filterOption={(input, option) =>
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    (option?.label).toLowerCase().includes(input.toLowerCase())
                                }
                                tagRender={({label, onClose}) => (
                                    <div className='ant-custom-tag'>
                                        <span className='ant-custom-tag-label'>{label}</span>

                                        <div className='d-flex ant-custom-tag-cancel jc-center ai-center'
                                             onClick={onClose}>
                                            <Cancel size={10} color='#000'/>
                                        </div>
                                    </div>
                                )}
                                popupClassName='ant-custom-dropdown-select'
                                placeholder="Выберите организацию"
                                options={selectOrganizationOptions}
                                value={values.organizationId}
                                onChange={v => {
                                    setValues(p => ({...p, 'organizationId': v}));
                                    selectRef.current && selectRef.current.blur();
                                }}
                                notFoundContent={'Нет вариантов..'}
                            />
                        </div>

                        <span className="form-error ">{errors.organizationId}</span>
                    </div>
                </div>

                <Select
                    fieldClasses='organization-select'
                    label='Причины'
                    value={values.dkReasonId}
                    onChange={v => handleValuesChange(v, 'dkReasonId')}
                    values={[{id: '', name: 'Выберите причину'}, ...selectReasonOptions]}
                    error={errors.dkReasonId && [errors.dkReasonId]}
                />

                <div className="d-flex w-full form-wrap gap-2">
                    <Input
                        style={{minHeight: 42.7}}
                        fieldClasses='w-full'
                        type="date"
                        label='Дата приглашения'
                        error={errors.time && [errors.time]}
                        value={values.date}
                        onChange={v => handleValuesChange(v, 'date')}
                    />
                    <Input
                        fieldClasses='w-full'
                        type="time"
                        label='Время приглашения'
                        value={values.timeValue}
                        onChange={v => handleValuesChange(v, 'timeValue')}
                    />
                </div>

                {!!varsList?.data?.length && (
                    <div>
                        <div className="mb-3">Переменные</div>
                        {varsList?.data.map(varName => (
                            <div key={varName} className='flex items-center space-x-2'>
                                <Input
                                    label='Название'
                                    style={{minHeight: 42.7}}
                                    fieldClasses='w-full'
                                    value={varName}
                                    disabled
                                />
                                <Input
                                    label='Значение'
                                    fieldClasses='w-full'
                                    value={varsValues[varName]}
                                    onChange={v => handleVarsValuesChange(v, varName)}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Modal>
    );
};

