import PropType from 'prop-types';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { Breadcrumbs, Input, Textarea, TreeItem, TriggerSearch } from '../../../components';
import './index.scss';

const EditTrigger = () => {
  const { id, idTrigger } = useParams();

  const [errObj, setErrObj] = useState({});
  const [sending, setSending] = useState(false);
  const [periodList, setPeriodList] = useState(null);
  const [triggerPeriodId, setTriggerPeiodId] = useState('');
  const [allowedAbsenceCheckBox, setAllowedAbsenceCheckBox] = useState(false);

  const history = useHistory();

  const { register, handleSubmit, setValue } = useForm({
    shouldUnregister: false,
  });

  // получение периода id
  const { response: periodIdResponse } = useFetch({
    url: `/trigger/view?id=${idTrigger}`,
  });

  useEffect(() => {
    setTriggerPeiodId(periodIdResponse?.period?.id);
  }, [periodIdResponse]);

  const { response: templateData, loading: templatesLoading } = useFetch({
    url: '/report',
  });

  const { response: treeReport, loading: treeLoading } = useFetch({
    url: `/report-template/reference?id=${id}`,
  });

  const editTriggerSuccessAlert = () => {
    confirmAlert({
      message: 'Триггер успешно изменен',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const getReportPeriod = () => {
    apiGet({ url: '/report-period?limit=1000' }).then(res => {
      if (res.error) {
        console.error('Получения отчетных периодов');
        return;
      }

      setPeriodList([...res.data.models]);
    });
  };

  //Изменение триггера
  const onEditTrigger = data => {
    const { triggerName, condition, periodId, description, nativeTrigger } = data;

    if (!sending) {
      setSending(true);

      apiPost({
        url: `/trigger/update?id=${idTrigger}`,
        postData: {
          period_id: +periodId || null,
          name: triggerName,
          condition,
          description,
          allowed_absence: allowedAbsenceCheckBox,
          native: nativeTrigger,
        },
      })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          } else if (res.error) {
            console.error('Изменение триггера');
            return;
          }

          setErrObj({});
          history.goBack();
          editTriggerSuccessAlert();
        })
        .finally(() => setSending(false));
    }
  };

  const goToTreeNode = id => {
    const node = document.querySelector(`.accordion__panel[data-id="${id}"]`);

    openTreeNode(node);

    node.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const openTreeNode = node => {
    node.removeAttribute('hidden');
    node.setAttribute('aria-hidden', false);

    const parentNode = node.parentNode.parentNode.parentNode;

    if (parentNode.classList.contains('accordion__panel')) {
      parentNode.removeAttribute('hidden');
      parentNode.setAttribute('aria-hidden', false);

      openTreeNode(parentNode);
    }
  };

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Просмотр шаблона',
      link: `/view-report/${id}`,
    },
    {
      name: 'Изменение триггера',
      link: `/edit-trigger/${id}/${idTrigger}`,
    },
  ];

  useEffect(() => {
    getReportPeriod();
  }, []);

  useEffect(() => {
    if (periodIdResponse) {
      setAllowedAbsenceCheckBox(periodIdResponse.allowedAbsence);
      setValue('triggerName', periodIdResponse.name);
      setValue('condition', periodIdResponse.condition);
      setValue('description', periodIdResponse.description || '');
      setValue('periodId', periodIdResponse.period?.id || '');
      setValue('nativeTrigger', periodIdResponse.native || '');
    }
  }, [periodIdResponse]);

  if (treeLoading && templatesLoading) {
    return <div className="d-flex flex-fill jc-center">{/* <Spinner /> */}</div>;
  }

  return (
    <div className="edit-trigger">
      <Breadcrumbs linkRoute={linkRoute} />

      <form onSubmit={handleSubmit(onEditTrigger)} className="card">
        <div className="card-header">Изменение триггера</div>

        <div className="card-content">
          <Input
            register={register}
            name="triggerName"
            label="Название триггера"
            error={errObj.name}
            autoComplete="off"
          />

          <div className="mb-3" style={{ display: 'inline-block' }}>
            <label className="d-flex">
              <input
                name="allowed_absence"
                type="checkbox"
                className="checkbox mr-2"
                checked={allowedAbsenceCheckBox}
                onChange={() => setAllowedAbsenceCheckBox(!allowedAbsenceCheckBox)}
              />
              <span>Разрешено отсутствие элемента</span>
            </label>
          </div>

          <Textarea
            type="number"
            label="Код триггера"
            name="condition"
            register={register}
            rows="10"
            error={errObj.condition}
          />

          <Input label="Нативный триггер" name="nativeTrigger" register={register} error={errObj.native} />

          <Textarea
            style={{ height: '90px' }}
            label="Описание триггера"
            name="description"
            register={register}
            rows="10"
            error={errObj.description}
          />

          <label className="form-field">
            <span className="form-label">Период</span>

            <select
              ref={register}
              name="periodId"
              value={triggerPeriodId}
              onChange={e => setTriggerPeiodId(e.target.value)}
              className={`form-input _select ${errObj.period_id ? '_error' : ''}`}
            >
              <option value="0">-</option>

              {periodList?.map(i => (
                <option value={i.id} key={i.id}>
                  {i.name} ({i.startDate} - {i.endDate})
                </option>
              ))}
            </select>

            {errObj.period_id && <div className="form-error">{errObj.period_id}</div>}
          </label>

          <div className="pos" style={{ marginBottom: '50px' }}>
            <TriggerSearch id={treeReport?.id} callback={goToTreeNode} />
          </div>

          <div className="mb-3 tree-wrap scroll-y scroll-y-custom">
            <TreeItem data={{ ...treeReport, templateList: templateData?.models }} />
          </div>
        </div>

        <div className="card-footer">
          <button type="submit" className="btn btn-primary !bg-primary hover:!bg-[#3956e3]">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};

EditTrigger.propTypes = {
  history: PropType.object,
};

export default EditTrigger;
