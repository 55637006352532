import React from 'react';
import { Event } from '../../containers/Lk/index';

const EventsPage = () => {
  return (
    <>
      <Event />
    </>
  );
};

export default EventsPage;
