import PropTypes from 'prop-types';
import * as React from 'react';

const Download = ({ size = 15, color = '#9295a4', ...props }) => {
  return (
    <svg viewBox="0 0 512 512" width={size} height={size} fill={color} {...props}>
      <path d="M492 324.133c-11.046 0-20 8.954-20 20v118.49c0 5.17-4.207 9.377-9.378 9.377H49.378c-5.171 0-9.378-4.206-9.378-9.377v-118.49c0-11.046-8.954-20-20-20s-20 8.954-20 20v118.49C0 489.85 22.151 512 49.378 512h413.244C489.849 512 512 489.85 512 462.623v-118.49c0-11.046-8.954-20-20-20z" />
      <path d="M421.252 248.078A20 20 0 00402.888 236h-68.133V20c0-11.046-8.954-20-20-20h-117.51c-11.046 0-20 8.954-20 20v216h-68.133a20.002 20.002 0 00-14.548 33.725l146.888 155.701a20.003 20.003 0 0029.096 0l146.888-155.702a20 20 0 003.816-21.646zM256 382.556L155.476 276h41.768c11.046 0 20-8.954 20-20V40h77.51v216c0 11.046 8.954 20 20 20h41.77L256 382.556z" />
    </svg>
  );
};

Download.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Download;
