import {
  ACCESS_USER,
  DATA_FOLDER_CHAT,
  UPDATE_FOLDER_CHAT,
  UPDATE_NOT_REPRIMANDED,
  UPDATE_PROFILE,
  UPDATE_UNREAD_MESSAGES,
  UPDATE_UNREAD_MESSAGES_INCOMING,
  UPLOADING_STATUS_PROGRESS,
} from '../types';

export const updateProfile = profile => {
  return dispatch => {
    localStorage.setItem('profile', JSON.stringify(profile));

    dispatch({
      type: UPDATE_PROFILE,
      payload: profile,
    });
  };
};

export const toggleAddFolderChat = value => {
  return dispatch => {
    dispatch({
      type: UPDATE_FOLDER_CHAT,
      payload: value,
    });
  };
};

export const countNotReprimanded = count => {
  return dispatch => {
    dispatch({
      type: UPDATE_NOT_REPRIMANDED,
      payload: count,
    });
  };
};

export const dataInboxFolders = res => {
  return dispatch => {
    dispatch({
      type: DATA_FOLDER_CHAT,
      payload: res,
    });
  };
};

export const getAccessUser = res => {
  return dispatch => {
    dispatch({
      type: ACCESS_USER,
      payload: res,
    });
  };
};

export const getUnReadMessages = res => {
  return dispatch => {
    dispatch({
      type: UPDATE_UNREAD_MESSAGES,
      payload: res,
    });
  };
};

export const uploadingProgress = progress => {
  return dispatch => {
    dispatch({
      type: UPLOADING_STATUS_PROGRESS,
      payload: progress,
    });
  };
};

export const getUnReadMessagesIncoming = res => {
  return dispatch => {
    dispatch({
      type: UPDATE_UNREAD_MESSAGES_INCOMING,
      payload: res,
    });
  };
};

// export const uploadingProgress = progress => {
// 	return dispatch => {
// 		dispatch({
// 			type: UPLOADING_STATUS_PROGRESS,
// 			payload: progress,
// 		})
// 	}
// }
