export const toggleDk = () => {
    return dispatch => {
        dispatch({
            type: 'TOGGLE_DK',
        });
    };
};

export const showDk = () => {
    return dispatch => {
        dispatch({
            type: 'SHOW_DK',
        });
    };
};


export const toggleNewArchiveModal = (payload) => {
    return dispatch => {
        dispatch({
            type: 'NEW_ARCHIVE_MODAL',
            payload
        });
    };
};
export const toggleEditArchiveModal = (payload) => {
    return dispatch => {
        dispatch({
            type: 'EDIT_ARCHIVE_MODAL',
            payload
        });
    };
};

export const toggleCreateReasonModal = (payload) => {
    return dispatch => {
        dispatch({
            type: 'CREATE_REASON_MODAL',
            payload
        });
    };
};
export const toggleUpdateReasonModal = (payload) => {
    return dispatch => {
        dispatch({
            type: 'UPDATE_REASON_MODAL',
            payload
        });
    };
};
export const toggleCreateInvitationModal = (payload) => {
    return dispatch => {
        dispatch({
            type: 'CREATE_INVITATION_MODAL',
            payload
        });
    };
};
export const toggleUpdateInvitationModal = (payload) => {
    return dispatch => {
        dispatch({
            type: 'UPDATE_INVITATION_MODAL',
            payload
        });
    };
};

export const setProtocol = (payload) => {
    return dispatch => {
        dispatch({
            type: 'SET_PROTOCOL',
            payload
        });
    };
};
