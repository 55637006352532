import PropTypes from 'prop-types';
import * as React from 'react';

const ProfileUser = ({ size = 15, color = '#f6f6f6', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} {...props} viewBox="0 0 512 512">
      <path d="M437.019 310.826c-28.355-28.355-62.197-49.227-99.125-61.652 34.055-25.17 56.183-65.596 56.183-111.097C394.078 61.941 332.137 0 256 0S117.922 61.941 117.922 138.077c0 45.502 22.128 85.928 56.183 111.097-36.928 12.424-70.768 33.296-99.125 61.652C26.629 359.178 0 423.466 0 491.846 0 502.977 9.023 512 20.154 512h471.691c11.131 0 20.154-9.023 20.154-20.154.001-68.38-26.628-132.668-74.98-181.02zM158.232 138.077c0-53.91 43.858-97.768 97.769-97.768 53.911 0 97.769 43.858 97.769 97.768 0 53.91-43.858 97.768-97.769 97.768-53.911.001-97.769-43.858-97.769-97.768zM41.243 471.691C51.437 362.172 143.863 276.154 256 276.154s204.563 86.017 214.757 195.537H41.243z" />
    </svg>
  );
};

ProfileUser.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default ProfileUser;
