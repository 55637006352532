import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { apiGet } from '../../../api';
import { Accordeon, Input } from '../../components';

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const _TreeItem = ({ treeItem, templateList }) => {
  const { _id, title, content, children } = treeItem;

  const [btnType, setBtnType] = useState(null);
  const [sending, setSending] = useState(false);
  const [prefix, setPrefix] = useState('');

  const triggerSuccessCopyAlert = () => {
    confirmAlert({
      message: 'Триггер добавлен в буфер обмена, вставьте его в поле',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const copyTrigger = id => {
    if (!sending) {
      setSending(true);

      let url = `/report-element/copy?id=${id}`;
      if (btnType) {
        url += `&copyMode=${btnType}`;
      }
      if (prefix.length) {
        url += `&prefix=${prefix}`;
      }

      apiGet({ url })
        .then(res => {
          if (res.error) {
            console.error('Копирование триггера');
            return;
          }

          copyToClipboard(res.data);
          setBtnType(null);
          setPrefix('');
          triggerSuccessCopyAlert();
        })
        .finally(() => setSending(false));
    }
  };

  const onClick = type => {
    setBtnType(type);
  };

  const changePreviousReportIndex = value => {
    !value.match(/\D/g) && setPrefix(value);
  };

  return (
    <Accordeon id={_id} key={_id} title={title || ''}>
      {children?.map(i => (
        <TreeItem key={i._id} treeItem={i} templateList={templateList} />
      ))}

      <p>{content}</p>

      {!children && (
        <div className="mt-3 row row-sm">
          {!btnType ? (
            <>
              <div className="col-auto">
                <span className="btn btn-primary" onClick={() => onClick('This')}>
                  Текущий
                </span>
              </div>

              <div className="col-auto">
                <span className="btn btn-primary" onClick={() => onClick('Previous')}>
                  Предыдущий
                </span>
              </div>

              <div className="col-auto">
                <span className="btn btn-primary" onClick={() => onClick('Report')}>
                  Конкретный
                </span>
              </div>
            </>
          ) : (
            <>
              {btnType === 'Previous' && (
                <div className="col">
                  <Input
                    value={prefix}
                    fieldClasses="mb-0"
                    placeholder="Введите индекс"
                    onChange={changePreviousReportIndex}
                  />
                </div>
              )}

              {btnType === 'Report' && (
                <div className="col">
                  <select value={prefix} onChange={e => setPrefix(e.target.value)} className="form-input _select">
                    <option value="null">Выберите отчет</option>
                    {templateList.map(i => (
                      <option value={i.id} key={i.id}>
                        {i.template.name} ({i.period.startDate}-{i.period.endDate})
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="col-auto">
                <span className="btn btn-primary" onClick={() => copyTrigger(_id)}>
                  Скопировать
                </span>
              </div>
            </>
          )}
        </div>
      )}
    </Accordeon>
  );
};
_TreeItem.propTypes = {
  treeItem: PropTypes.object.isRequired,
  templateList: PropTypes.array.isRequired,
};
const TreeItem = memo(_TreeItem);

const Tree = ({ data }) => {
  const { tree, templateList, treeCompleted } = data;

  if (!treeCompleted) {
    return <div className="d-flex jc-center flex-fill">{/* <Spinner /> */}</div>;
  }

  return <TreeItem treeItem={tree} templateList={templateList} />;
};
Tree.propTypes = {
  data: PropTypes.object.isRequired,
};

export default memo(Tree);
