import React, {useEffect, useRef, useState} from 'react';
import {Input} from '../../../../components';
import cn from 'classnames';
import Done from '../../../../icons/Done';
import './invitationVar.scss';

let timeout;

export const InvitationVar = ({name, onSave,value, statusInvitation}) => {
    const [varValue,setVarValue] = useState(value || '');
    const [varError,setVarError] = useState([]);
    const  [disabledField,setDisabledField] = useState(true);
    const errorRef = useRef(null);
    const formRef = useRef(null);


    const onDoneClick = () => {
        onSave(name,varValue);
        setVarError([]);
        setDisabledField(true);
    };

    const onFormClick = () => {
        if (statusInvitation === 2) {
            if (errorRef.current && formRef.current) {
                errorRef.current.classList.remove('error_active');
                formRef.current.classList.add('form-disable');
            }
            timeout = setTimeout(() => {
                if (errorRef.current && formRef.current) {
                    formRef.current.classList.remove('form-disable');
                    errorRef.current.classList.add('error_active');
                }
            },3000);
        }
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        };
    },[]);

    return (
        <div className={cn('d-flex ai-center relative my-2 gap-2 jc-between')} >
            <div ref={errorRef} className='absolute error_active animate-pulse z-0 text-sm m767:text-base leading-none transition-all right-0 -top-1.5 text-red/80 font-medium'>
                Приглашение уже отправлено
            </div>

            <div ref={formRef} onClick={onFormClick} className={cn('d-flex ai-center relative var-form-wrap gap-2 w-full')}>
                <Input label='Название' disabled value={name} fieldClasses={cn('w-full mb-0')}/>
                <Input
                    onChange={(v) => {
                        setDisabledField(false);
                        setVarValue(v);
                    }}
                    value={varValue}
                    disabled={statusInvitation === 2}
                    label='Значение'
                    error={!!varError.length && varError}
                    fieldClasses='w-full mb-0'
                />
            </div>

            <button
                disabled={Boolean(disabledField)}
                onClick={onDoneClick}
                className={cn('btn btn-success mt-6 min-h-[43px]', {'!bg-gray !cursor-not-allowed': disabledField})}
            >
                <Done/>
            </button>
        </div>
    );
};

