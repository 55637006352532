import React from 'react';
import { Calendar } from '../../containers/Lk';

const CalendarPage = () => {
  return (
    <>
      <Calendar />
    </>
  );
};

export default CalendarPage;
