import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiGet, apiPost } from '../../../api';
import { appLogout } from '../../../redux/actions/app';
// import logo from "../../../static/img/logo_edinstvo.svg"
import { MobileMenu } from '../../icons/index';
import './index.scss';

const Header = ({ onOpenMobMenu }) => {
  const [checkTheme, setCheckTheme] = useState('');
  const state = useSelector(state => state);
  // const { response } = useFetch({ url: "/user/profile" })

  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(appLogout());
  };

  const getTheme = () => {
    apiGet({ url: '/user/profile' }).then(res => {
      setCheckTheme(res.data.theme);
    });
  };

  const changeTheme = () => {
    apiPost({
      url: '/user/set-theme',
      postData: {
        theme: checkTheme == 2 ? 1 : 2,
      },
    }).then(() => {
      setCheckTheme(checkTheme == 2 ? 1 : 2);
    });
  };

  useEffect(() => {
    localStorage.setItem('theme', `${checkTheme == 1 ? '_dark' : '_light'}`);
    const htmlCls = document.getElementById('html').classList;
    const theme = localStorage.getItem('theme');
    if (theme === '_dark') {
      htmlCls.remove('_light');
      htmlCls.add('_dark');
    }
    if (theme === '_light') {
      htmlCls.remove('_dark');
      htmlCls.add('_light');
    }
  }, [checkTheme]);

  useEffect(() => {
    getTheme();
  }, []);

  // if (loading) {
  // 	return <p>Загрузка...</p>
  // }
  return (
    <div className="header ai-center d-flex">
      <button className="d-block d-xl-none mob-menu mr-3" onClick={() => onOpenMobMenu()}>
        <MobileMenu />
      </button>
      <a href="/" className="logo f-800">
        {/*<img src={logo} alt="" />*/}
      </a>
      <div className="right-content d-flex">
        <div className="check-theme ">
          <label>
            <input
              type="checkbox"
              name="checkboxName"
              className="checkbox-theme"
              value={checkTheme}
              checked={checkTheme}
              onChange={() => changeTheme()}
            />
            <div className={`switch ${checkTheme == true && 'active'}`}>
              <div className="circle"></div>
            </div>
          </label>
        </div>
        <Link to="/profile" className="user d-flex f-600">
          {state?.user?.profile.name || ''}
        </Link>
        <button className="btn btn-primary btn-xs" onClick={() => LogOut()}>
          Выйти
        </button>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  mobMenuPress: PropTypes.func,
  onOpenMobMenu: PropTypes.any,
};
