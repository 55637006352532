import React from 'react';
import { ViewReport } from '../../containers/Lk/index';

const ViewReportPage = () => {
  return (
    <>
      <ViewReport />
    </>
  );
};

export default ViewReportPage;
