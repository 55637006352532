import PropTypes from 'prop-types';
import * as React from 'react';

const SvgComponent = ({ size = 20, color = '#000', ...props }) => {
  return (
    <svg viewBox="0 0 512 512" height={size} width={size} fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M150.843 512h212.176v-78.267c0-9.083 7.363-16.445 16.445-16.445h82.829V99.714c0-8.503-6.893-15.396-15.396-15.396H150.843c-8.503 0-15.396 6.893-15.396 15.396v396.89c0 8.503 6.893 15.396 15.396 15.396zm35.665-329.367H413.42c9.082 0 16.445 7.363 16.445 16.445s-7.363 16.445-16.445 16.445H186.508c-9.082 0-16.445-7.363-16.445-16.445s7.363-16.445 16.445-16.445zm0 65.934H413.42c9.082 0 16.445 7.363 16.445 16.445s-7.363 16.445-16.445 16.445H186.508c-9.082 0-16.445-7.363-16.445-16.445s7.363-16.445 16.445-16.445zm0 65.935H413.42c9.082 0 16.445 7.363 16.445 16.445s-7.363 16.445-16.445 16.445H186.508c-9.082 0-16.445-7.363-16.445-16.445s7.363-16.445 16.445-16.445zm0 65.934h134.873c9.082 0 16.445 7.363 16.445 16.445s-7.363 16.445-16.445 16.445H186.508c-9.082 0-16.445-7.363-16.445-16.445s7.363-16.445 16.445-16.445z" />
      <path d="M102.556 67.872c0-9.083 7.363-16.445 16.445-16.445h290.442v-36.03C409.444 6.893 402.55 0 394.047 0H65.102c-8.503 0-15.396 6.893-15.396 15.396v429.781c0 8.503 6.893 15.396 15.396 15.396h37.454zM437.678 450.178H395.91v39.849z" />
    </svg>
  );
};

SvgComponent.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default SvgComponent;
