import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Editor} from '@tinymce/tinymce-react';
import {EDITOR_OPTIONS} from './CustomEditorOptions';

export const hideOptionEditor = () => {
  const btn = window.document.querySelector('.tox-tbtn--enabled');
  btn && btn.click();
};

export const CustomEditor = ({
    label = null,
    fieldClasses = '',
    onChange = () => {},
    error = null,
    ...props
}) => {
    const editorRef = useRef(null);

    const labelClick = () => {
        if(editorRef.current ){
            editorRef.current.focus();
        }
    };

    return (
        <div>
            <label className={`form-field mt-3 ${fieldClasses}`}>
                {label && <span onClick={labelClick} className={'form-label  w-fit'}>{label}</span>}

                <span className="custom-editor">
                    <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        apiKey='ld1q6c33olx3ktcfpoam29x1omwb8sr82p9xxpen9tlq5o4s'
                        onEditorChange={v=> onChange(v)}
                        value={props.value || ''}
                        init={{
                            ...EDITOR_OPTIONS
                        }}
                    />
                </span>


                {error &&
                    error.map(e => (
                        <div key={e} className="form-error">
                            {e}
                        </div>
                    ))}
            </label>
        </div>
    );
};

CustomEditor.propTypes = {
    label: PropTypes.any,
    register: PropTypes.any,
    fieldClasses: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.any,
    type: PropTypes.string,
};
