import React from 'react';
import { Tree } from '../../components/index';

const TreePage = () => {
  return (
    <>
      <Tree />
    </>
  );
};

export default TreePage;
