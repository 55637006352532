import PropTypes from 'prop-types';
import * as React from 'react';

const Approval = ({ size = 20, color = '#fff', ...props }) => {
  return (
    <svg width={size} height={size} fill={color} {...props} viewBox="-76 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M339.336 0H19.96C8.937 0 0 8.938 0 19.96v472.08C0 503.061 8.938 512 19.96 512h319.376c11.027 0 19.96-8.938 19.96-19.96V19.96c0-11.023-8.933-19.96-19.96-19.96zm-19.961 472.078H39.922V39.922h279.453zm-69.672-185.781c7.797 7.797 7.797 20.437 0 28.23l-75.851 75.852c-7.793 7.797-20.438 7.793-28.23 0l-36.028-36.027c-7.797-7.797-7.797-20.438 0-28.23 7.797-7.794 20.433-7.798 28.23 0l21.914 21.913 61.735-61.738c7.797-7.793 20.437-7.793 28.23 0zM75.875 136.734c0-11.027 8.938-19.96 19.96-19.96h167.677c11.023 0 19.96 8.933 19.96 19.96 0 11.024-8.937 19.961-19.96 19.961H95.836c-11.024 0-19.961-8.937-19.961-19.96zm0 79.844c0-11.027 8.938-19.96 19.96-19.96h167.677c11.023 0 19.96 8.933 19.96 19.96 0 11.024-8.937 19.961-19.96 19.961H95.836c-11.024 0-19.961-8.937-19.961-19.96zm0 0" />
    </svg>
  );
};

Approval.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Approval;
