import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import useUrl from '../../../hooks/useUrl';
import { downloadBase64File } from '../../../utils/downloadBase64';
import { Breadcrumbs, Dropzone, Modal, Pagination } from '../../components';
import { Download } from '../../icons';
import { organizationApi } from '../../../api/organization/organization';
import { useMutation, useQuery } from '@tanstack/react-query';
import Index from '../../components/Spinner';
import { modalApi } from '../../../redux/actions/modal';
import { confirmAlert } from 'react-confirm-alert';
import View from '../../icons/View';
import { Breadcrumb } from 'antd';

const OrganizationRegistryRowListPage = () => {
  const { id } = useParams();
  const { page, organizationId } = useUrl();

  const { data: registryRowList, isLoading } = useQuery({
    queryFn: () => organizationApi.registryRowList({ page, registryId: id }),
    queryKey: ['registryListRow', page],
  });

  const linkRoute = (() => {
    const result = [
      {
        name: 'Пользователи',
        link: '/users',
      },
    ];

    if (organizationId) {
      result.push({
        name: 'Реестры по должникам',
        link: `/organization-registry/${organizationId}`,
      });
    }

    result.push({
      name: `Реестр №${id}`,
    });

    return result;
  })();

  return (
    <>
      <Breadcrumbs linkRoute={linkRoute} />

      <div className="summary card">
        <div className="card-header">Нарушения</div>

        <div className="card-content">
          {isLoading ? (
            <div className="flex items-center justify-center">
              <Index />
            </div>
          ) : (
            <div className="scroll-x">
              <table className="mt-3 low-table">
                <thead>
                  <tr>
                    <th>Тип</th>
                    <th>Текст</th>
                    <th className="edit"></th>
                  </tr>
                </thead>

                <tbody>
                  {registryRowList?.data.models.map(i => (
                    <tr key={i.id}>
                      <td>{i?.typeText}</td>
                      <td>{i?.text}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="mt-2 ta-center">
            <Pagination lastPage={registryRowList?.data.lastPage} page={page} />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationRegistryRowListPage;
