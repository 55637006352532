import PropTypes from 'prop-types';
import * as React from 'react';

const Plus = ({ size = 15, color = '#f6f6f6', ...props }) => {
  return (
    <svg viewBox="0 0 512 512" width={size} height={size} fill={color} {...props}>
      <path d="M492 236H276V20c0-11.046-8.954-20-20-20s-20 8.954-20 20v216H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h216v216c0 11.046 8.954 20 20 20s20-8.954 20-20V276h216c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
    </svg>
  );
};

Plus.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Plus;
