import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { uploadingProgress } from '../redux/actions/user';
import { store } from '../redux/store';
import { errorHandler } from '../utils/errorHandler';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 180000,
});

export const apiPost = async ({ url, postData = null, headerList = {}, showProgress = true }) => {
  const { authToken } = store.getState().app;
  const headers = { Authorization: `Bearer ${authToken}`, ...headerList };

  try {
    const res = await Api.post(url, postData, {
      headers,
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (percentCompleted % 5 === 0 && showProgress) {
          store.dispatch(uploadingProgress({ loading: true, progress: percentCompleted }));
        }
      },
    });

    if (res.data.status === 400) {
      confirmAlert({
        message: res.data.data,
        buttons: [
          {
            label: 'Ок',
            onClick: () => {},
          },
        ],
      });
    }
    store.dispatch(uploadingProgress({ loading: false, progress: 0 }));
    return res.data;
  } catch (e) {
    errorHandler({
      url,
      errorText: e.message,
    });
  }
};

export const apiGet = async ({ url, headerList = {}, timeout = 60000, fileWeight = null }) => {
  const { authToken } = store.getState().app;
  const headers = { Authorization: `Bearer ${authToken}`, ...headerList };

  try {
    const res = await axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: timeout,
      })
      .get(url, {
        headers,
        onDownloadProgress: e => {
          const percentCompleted = Math.round((e.loaded * 100) / fileWeight);

          if (percentCompleted % 5 === 0 && percentCompleted < 100) {
            store.dispatch(uploadingProgress({ loading: true, progress: percentCompleted }));
          }
          if (percentCompleted === 99) {
            store.dispatch(uploadingProgress({ loading: true, progress: percentCompleted }));
          }
        },
      });
    if (res.data.status === 400) {
      confirmAlert({
        message: res.data.data,
        buttons: [
          {
            label: 'Ок',
            onClick: () => {},
          },
        ],
      });
    }
    store.dispatch(uploadingProgress({ loading: false, progress: 0 }));
    return res.data;
  } catch (e) {
    errorHandler({
      url,
      errorText: e.message,
    });
  }
};
