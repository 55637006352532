import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useParams } from 'react-router-dom';
import SelectCustom from 'react-select';
import { apiGet, apiPost } from '../../../../api';
import updateUrl from '../../../../hooks/updateUrl';
import useDebounce from '../../../../hooks/useDebounce';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { Breadcrumbs, Input, Modal, Pagination } from '../../../components';
import { Gear, TrashCan } from '../../../icons';
import './index.scss';

const TemplateSummaryReportTriggersElements = () => {
  const { id, idTemplateSummaryReport } = useParams();
  const { page } = useUrl();

  const [sending, setSending] = useState(false);
  const [errObj, setErrObj] = useState({});

  const [modalCreate, setModalCreate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [elementTextEdit, setElementTextEdit] = useState('');
  const [searchEmelentName, setSearchElementName] = useState('');
  const [editNameTemplateSummaryElementReport, setEditNameTemplateSummaryElementReport] = useState('');
  const [elementIdEdit, setElementIdEdit] = useState('');
  const [createNameTemplateSummaryElementReport, setCreateNameTemplateSummaryElementReport] = useState('');
  const [createElementText, setCreateElementText] = useState('');
  const [afterElementId, setAfterElementId] = useState('');
  const [searchAfterElementId, setSearchAfterElementId] = useState('');

  const { response: afterElementsData } = useFetch({
    url: `/summary-report-element?limit=10&summary_report_template_id=${idTemplateSummaryReport}&name=${
      searchAfterElementId || ''
    }`,
  });

  const { response: treeReport } = useFetch({
    url: `/report-template/reference?id=${id}`,
  });

  const debouncedTriggerName = useDebounce(createElementText);
  const url = debouncedTriggerName.length
    ? `/report-element?instance_id=${treeReport?.id}&name=${debouncedTriggerName}&limit=10`
    : null;

  const { response: triggerSearchList, setResponse: setTriggerSearchList } = useFetch({ url });

  const editDebouncedTriggerName = useDebounce(elementTextEdit);
  const urlEdit = editDebouncedTriggerName.length
    ? `/report-element?instance_id=${treeReport?.id}&name=${editDebouncedTriggerName}&limit=10`
    : null;

  const { response: triggerSearchListEdit, setResponse: setTriggerSearchListEdit } = useFetch({ url: urlEdit });

  const { response, fetchData } = useFetch({
    url: `/summary-report-element?page=${page}&limit=10&summary_report_template_id=${idTemplateSummaryReport}&name=${
      searchEmelentName || ''
    }`,
  });

  const [createPositionElement, setCreatePositionElement] = useState('end');

  const createTemplateSummaryReportElement = () => {
    const postData = {
      name: createNameTemplateSummaryElementReport,
      element_name: createElementText,
    };
    if (createPositionElement == 'afterElementId') {
      postData.afterElementId = afterElementId.value;
    }
    if (createPositionElement == 'beforeElementId') {
      postData.beforeElementId = afterElementId.value;
    }

    if (!sending) {
      apiPost({
        url: `/summary-report-element/create?summary_report_template_id=${idTemplateSummaryReport}`,
        postData: postData,
      })
        .then(res => {
          if (res?.status === 406) {
            setErrObj(res.data);
            return;
          }
          if (res.error) {
            console.error('Создание элемента шаблона сводного отчета по триггерам');
            return;
          }
          setCreatePositionElement('end');
          setAfterElementId();
          setErrObj({});
          setCreateNameTemplateSummaryElementReport('');
          setCreateElementText('');
          fetchData();
          setModalCreate(false);
        })
        .finally(() => setSending(false));
    }
  };

  const editTemplateSummaryReportElement = () => {
    const postData = {
      name: editNameTemplateSummaryElementReport,
      element_name: elementTextEdit,
    };
    if (createPositionElement == 'afterElementId') {
      postData.afterElementId = afterElementId.value;
    }
    if (createPositionElement == 'beforeElementId') {
      postData.beforeElementId = afterElementId.value;
    }

    if (!sending) {
      apiPost({
        url: `/summary-report-element/update?id=${elementIdEdit}`,
        postData: postData,
      })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          }
          if (res.error) {
            console.error('Изменение элемента шаблона сводного отчета по триггерам');
            return;
          }
          setCreatePositionElement('end');
          setAfterElementId();
          setErrObj({});
          setCreateNameTemplateSummaryElementReport('');
          setElementTextEdit('');
          fetchData();
          setModalEdit(false);
        })
        .finally(() => setSending(false));
    }
  };

  const peasteTriggerElement = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-element/copy?id=${id}&copyMode=This` })
        .then(res => {
          if (res.error) {
            console.error('Вставка триггера триггера');
            return;
          }

          setCreateElementText(res.data);
        })
        .finally(() => setSending(false));
    }
  };

  const peasteTriggerElementEdit = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-element/copy?id=${id}&copyMode=This` })
        .then(res => {
          if (res.error) {
            console.error('Вставка триггера триггера');
            return;
          }

          setElementTextEdit(res.data);
        })
        .finally(() => setSending(false));
    }
  };

  const getSearchItem = id => {
    peasteTriggerElement(id);
    setTriggerSearchList([]);
  };

  const getSearchItemEdit = id => {
    peasteTriggerElementEdit(id);
    setTriggerSearchListEdit([]);
  };

  const deleteTemplateSummaryReportElent = id => {
    if (!sending) {
      apiGet({
        url: `/summary-report-element/delete?id=${id}`,
      })
        .then(res => {
          if (res.error) {
            console.error('Удаление элемента шаблона сводного отчета по триггерам');
            return;
          }
          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  const alerDeleteTemplateSummaryReportElement = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить элемент шаблона сводного отчета по триггерам?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteTemplateSummaryReportElent(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Просмотр шаблона',
      link: `/view-report/${id}`,
    },
    {
      name: 'Шаблоны сводных отчетов по триггерам',
      link: `/template-summary-report-triggers/${id}`,
    },
    {
      name: 'Элементы шаблона сводного отчета по триггерам',
      link: `/template-summary-report-elements/${id}/${idTemplateSummaryReport}`,
    },
  ];

  const openEditModal = item => {
    setEditNameTemplateSummaryElementReport(item.name);
    setElementIdEdit(item.id);
    setElementTextEdit(item?.element_name);
    setModalEdit(true);
  };

  const functionSearch = e => {
    setSearchElementName(e);
    history.push(updateUrl({ key: 'page', value: '1' }));
  };

  useEffect(() => {
    if (!debouncedTriggerName.length) {
      setTriggerSearchList([]);
    }
  }, [debouncedTriggerName]);

  useEffect(() => {
    if (!editDebouncedTriggerName.length) {
      setTriggerSearchListEdit([]);
    }
  }, [editDebouncedTriggerName]);

  return (
    <>
      <div className="template-summary-report-elements">
        <Breadcrumbs linkRoute={linkRoute} />
        <div className="card">
          <div className="card-header ">
            <div className="row jc-between">
              <div className="col-auto mb-2">
                <span>Список элементов сводных отчетов по триггерам</span>
              </div>

              <div className="col-auto">
                <button className="btn btn-primary" onClick={() => setModalCreate(true)}>
                  Создание элемента шаблона сводного отчета по триггерам
                </button>
              </div>
            </div>
          </div>
          <div className="card-content">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                <Input fieldClasses="mb-0" placeholder="Поиск..." onChange={e => functionSearch(e)} />
              </div>
            </div>
            <div className="scroll-x">
              <table className="mt-4 low-table">
                <thead>
                  <tr>
                    <th>Название</th>
                    <th className="settings"></th>
                  </tr>
                </thead>
                <tbody>
                  {response?.models.map(item => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>
                        <div className="row row-sm jc-end">
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-warning"
                              onClick={() => openEditModal(item)}
                              title="Изменить"
                            >
                              <Gear />
                            </button>
                          </div>
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-danger"
                              onClick={() => alerDeleteTemplateSummaryReportElement(item.id)}
                              title="Удаление"
                            >
                              <TrashCan />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-2 ta-center">
              <Pagination lastPage={response?.lastPage} page={page} />
            </div>
          </div>
          <Modal
            fieldClasses="h-100"
            show={modalCreate}
            onClose={() => setModalCreate(false)}
            modalTitle="Создание элемента шаблона сводного отчета по триггерам"
            footer={
              <button className="btn btn-primary" onClick={() => createTemplateSummaryReportElement()}>
                Создать
              </button>
            }
          >
            <div className="row">
              <div className="col-12">
                <Input
                  label="Название элемента"
                  value={createNameTemplateSummaryElementReport}
                  onChange={setCreateNameTemplateSummaryElementReport}
                  error={errObj.name}
                />
              </div>
              <div className="col-12">
                <Input
                  fieldClasses="mb-0 pos"
                  label="Элемент"
                  value={createElementText}
                  onChange={setCreateElementText}
                  error={errObj.name}
                />

                {triggerSearchList && (
                  <div className="search-list">
                    {triggerSearchList.map(i => (
                      <div key={i.id} className="item cursor-pointer" onClick={() => getSearchItem(i.id)}>
                        {i.name} {i.value}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-12">
                <label style={{ marginTop: '30px' }} className="form-field form-label size">
                  Расположение элемента
                </label>
                <label className="form-field form-label size pl-0">
                  <select
                    name="#"
                    onChange={e => setCreatePositionElement(e.target.value)}
                    className="form-input _select"
                    value={createPositionElement}
                  >
                    <option value="end">Расположить без учета порядка</option>
                    <option value="afterElementId">Расположить до элемента</option>
                    <option value="beforeElementId">Расположить после элемента</option>
                  </select>
                </label>
              </div>
              {createPositionElement !== 'end' && (
                <div className="col-12">
                  <label style={{ marginTop: '30px' }} className="form-field form-label size">
                    Очередность элемента
                  </label>
                  {afterElementsData && (
                    <SelectCustom
                      className="select-company mb-4 p-0"
                      classNamePrefix="select"
                      placeholder=""
                      options={afterElementsData?.models.map(i => ({
                        value: i.id,
                        label: i.name,
                      }))}
                      value={afterElementId}
                      onChange={setAfterElementId}
                      onInputChange={e => setSearchAfterElementId(e)}
                      noOptionsMessage={() => 'Нет вариантов..'}
                    />
                  )}
                </div>
              )}
            </div>
          </Modal>
          <Modal
            fieldClasses="h-100"
            show={modalEdit}
            onClose={() => setModalEdit(false)}
            modalTitle="Изменение элемента шаблона сводного отчета по триггерам"
            footer={
              <button className="btn btn-primary" onClick={() => editTemplateSummaryReportElement()}>
                Изменить
              </button>
            }
          >
            <div className="row">
              <div className="col-12">
                <Input
                  label="Название элемента"
                  value={editNameTemplateSummaryElementReport}
                  onChange={setEditNameTemplateSummaryElementReport}
                  error={errObj.name}
                />
              </div>
              <div className="col-12">
                <Input
                  fieldClasses="mb-0 pos"
                  label="Элемент"
                  value={elementTextEdit}
                  onChange={setElementTextEdit}
                  error={errObj.name}
                />
                {triggerSearchListEdit && (
                  <div className="search-list">
                    {triggerSearchListEdit.map(i => (
                      <div key={i.id} className="item cursor-pointer" onClick={() => getSearchItemEdit(i.id)}>
                        {i.name} {i.value}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-12">
                <label style={{ marginTop: '30px' }} className="form-field form-label size">
                  Расположение элемента
                </label>
                <label className="form-field form-label size pl-0">
                  <select
                    name="#"
                    onChange={e => setCreatePositionElement(e.target.value)}
                    className="form-input _select"
                    value={createPositionElement}
                  >
                    <option value="end">Расположить без учета порядка</option>
                    <option value="afterElementId">Расположить до элемента</option>
                    <option value="beforeElementId">Расположить после элемента</option>
                  </select>
                </label>
              </div>
              {createPositionElement !== 'end' && (
                <div className="col-12">
                  <label style={{ marginTop: '30px' }} className="form-field form-label size">
                    Очередность элемента
                  </label>
                  {afterElementsData && (
                    <SelectCustom
                      className="select-company mb-4 p-0"
                      classNamePrefix="select"
                      placeholder=""
                      options={afterElementsData?.models.map(i => ({
                        value: i.id,
                        label: i.name,
                      }))}
                      value={afterElementId}
                      onChange={setAfterElementId}
                      onInputChange={e => setSearchAfterElementId(e)}
                      noOptionsMessage={() => 'Нет вариантов..'}
                    />
                  )}
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default TemplateSummaryReportTriggersElements;
