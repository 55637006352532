import React from 'react';
import { EditReport } from '../../containers/Lk/index';

const EditReportPage = () => {
  return (
    <>
      <EditReport />
    </>
  );
};

export default EditReportPage;
