import PropTypes from 'prop-types';
import {memo, useRef} from 'react';

const Select = ({
  label = null,
  fieldClasses = '',
  disabled = false,
  onChange = () => {},
  values = [],
  error = null,
  ...props
}) => {
  let selectClassName = 'form-input _select' + props.className;

  if (error) {
    selectClassName += ' _error';
  }

  return (
    <label className={`form-field ${fieldClasses}`}>
      {label && <span className={`form-label  ${disabled ? 'cursor-default' : ''}`}>{label}</span>}

      <select  {...props} onChange={e => onChange(e.target.value)} className={selectClassName} disabled={disabled}>
        {values.map(v => {
          return (
            <option key={v.id} value={v.id}>
              {v.name}
            </option>
          );
        })}
      </select>

      {error && <div className="form-error">{error}</div>}
    </label>
  );
};
Select.propTypes = {
  label: PropTypes.any,
  values: PropTypes.array,
  fieldClasses: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.any,
};

export default memo(Select);
