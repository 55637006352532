import PropTypes from 'prop-types';
import React, { memo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Plus } from '../../icons';
import './index.scss';

const Accordeon = ({ id, title, children }) => {
  return (
    <Accordion className="accordeon-window" allowZeroExpanded allowMultipleExpanded>
      <AccordionItem className="accordeon-block mt-2">
        <AccordionItemHeading>
          <AccordionItemButton className="accordion d-flex jc-between">
            {title}
            <Plus />
          </AccordionItemButton>
        </AccordionItemHeading>

        <AccordionItemPanel data-id={id}>{children}</AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};
Accordeon.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default memo(Accordeon);
