/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import SelectCustom from 'react-select';
import { apiGet, apiPost } from '../../../../api';
import updateUrl from '../../../../hooks/updateUrl';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { dataInboxFolders, getUnReadMessagesIncoming, toggleAddFolderChat } from '../../../../redux/actions/user';
import { Dropzone, Input, Modal, Pagination } from '../../../components';
import { PaperClip } from '../../../icons';
import './index.scss';
import {CustomEditor} from '../../../components/CustomEditor/CustomEditor';
import {Checkbox} from 'antd';
import ReactQuill from "react-quill";

const Inbox = () => {
  const state = useSelector(s => s);
  const { pathname, search, hash } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, from, title, organizationName } = useUrl();
  const [createInboxModal, setCreateInboxModal] = useState(false);
  const [errObj, setErrObj] = useState({});
  const [files, setFiles] = useState([]);
  const [selectCustomIds, setSelectCustomIds] = useState([]);
  const [titleMessage, setTitleMessage] = useState('');
  const [bodyMessage, setBodyMessage] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [searchMessage, setSearchMessage] = useState(title || '');
  const [deleteInbox, setDeleteInbox] = useState([]);
  const [sendMessage, setSendMessage] = useState(false);
  const [sendToFolderChat, setSendToFolderChat] = useState(false);
  const [searchOrganization, setSearchOrganization] = useState(organizationName || '');

  const [folderSelectId, setFolderSelectId] = useState(0);

  const [selectCreateFolder, setSelectCreateFolder] = useState('');
  const [renameFolderModal, setRenameFolderModal] = useState(false);

  const [nameFolderInput, setNameFolderInput] = useState('');
  const [orgNamesArr, setOrgNamesArr] = useState();

  const checkboxVisible = location.search.includes('mail_message_group_id') && !location.search.includes('from');
  const [notViewedValue, setNotViewedValue] = useState(false);



  let url = '/mail-message?&limit=10';
  if (page) {
    url += `&page=${page}`;
  }

  if (from) {
    url += `&from=${from}`;
  }
  if (location.search) {
    let loc = location.search.replace('?', '&');
    url += `${loc}`;
  }

  if (searchMessage || searchOrganization) {
    url = `/mail-message?&limit=10&page=${page}&title=${searchMessage}&organizationName=${searchOrganization}`;
  }

  if (notViewedValue && checkboxVisible ) {
    url += '&onlyNotViewed=1';
  }

  // if (searchOrganization) {
  // 	url += `&organizationName=${searchOrganization}`
  // }

  // if (searchMessage) {
  // 	url += `&title=${searchMessage}`
  // }

  // if (searchOrganization) {
  // 	url += `&organizationName=${searchOrganization}`
  // }

  const {
    response: tickets,
    setResponse: setTickets,
    fetchData: refreshMessageList,
  } = useFetch({
    url,
  });

  const { response: companyNames } = useFetch({
    url: `/user?limit=10&q=${searchInput}`,
  });

  useEffect(() => {
    let arr = [];
    companyNames?.models.map(i => {
      arr.push({
        label: `${i?.organization?.name} ${i?.organization?.inn}  ${i.username != '' ? `user${i?.username}` : ''}`,
        value: i?.organization?.id,
      });
    });
    setOrgNamesArr([{ label: 'Все организации', value: 'all' }, ...arr]);
  }, [companyNames]);

  const { response: folderList, fetchData: refreshFolderList } = useFetch({
    url: '/mail-message-group',
  });

  useEffect(() => {
    if (tickets?.lastPage < page) {
      let newSearch = '';
      if (search.length) {
        if (page) {
          newSearch = search.replace(/page=[0-9]+/, `page=${tickets?.lastPage}`);
        } else {
          newSearch = `${search}&page=${tickets?.lastPage}`;
        }
      } else {
        newSearch = `?page=${tickets?.lastPage}`;
      }

      history.replace(`${pathname}${newSearch}${hash}`);
    }
    refreshMessageList();
  }, [tickets?.lastPage]);

  const toggleCheckbox = (index, id) => {
    const models = [...tickets.models];
    models[index].checkbox = !models[index].checkbox;
    setTickets({ ...tickets, models });

    if (models[index].checkbox) {
      setDeleteInbox([...deleteInbox, id]);
    }
    if (!models[index].checkbox) {
      let arr = deleteInbox;
      arr.splice(arr.findIndex(e => e.name === id));
      setDeleteInbox(arr);
    }
  };

  const removeFile = fileName => {
    const data = files.filter(item => item.name !== fileName);
    setFiles(data);
  };

  const handleUploadFiles = file => {
    setFiles([...files, ...file]);
  };

  const createMessages = () => {
    setSendMessage(true);
    if (titleMessage == '<p><br></p>') {
      setTitleMessage('');
    }
    let formData = new FormData();

    let arrCustomIds = [];
    let strCustomIds = '';
    let check = false;
    if (selectCustomIds.length) {
      selectCustomIds.forEach(i => {
        if (i.value == 'all') {
          check = true;
          formData.append('forAllOrganizations', true);
        } else {
          arrCustomIds.push(i.value);
          strCustomIds = arrCustomIds.join(',');
        }
      });
    }

    if (!check) {
      formData.append('organizationIds', strCustomIds);
    }

    formData.append('title', titleMessage);
    formData.append('body', bodyMessage);

    if (files.length) {
      files.forEach(f => {
        formData.append('files[]', f);
      });
    }

    apiPost({
      url: '/mail-message/create',
      postData: formData,
    })
      .then(res => {
        setSendMessage(false);
        if (res.error) {
          setErrObj(res.data);
          return;
        }
        setCreateInboxModal(false);
        setTitleMessage('');
        setBodyMessage('');
        setFiles([]);
        setSelectCustomIds([]);
        refreshMessageList();
      })
      .finally(() => setSendMessage(false));
  };

  const createFolder = () => {
    apiPost({
      url: '/mail-message-group/create',
      postData: {
        name: selectCreateFolder,
      },
    }).then(res => {
      if (res.error) {
        setErrObj(res.data);
        return;
      }
      dispatch(toggleAddFolderChat(false));
      setSelectCreateFolder('');
      getInboxFolders();
    });
  };

  const getInboxFolders = () => {
    apiGet({ url: '/mail-message-group' }).then(res => {
      if (res.error) {
        console.error('Получение папок');
        return;
      }
      dispatch(dataInboxFolders(res));
    });
  };

  const alertUnreadMessage = (id, e) => {
    e.preventDefault();
    confirmAlert({
      title: '',
      message: 'Вы точно хотите сделать сообщение не прочитаным?',
      buttons: [
        {
          label: 'Да',
          onClick: () => toggleViewMessage(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const toggleViewMessage = id => {
    apiGet({ url: `/mail-message/set-as-not-viewed?id=${id}` }).then(res => {
      if (res.error) {
        console.error('Сообщение сделать прочитаным.');
        return;
      }
      getUnreadableMsg();
      refreshMessageList();
    });
  };

  const changeNameFolder = () => {
    let idFolder = location.search;
    let newIdFolder = idFolder.replace('?mail_message_group_id=', '');

    apiPost({
      url: `/mail-message-group/update?id=${newIdFolder}`,
      postData: {
        name: nameFolderInput,
      },
    }).then(res => {
      if (res.error) {
        console.error('Удаление папки');
        return;
      }
      dispatch(dataInboxFolders(res));
      getInboxFolders();
      setRenameFolderModal(false);
      refreshFolderList();
    });
  };

  const getUnreadableMsg = () => {
    apiGet({ url: '/mail-message/not-viewed-count?group=0' }).then(res => {
      if (res?.error) {
        console.error('Получение непрочитаных сообщений');
        return;
      }
      dispatch(getUnReadMessagesIncoming(res.data));
    });
  };

  useEffect(() => {
    getUnreadableMsg();
  }, []);

  useEffect(() => {
    let idFolder = location.search;
    let newIdFolder = idFolder.replace('?mail_message_group_id=', '');
    for (let i = 0; folderList?.data.length > i; i++) {
      if (folderList?.data[i].id == newIdFolder) {
        setNameFolderInput(folderList?.data[i].name);
      }
    }
  }, [location.search]);

  // useEffect(() => {
  // 	setDeleteInbox([])
  // }, [location.search])

  const alertDeleteFolder = () => {
    confirmAlert({
      title: '',
      message: 'Вы точно хотите удалить папку?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteFolder(),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const deleteFolder = () => {
    let idFolder = location.search;
    let newIdFolder = idFolder.replace('?mail_message_group_id=', '');

    apiGet({ url: `/mail-message-group/delete?id=${newIdFolder}` }).then(res => {
      if (res.error) {
        console.error('Удаление папки');
        return;
      }
      dispatch(dataInboxFolders(res));
      getInboxFolders();
    });
  };

  const alertEmptyCheckbox = () => {
    confirmAlert({
      title: '',
      message: 'Сообщения не выбраны',
      buttons: [
        {
          label: 'Ok',
          onClick: e => e,
        },
      ],
    });
  };

  const alertMoveToFolderTrue = () => {
    confirmAlert({
      title: '',
      message: 'Сообщения успешно перемещены',
      buttons: [
        {
          label: 'Ok',
          onClick: e => e,
        },
      ],
    });
  };

  const alertDeleteChat = () => {
    if (!deleteInbox.length) {
      alertEmptyCheckbox();
      return;
    }
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить сообщения?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteChat(),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const deleteChat = () => {
    const idsList = deleteInbox.join(',');

    apiGet({ url: `/mail-message/delete?ids=${idsList}` }).then(res => {
      if (res.error) {
        console.error('Удаление чата');
        return;
      }
      setDeleteInbox([]);
      refreshMessageList();
      getInboxFolders();
      getUnreadableMsg();
    });
  };

  const moveToFolder = () => {
    const idsList = deleteInbox.join(',');

    apiPost({
      url: '/mail-message-group/move',
      postData: {
        mailMessageGroupId: folderSelectId,
        mailMessageIds: idsList,
      },
    }).then(res => {
      if (res.error) {
        console.error('Удаление чата');
        return;
      }
      setFolderSelectId(0);
      setDeleteInbox([]);
      setSendToFolderChat(false);
      refreshMessageList();
      alertMoveToFolderTrue();
      getInboxFolders();
      getUnreadableMsg();
    });
  };

  const sendToFolderFunc = () => {
    if (!deleteInbox.length) {
      alertMoveToFolder();
      return;
    }
    setSendToFolderChat(true);
  };

  const alertMoveToFolder = () => {
    if (!deleteInbox.length) {
      alertEmptyCheckbox();
      return;
    }
    confirmAlert({
      title: '',
      message: 'Вы точно хотите переместить сообщения?',
      buttons: [
        {
          label: 'Да',
          onClick: () => moveToFolder(),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const searchOrgFunc = e => {
    setSearchOrganization(e);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'organizationName', value: e }));
  };

  const searchMessageFunc = e => {
    setSearchMessage(e);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'title', value: e }));
  };

  const alertBlockUndreadMessage = () => {
    // e.preventDefault()
    confirmAlert({
      title: '',
      message: 'Вы не можете сделать не прочитаным сообщение отправленное вами.',
      buttons: [
        {
          label: 'Ok',
          onClick: e => e,
        },
      ],
    });
  };


  useEffect(() => {
    setErrObj(1);
  }, []);

  useEffect(() => {
    setNotViewedValue(false);
  }, [location.pathname]);

  return (
    <div className="page-inbox">
      <div className="card">
        <div className="card-header">
          <div className="row ai-center jc-between">
            <div className="col-auto">Сообщения</div>
            {state.user.accessUser.ticket.create && (
              <div className="col-auto">
                <button className="btn btn-danger" onClick={() => setCreateInboxModal(true)}>
                  Создать сообщение
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="card-content scroll-x scroll-x-custom">
          <div className="row row-sm">
            <div className="col-md-6 mb-2">
              <Input
                fieldClasses="mb-0"
                placeholder="Поиск сообщения.."
                value={searchMessage}
                onChange={searchMessageFunc}
              />
            </div>
            <div className="col-md-6 mb-2">
              <Input
                fieldClasses="mb-0"
                placeholder="Поиск организации.."
                value={searchOrganization}
                onChange={searchOrgFunc}
              />
            </div>
          </div>
          {state.user.accessUser.ticket.delete && (
            <button className="btn btn-primary mb-2 mr-2" onClick={() => alertDeleteChat()}>
              Удалить сообщения
            </button>
          )}
          {state.user.accessUser['ticket-group'].move && (
            <button className="btn btn-success mb-2  mr-2" onClick={() => sendToFolderFunc()}>
              Переместить в папку
            </button>
          )}
          {location.search && location.search != '?mail_message_group_id=0' && location.search != '?onlyNotViewed=1' && (
            <>
              {state.user.accessUser['ticket-group'].delete && (
                <button className="btn btn-danger mb-2  mr-2" onClick={() => alertDeleteFolder()}>
                  Удалить папку
                </button>
              )}
              {state.user.accessUser['ticket-group'].update && (
                <button className="btn btn-warning mb-2  mr-2" onClick={() => setRenameFolderModal(true)}>
                  Изменить папку
                </button>
              )}
            </>
          )}

          {checkboxVisible && (
              <Checkbox value={notViewedValue} onChange={({target:{checked}}) => setNotViewedValue(checked)}>
               <span className='select-none'>Не прочитанные</span>
              </Checkbox>
          )}


          {tickets?.models?.map((item, index) => (
            <div key={item.id} className={`cursor-pointer message p-3 ${item.isViewed ? '' : 'new-msg'}`}>
              <div className="d-flex ai-center">
                <div className="col-auto">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={item.checkbox}
                    onChange={() => toggleCheckbox(index, item.id)}
                  />
                </div>
                <div
                  className="d-flex"
                  style={{ width: '100%' }}
                  onClick={e => e.target.className !== 'badge badge-green' && history.push(`/message/${item.id}`)}
                >
                  <div className="d-flex" style={{ flex: 1 }}>
                    <div className="start">
                      <div className="org-name">{item.organization.name}</div>
                      {/* <div> {"username" + item.username}</div> */}
                      <div style={{ whiteSpace: 'nowrap' }}> {'ИНН:' + ' ' + item?.organization?.inn}</div>
                    </div>
                    <div className="">
                      <div className="theme">{'Тема:' + ' ' + item.title}</div>
                      <div
                        className="theme"
                        style={{ fontSize: '14px', height: '22px' }}
                        dangerouslySetInnerHTML={{ __html: item.body }}
                      ></div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: '80px' }}>
                      <span className="badge badge-dark-blue">{item.from === 1 ? 'Входящее' : 'Исходящее'}</span>
                    </div>
                    <div className="date ai-center">
                      <div className="d-flex jc-between">
                        {item.isHasFile && <PaperClip size={20} className="mr-2 paperClip" />}
                        {item.from == 1 ? (
                          item.isViewed ? (
                            <span
                              onClick={e =>
                                item.from == '1' ? alertUnreadMessage(item.id, e) : alertBlockUndreadMessage()
                              }
                              className="badge badge-green"
                              style={{ display: 'inline-table' }}
                            >
                              Прочитано
                            </span>
                          ) : (
                            <span className="badge badge-danger" style={{ display: 'inline-table' }}>
                              Не прочитано
                            </span>
                          )
                        ) : item.isViewedByOpponent ? (
                          <span
                            onClick={e =>
                              item.from == '1' ? alertUnreadMessage(item.id, e) : alertBlockUndreadMessage()
                            }
                            className="badge badge-green"
                            style={{ display: 'inline-table' }}
                          >
                            Прочитано
                          </span>
                        ) : (
                          <span className="badge badge-danger" style={{ display: 'inline-table' }}>
                            Не прочитано
                          </span>
                        )}
                      </div>
                      <span className=""> {item.createdAt}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-2 ta-center">
          <Pagination lastPage={tickets?.lastPage} page={page} />
        </div>
      </div>

      <Modal
        modalTitle="Создать новое сообщение"
        show={createInboxModal}
        onClose={() => setCreateInboxModal(false)}
        footer={
          <button
            className={`btn btn-bg-acent ${sendMessage ? 'blocked-btn ' : ''}`}
            onClick={() => (sendMessage ? null : createMessages())}
          >
            Создать сообщение
          </button>
        }
      >
        {companyNames && (
          <SelectCustom
            isMulti
            className="select-company p-0"
            classNamePrefix="select"
            placeholder="Выберите организацию"
            options={orgNamesArr}
            value={selectCustomIds}
            onChange={setSelectCustomIds}
            onInputChange={e => setSearchInput(e)}
            noOptionsMessage={() => 'Нет вариантов..'}
          />
        )}
        {errObj.organizationIds &&
          errObj.organizationIds.map((i, index) => (
            <span key={index} className="_error">
              {i}
            </span>
          ))}

        <Input fieldClasses="mb-0 mt-3" label="Тема" onChange={setTitleMessage} value={titleMessage} />

        {errObj.title &&
          errObj.title.map((i, index) => (
            <span key={index} className="_error">
              {i}
            </span>
          ))}

        {/*<CustomEditor className="mt-3 editor" value={bodyMessage} onChange={setBodyMessage} />*/}
        <ReactQuill className="mt-3 editor" value={bodyMessage} onChange={setBodyMessage} />


        {errObj.body &&
          errObj.body.map((i, index) => (
            <span key={index} className="_error">
              {i}
            </span>
          ))}

        <div className="mt-3">
          {' '}
          <Dropzone
            maxSize={100000000}
            onFileRemove={removeFile}
            onFileUpload={handleUploadFiles}
            styles={{ height: 120 }}
            files={files}
            // acceptFileType=".jpg, .jpeg, .png, .doc, .docx, .pdf, .xls, .xlsx, .xtdd"
          />
        </div>
        {errObj.files && <span style={{ color: '#f46a6a', fontSize: '12px' }}>{errObj.files}</span>}
      </Modal>

      <Modal
        modalTitle="Создать новую папку"
        show={state.user.addFolderModalState}
        onClose={() => dispatch(toggleAddFolderChat(false))}
        footer={
          <button className="btn btn-bg-acent" onClick={() => createFolder()}>
            Создать папку
          </button>
        }
      >
        <Input value={selectCreateFolder} onChange={setSelectCreateFolder} error={errObj.name} />
      </Modal>

      <Modal
        modalTitle="Переместить в папку"
        show={sendToFolderChat}
        onClose={() => setSendToFolderChat(false)}
        footer={
          <button className="btn btn-bg-acent" onClick={() => moveToFolder()}>
            Переместить в папку
          </button>
        }
      >
        <label className="form-field">
          <span className="form-label">Выберите папку</span>
          <select
            value={folderSelectId}
            onChange={e => setFolderSelectId(e.target.value)}
            className="form-input _select"
          >
            <option value={0}>Входящие</option>
            {folderList?.data.map(i => (
              <option value={i.id} key={i.id}>
                {i.name}
              </option>
            ))}
          </select>
        </label>
      </Modal>

      <Modal
        modalTitle="Изменить название папки"
        show={renameFolderModal}
        onClose={() => setRenameFolderModal(false)}
        footer={
          <button className="btn btn-bg-acent" onClick={() => changeNameFolder()}>
            Изменить папку
          </button>
        }
      >
        <Input label="Название папки" value={nameFolderInput} onChange={setNameFolderInput} error={errObj.name} />
      </Modal>
    </div>
  );
};

export default Inbox;
