import React from 'react';
import {Pagination} from '../../../components';
import Index from '../../../components/Spinner';
import {Download, TrashCan} from '../../../icons';
import {Pencil} from '../../../icons/Pencil';
import {modalApi} from '../../../../redux/actions/modal';
import {CreateProtocolModal} from './elems/CreateProtocolModal';
import {useProtocolPage} from './useProtocolPage';
import {UpdateProtocolModal} from './elems/UpdateProtocolModal';
import {getFolderIdUrl} from '../../../../utils/getFolderId';

const ProtocolPage = () => {
    const {
        protocolQueryList,
        dispatch,
        page,
        history,
        handleConfirmDeleteProtocol,
        downloadFile,
        handleProtocolUpdate
    } = useProtocolPage();

    return (
        <div>
            <div className="card">
                <div className="card-header flex-wrap jc-between d-flex ai-center">
                    Протоколы

                    <button
                        onClick={() => dispatch(modalApi('create-protocol'))}
                        className='btn btn-primary'
                    >
                        Сформировать протокол
                    </button>
                </div>
                <div className="card-content">
                    {protocolQueryList.isLoading ? (
                        <div className='d-flex jc-center '>
                            <Index size={60}/>
                        </div>
                    ) : (
                        <div className="scroll-x">
                            <table className="mt-4 middle-table">
                                {!protocolQueryList.data?.data?.models?.length  && !protocolQueryList.isLoading ? (
                                    <div className='w-full text-center font-medium text-primary'>Ничего не найдено</div>
                                ) : (
                                    <thead>
                                        <tr>
                                            <th>Номер протокола</th>
                                            <th>Дата приглашения на ДК</th>
                                            <th>Время начала заседания</th>
                                            <th>Время завершения заседания</th>
                                            <th className="settings"/>
                                        </tr>
                                    </thead>
                                )}


                                <tbody>
                                {protocolQueryList.data?.data?.models?.map((protocol) => (
                                    <tr className='go-link cursor-pointer'
                                        onClick={() => history.push(`/protocol-view/${protocol.id}${getFolderIdUrl()}`)}
                                        key={protocol.id}>
                                        <td>{protocol.number || ''}</td>
                                        <td>{protocol.dateAsString || ''}</td>
                                        <td>{protocol.timeStart || ''}</td>
                                        <td>{protocol['timeEnd'] || ''}</td>
                                        <td>
                                            <div onClick={e => e.stopPropagation()} className='d-flex ai-center jc-end gap-2'>
                                                <button className="action-btn btn btn-light" title="Скачать"
                                                        onClick={() => downloadFile(protocol.id)}>
                                                    <Download/>
                                                </button>

                                                <button
                                                    className="action-btn btn btn-warning"
                                                    onClick={() => handleProtocolUpdate(protocol)}
                                                >
                                                    <Pencil/>
                                                </button>

                                                <button
                                                    className="action-btn btn btn-danger"
                                                    onClick={() => handleConfirmDeleteProtocol(protocol.id)}
                                                >
                                                    <TrashCan/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {!protocolQueryList.isLoading && (
                        <div className="d-flex jc-center">
                            <Pagination lastPage={protocolQueryList.data?.data?.lastPage} page={page}/>
                        </div>
                    )}
                </div>
            </div>


            <CreateProtocolModal fetchData={() => protocolQueryList.refetch()} />
            <UpdateProtocolModal fetchData={() => protocolQueryList.refetch()}/>
        </div>
    );
};


export default ProtocolPage;
