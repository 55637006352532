import PropTypes from 'prop-types';
import * as React from 'react';

const WarningSign = ({ size = 15, color = '#fff', ...props }) => {
  return (
    <svg viewBox="0 0 489.4 489.4" width={size} height={size} fill={color} {...props}>
      <path d="M245 389c18 0 34-14 36-33l24-290a60 60 0 10-121 0l25 290c1 19 17 33 36 33zm0 22a39 39 0 100 78 39 39 0 000-78z" />
    </svg>
  );
};

WarningSign.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default WarningSign;
