import PropType from 'prop-types';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { Breadcrumbs, Input, Textarea, TreeItem, TriggerSearch } from '../../../components';
import Index from '../../../components/Spinner';
import './index.scss';

const CreateRule = () => {
  const { id } = useParams();

  const [sending, setSending] = useState(false);
  const [errObj, setErrObj] = useState({});

  const { register, handleSubmit } = useForm();

  const history = useHistory();

  // получение периода id
  const { response: periodList } = useFetch({ url: `/report-period?limit=1000` });
  // const { response } = useFetch({ url: `/trigger/view?id=}` })
  const { response: templateData, loading: templatesLoading } = useFetch({
    url: '/report?',
  });

  const { response: treeReport, loading: treeLoading } = useFetch({
    url: `/report-template/reference?id=${id}`,
  });

  const ruleSuccessCreate = () => {
    confirmAlert({
      message: 'Правило успешно создано',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  //Создание правила
  const onCreateRule = data => {
    const { periodId, ruleName, condition, description, native } = data;

    if (!sending) {
      setSending(true);
      apiPost({
        url: `/rule/create?template_id=${id}`,
        postData: {
          period_id: +periodId || null,
          name: ruleName,
          condition,
          description,
          native,
        },
      })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          } else if (res.error) {
            console.error('Создание правила');
            return;
          }
          history.goBack();
          setErrObj({});
          ruleSuccessCreate();
        })
        .finally(() => setSending(false));
    }
  };

  const goToTreeNode = id => {
    const node = document.querySelector(`.accordion__panel[data-id="${id}"]`);

    if (!node) {
      console.log('Can not find node with selector ' + `.accordion__panel[data-id="${id}"]`);
    }

    openTreeNode(node);

    node.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const openTreeNode = node => {
    node.removeAttribute('hidden');
    node.setAttribute('aria-hidden', false);

    const parentNode = node.parentNode.parentNode.parentNode;

    if (parentNode.classList.contains('accordion__panel')) {
      parentNode.removeAttribute('hidden');
      parentNode.setAttribute('aria-hidden', false);

      openTreeNode(parentNode);
    }
  };

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Просмотр шаблона',
      link: `/view-report/${id}`,
    },
    {
      name: 'Создание правила',
      link: `/create-rule/${id}`,
    },
  ];

  if (treeLoading && templatesLoading) {
    return (
      <div className="d-flex flex-fill jc-center">
        <Index />
      </div>
    );
  }
  return (
    <div className="edit-trigger">
      <Breadcrumbs linkRoute={linkRoute} />

      <div className="card">
        <div className="card-header">Создание правила</div>

        <div className="card-content">
          <Input register={register} name="ruleName" label="Название правила" error={errObj.name} />
          <Textarea label="Код правила" name="condition" register={register} rows="10" error={errObj.condition} />

          <Input register={register} name="native" label="Нативное правило" error={errObj.native} />

          <Textarea
            style={{ height: '90px' }}
            label="Описание правила"
            name="description"
            register={register}
            rows="10"
            error={errObj.description}
          />
          <label className="form-field">
            <span className="form-label">Период</span>

            <select ref={register} name="periodId" className={`form-input _select ${errObj.period_id ? '_error' : ''}`}>
              <option value="0">-</option>

              {periodList?.models.map(i => (
                <option value={i.id} key={i.id}>
                  {i.name} ({i.startDate} - {i.endDate})
                </option>
              ))}
            </select>

            {errObj.period_id && <div className="form-error">{errObj.period_id}</div>}
          </label>
          <div className="pos " style={{ marginBottom: '50px' }}>
            <TriggerSearch id={treeReport?.id} callback={goToTreeNode} />
          </div>
          <div className="mb-3 tree-wrap scroll-y scroll-y-custom">
            <TreeItem data={{ ...treeReport, templateList: templateData?.models }} />
          </div>
        </div>

        <div className="card-footer">
          <button onClick={handleSubmit(onCreateRule)} className="btn btn-primary">
            Создать
          </button>
        </div>
      </div>
    </div>
  );
};

CreateRule.propTypes = {
  history: PropType.object,
};

export default CreateRule;
