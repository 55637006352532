import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Switch} from 'react-router-dom';
import App from './App';

import {store} from './redux/store';
// import reportWebVitals from "./reportWebVitals"
import './static/styles/style.scss';
import {ConfigProvider} from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';


dayjs.locale('ru-RU');

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: 0,
            refetchOnMount: true,
            cacheTime: 0,
            staleTime: 0,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={ruRu}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Switch>
                            <App/>
                        </Switch>
                    </BrowserRouter>
                </Provider>
            </ConfigProvider>

            {/* eslint-disable-next-line */}
            {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
