import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { useFetch } from '../../../hooks/useFetch';
import { Input } from '../index';

const TriggerSearch = ({ id, callback }) => {
  const [value, setValuse] = useState('');

  const debouncedTriggerName = useDebounce(value);
  const url = debouncedTriggerName.length
    ? `/report-element?instance_id=${id}&name=${debouncedTriggerName}&limit=10`
    : null;

  const { response: triggerSearchList, setResponse: setTriggerSearchList } = useFetch({ url });

  const getSearchItem = id => {
    setTriggerSearchList([]);
    callback(id);
  };

  useEffect(() => {
    if (!debouncedTriggerName.length) {
      setTriggerSearchList([]);
    }
  }, [debouncedTriggerName]);

  return (
    <>
      <Input label="Поиск элемента" fieldClasses="mb-0" value={value} onChange={setValuse} />

      {triggerSearchList && (
        <div className="search-list">
          {triggerSearchList.map(i => (
            <div key={i.id} className="item cursor-pointer" onClick={() => getSearchItem(i.id)}>
              {i.name} {i.value}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
TriggerSearch.propTypes = {
  id: PropTypes.any,
  callback: PropTypes.func.isRequired,
};

export default TriggerSearch;
