import React, {useState} from 'react';
import {Input, Modal} from '../../../../components';
import {toggleNewArchiveModal} from '../../../../../redux/actions/dk';
import {useDispatch, useSelector} from 'react-redux';
import {apiPost} from '../../../../../api';
import {message} from 'antd';

// eslint-disable-next-line react/prop-types
export const DkNewArchiveModal = ({fetchData}) => {
    const dispatch = useDispatch();
    const {isVisibleNewArchiveModal} = useSelector(state => state.dk);
    const [name,setName] = useState('');
    const [errors,setErrors] = useState([]);
    const hideAddNewArchiveModal = () => {
        dispatch(toggleNewArchiveModal(false));
        setName('');
        setErrors([]);
    };

    const createFolder = async () => {
        const {error, data} = await apiPost({url:'/dk-folder/create',postData:{name}});

        if (error) {
            setErrors(data.name.map(i => i.replace('Name', 'Название')));
        }
        if (!error) {
            fetchData();
            hideAddNewArchiveModal();
            message.success('Успешно создано!');
        }
    };

    return (
        <div>
            <Modal
                footer={
                    <button className="btn btn-bg-acent" onClick={createFolder}>
                        Создать архив
                    </button>
                }
                onClose={hideAddNewArchiveModal}
                modalTitle='Создать новый архив'
                show={isVisibleNewArchiveModal}
            >
                <Input
                    label='Название'
                    required
                    value={name}
                    onChange={(v) => setName(v)}
                    error={!!errors.length && errors}
                />
            </Modal>
        </div>
    );
};

