import PropTypes from 'prop-types';
import * as React from 'react';

const SvgComponent = ({ size = 20, color = '#000', ...props }) => {
  return (
    <svg viewBox="0 0 512 512" height={size} width={size} fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M143 69V36c0-8.284-6.716-15-15-15s-15 6.716-15 15v33zM271 69V36c0-8.284-6.716-15-15-15s-15 6.716-15 15v33zM512 181v-67c0-24.813-20.187-45-45-45h-68v63c0 8.284-6.716 15-15 15s-15-6.716-15-15V69h-98v63c0 8.284-6.716 15-15 15s-15-6.716-15-15V69h-98v63c0 8.284-6.716 15-15 15s-15-6.716-15-15V69H45C20.187 69 0 89.187 0 114v67zM399 69V36c0-8.284-6.716-15-15-15s-15 6.716-15 15v33zM0 211v235c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45V211zm104 200H88c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64H88c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64H88c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm80 128h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm80 128h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm80 128h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm80 128h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
    </svg>
  );
};

SvgComponent.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default SvgComponent;
