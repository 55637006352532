import React from 'react';
import EditRule from '../../containers/Lk/EditRule';

const EditRulePage = () => {
  return (
    <>
      <EditRule />
    </>
  );
};

export default EditRulePage;
