import React from 'react';
import { useSelector } from 'react-redux';
import { LoadProcent } from '../../components';
import './index.scss';

const Footer = () => {
  const state = useSelector(state => state);
  return (
    <div className="footer">
      <div className="footer-content ta-center f-300 d-flex jc-between">
        <span className="d-block">2021 © СРО Альянс</span>
        <div className="made">
          Сделано в{' '}
          <a href="https://wocom.biz/" target="blank" className="f-700">
            WOCOM
          </a>
        </div>
      </div>
      {state.user?.uploadingProgress?.loading ? <LoadProcent /> : ''}
    </div>
  );
};

export default Footer;
