import PropTypes from 'prop-types';
import * as React from 'react';

const SvgComponent = ({ size = 20, color = '#000', ...props }) => {
  return (
    <svg
      viewBox="0 0 511.999 511.999"
      height={size}
      width={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M377.003 15.988H45c-24.82 0-45 20.19-45 45V300.99c0 24.82 20.18 45 45 45h45v75.001c0 13.38 16.22 19.99 25.6 10.61 41.88-41.89 12.47-12.47 81.211-81.211 2.82-2.82 6.62-4.4 10.61-4.4h169.581c24.81 0 45-20.18 45-45V60.988c.001-24.81-20.189-45-44.999-45zm-302.002 90h212.002c8.29 0 15 6.71 15 15s-6.71 15-15 15H75.001c-8.29 0-15-6.71-15-15s6.71-15 15-15zM227.002 255.99H75.001c-8.29 0-15-6.71-15-15s6.71-15 15-15h152.001c8.29 0 15 6.71 15 15s-6.71 15-15 15zm120.001-60.001H75.001c-8.29 0-15-6.71-15-15s6.71-15 15-15h272.002c8.29 0 15 6.71 15 15s-6.71 15-15 15z" />
      <path d="M467 75.989h-14.8v225.002c0 41.353-33.847 75.001-75.201 75.001H215.631l-32 29.8h127.157l85.606 85.806c9.405 9.405 25.605 2.804 25.605-10.605v-75.201h45c24.814 0 45-19.986 45-44.8V120.99C512 96.175 491.815 75.989 467 75.989z" />
    </svg>
  );
};

SvgComponent.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default SvgComponent;
