/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiGet, apiPost } from '../../../../api';
import { appLogin } from '../../../../redux/actions/app';
import { getAccessUser, updateProfile } from '../../../../redux/actions/user';
// import logo from "../../../../static/img/logo_edinstvo.svg"
import { Input } from '../../../components';
import './index.scss';

const Login = () => {
  const dispatch = useDispatch();

  const [sending, setSending] = useState(false);
  const [errObj, setErrObj] = useState({});
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const authUser = data => {
    if (!sending) {
      setSending(true);

      apiPost({
        url: '/auth/login',
        postData: {
          username: userName,
          password,
        },
      }).then(authRes => {
        if (authRes.status === 406) {
          setSending(false);
          setErrObj(authRes.data);
          return;
        } else if (authRes.error) {
          setSending(false);
          console.error('Авторизация');
          return;
        }

        apiGet({
          url: `/access-user`,
          headerList: {
            Authorization: `Bearer ${authRes.data.access_token}`,
          },
        }).then(res => {
          if (res.error) {
            setSending(false);
            console.error('Получение прав пользователя');

            return;
          }

          setSending(false);

          dispatch(getAccessUser(res));
          dispatch(updateProfile(authRes.data.profile));
          dispatch(appLogin(authRes.data.access_token));
        });
      });
    }
  };

  return (
    <div className="page-login ta-center mt-5">
      <div className="container">
        <div className="row jc-center">
          <div className="col-md-10 col-lg-8 col-xl-5 col-xxl-4">
            {/*<img className="mb-5" src={logo} />*/}

            <Input
              onChange={e => setUserName(e)}
              name="username"
              placeholder="Username"
              type="text"
              error={errObj.username}
            />

            <Input
              onChange={e => setPassword(e)}
              name="password"
              placeholder="Пароль"
              type="password"
              error={errObj.password}
            />

            <button onClick={() => authUser()} className="btn btn-primary">
              Войти
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
