import React from 'react';
import { TemplateSummaryReport } from '../../containers/Lk/index';

const TemplateSummaryReportPage = () => {
  return (
    <>
      <TemplateSummaryReport />
    </>
  );
};

export default TemplateSummaryReportPage;
