import PropTypes from 'prop-types';
import * as React from 'react';

const AllMessages = ({ size = 20, color = '#fff', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 512 512" {...props}>
      <path d="M492.034 66.002L492 66l-472.032.001C8.768 66.01-.053 75.208 0 86.144V426c0 11.046 8.954 20 20 20h472c11.046 0 20-8.954 20-20V86.144c.054-10.952-8.787-20.134-19.966-20.142zM454.141 106l-88.706 130H146.566L57.86 106h396.281zM472 406H40V150.794l79.479 116.479A20 20 0 00136 276.001h240a20.002 20.002 0 0016.521-8.728L472 150.794V406z" />
    </svg>
  );
};

AllMessages.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default AllMessages;
