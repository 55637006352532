import React from 'react';
import { CreateSummary } from '../../containers/Lk/index';

const CreateSummaryReportPage = () => {
  return (
    <>
      <CreateSummary />
    </>
  );
};

export default CreateSummaryReportPage;
