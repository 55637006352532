import PropTypes from 'prop-types';
import * as React from 'react';

const Done = ({ size = 44, color = '#fff', ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 24 24" {...props}>
            <path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/>
        </svg>
    );
};

Done.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

export default Done;
