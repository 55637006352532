export const downloadReport = (data,blank) => {
    const link = document.createElement('a');
    link.href = data.link;
    link.download = data.id;
    if (blank){
        link.target = '_blank';
    }
    document.body.appendChild(link);
    link.click();
};
